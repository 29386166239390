const ERROR_MSGS = ['no permissions']
/**
 * Returns whether or not given error
 * relates to permissions error on object.
 */
export const permissionsCheckError = error => {
  const errorMsg = error?.response?.data?.message
  if (!errorMsg) {
    return false
  }
  return ERROR_MSGS.includes(errorMsg)
}
