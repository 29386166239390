<script>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { set } from '@vueuse/core'
/**
 * Vue allows for "teleporting" components
 * up and down the DOM tree via the <teleport> component.
 *
 * However it exposes some race conditions in case the teleport
 * target is mounted after the component being teleported.
 *
 * This is a logical wrapper for the teleport to ensure the target
 * is in the dom before allowing the child to render.
 *
 */
export default {
  name: 'StatefulTeleport',
  props: {
    teleportTo: {
      type: String,
      required: false,
      default: '#teleport'
    }
  },
  setup () {
    const isMounted = ref(false)
    onMounted(() => set(isMounted, true))
    onBeforeUnmount(() => set(isMounted, false))
    return {
      isMounted
    }
  }
}
</script>

<template>
  <teleport :to="teleportTo" v-if="isMounted">
    <slot />
  </teleport>
</template>

<style scoped></style>
