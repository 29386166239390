import { ApiNames, Methods, callLambdaApi } from '@/api'
import { Project, SearchService } from '@/store/models'

/**
 * Handles API communications for Organization model
 */
export class OrganizationApiCommunicator {
  static perPageLimit = 100

  /**
   * We expose a static form of this call because it is the 'entry-point'
   * for the store and all of the cached data -- network connections.
   *
   * @returns {Promise<Organization[]>}
   */
  static fetchList () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Org,
      path: '/orgs'
    })
  }

  static create (orgName) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/orgs',
      payload: {
        body: {
          orgName
        }
      }
    })
  }

  static fetchInviteInformation (inviteToken) {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Org,
      path: `/invite/token/${inviteToken}`
    })
  }

  static requestUpgradeInformation () {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/contact/upgrade'
    })
  }

  constructor (orgId) {
    /**
     * Due to permissions requirements on the backend,
     * listing projects within an org requires using the search API.
     */
    this.useSearchAPIForListProjects = true
    this.orgId = orgId
    this.cursor = null
    /**
     * @type {number}
     * Cached from network response as the
     * total project count per org.
     * Compared against stored project list
     * to determine if paginated fetch is needed.
     */
    this.projectCountTotal = 0
  }

  fetchCurrentPlan () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Org,
      path: '/plans/current'
    })
  }

  /**
   * @param {Number} projectCount
   * @param {{
   * sortField: string,
   * sortAsc: boolean
   * }} sortOptions
   */
  async fetchProjectsList (projectCount = 0, sortOptions = {}) {
    if (this.useSearchAPIForListProjects) {
      const apiResults = await this.fetchProjectsListFromSearch(
        projectCount,
        sortOptions
      )
      this.projectCountTotal = apiResults.total
      return apiResults.items
    }
    // todo
    // cache info from regular api
    // in case we ever swtich back
    return this.fetchProjectsListFromApi()
  }

  fetchProjectsListFromApi () {
    const cursor = this.cursor
    let path = `/projects?limit=${OrganizationApiCommunicator.perPageLimit}`
    if (cursor) {
      path = `${path}&cursor=${cursor}`
    }
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path
    })
  }

  /**
   * @param {number} projectCount
   * @param {{
   * sortField: string,
   * sortAsc: boolean
   * }} sortOptions
   *
   */
  fetchProjectsListFromSearch (projectCount, sortOptions) {
    const size = 50
    const from = projectCount
    const query = ''
    const endpoint = '/projects'
    const path = SearchService.buildQueryStringURL({
      endpoint,
      query,
      from,
      size,
      ...sortOptions
    })
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Search,
      path
    })
  }

  hasFetchedAllProjects (projectCount) {
    return projectCount >= this.projectCountTotal
  }

  formatProjectSearchResultsFromApi (res = {}) {
    const { items } = res
    const asProjects = (items || []).map(i => new Project(i._source))
    return asProjects
  }

  fetchDetails () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Org,
      path: `/orgs/${this.orgId}`
    })
  }

  rename (orgName) {
    return callLambdaApi({
      method: Methods.PUT,
      apiName: ApiNames.Org,
      path: '/orgs',
      payload: {
        body: {
          orgId: this.orgId,
          orgName
        }
      }
    })
  }

  delete () {
    return callLambdaApi({
      method: Methods.DELETE,
      apiName: ApiNames.Org,
      path: `/orgs/${this.orgId}`
    })
  }

  inviteUser (email, roles) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/invite',
      payload: {
        body: {
          orgId: this.orgId,
          email,
          roles
        }
      }
    })
  }

  removeUser (emailHash) {
    return callLambdaApi({
      method: Methods.DELETE,
      apiName: ApiNames.Org,
      path: '/user',
      payload: {
        body: { orgId: this.orgId, emailHash }
      }
    })
  }

  updateUser (emailHash, roles) {
    return callLambdaApi({
      method: Methods.PUT,
      apiName: ApiNames.Org,
      path: '/user',
      payload: {
        body: {
          orgId: this.orgId,
          emailHash,
          roles
        }
      }
    })
  }

  makeDefault () {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/orgs/default',
      payload: { body: { orgId: this.orgId } }
    })
  }

  leave () {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/orgs/leave',
      payload: {
        body: {
          orgId: this.orgId
        }
      }
    })
  }

  transferOwnership (emailHash) {
    const orgId = this.orgId
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/admin/transfer',
      payload: {
        body: {
          orgId,
          emailHash
        }
      }
    })
  }

  fetchSettings () {
    const orgId = this.orgId
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: `/settings/orgs/${orgId}`
    })
  }

  saveSettings (requestBody) {
    const orgId = this.orgId
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `/settings/orgs/${orgId}`,
      payload: {
        body: requestBody
      }
    })
  }

  acceptInvite () {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: '/invite/accept',
      payload: { body: { orgId: this.orgId } }
    })
  }
}
