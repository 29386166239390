<script>
export default {
  name: 'BaseRouterLink'
}
</script>

<template>
  <router-link class="text-sm font-medium text-brand-blue hover:underline">
    <slot />
  </router-link>
</template>

<style scoped></style>
