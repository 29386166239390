/**
 *
 * Enum of possible comment "types" allowed in graphql queries, subscriptions,
 * mutations.
 *
 * @enum {String}
 * @readonly
 *
 */
const COMMENT_TYPE = {
  PROJECT: 'project',
  PART: 'part',
  MODEL: 'model',
  CAPTURE: 'capture',
  SCAN: 'scan',
  COMPARE_RESULT: 'compareresult',
  REFERENCE_CAD: 'referencecad',
  MEASUREMENT: 'measurement'
}

export { COMMENT_TYPE }
