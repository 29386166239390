import config from '@/config'

const guardRegistration = () => {
  if (!config.Rover.app.allowRegistration) {
    return login
  }
}

export const registration = {
  name: 'registration',
  path: 'registration',
  component: () =>
    import(/* webpackChunkName: 'registration' */ '../views/Registration.vue'),
  meta: { requiresAuth: false },
  beforeEnter: guardRegistration
}

export const login = {
  name: 'login',
  path: 'login',
  component: () => import(/* webpackChunkName: 'login' */ '../views/Login.vue'),
  meta: { requiresAuth: false }
}

export const forgotPassword = {
  name: 'forgotPassword',
  path: 'forgot-password',
  component: () =>
    import(
      /* webpackChunkName: 'forgot-password' */ '../views/ForgotPassword.vue'
    ),
  meta: { requiresAuth: false }
}

export const changePassword = {
  name: 'changePassword',
  path: 'change-password',
  component: () =>
    import(
      /* webpackChunkName: 'change-password' */ '../views/ChangePassword.vue'
    ),
  meta: { requiresAuth: false }
}

export const inviteRegistration = {
  name: 'inviteRegistration',
  path: 'register/invite/:token',
  component: () => import(/* webpackChunkName */ '../views/InviteRegistration.vue'),
  beforeEnter: guardRegistration,
  meta: { requiresAuth: false }
}

export const confirmEmail = {
  name: 'confirmEmail',
  path: 'confirm-email',
  component: () => import('../views/ConfirmEmail.vue'),
  meta: { requiresAuth: false }
}
