import BaseIcon from './icon/BaseIcon.vue'
import BaseInput from './BaseInput.vue'
import BaseTextArea from './BaseTextArea.vue'
import BaseRouterLink from './BaseRouterLink'
import BaseAvatar from './BaseAvatar'
import BaseToggle from './BaseToggle.vue'
import BaseBanner from './BaseBanner.vue'
import BaseSeparator from './BaseSeparator.vue'
import BaseSpan from './BaseSpan.vue'
import BaseCheckbox from './checkbox/BaseCheckbox.vue'
// ======
// Button
// =====-
import BaseButton from './button/BaseButton.vue'
import BaseIconButton from './button/BaseIconButton.vue'
// =============================
// Loading / Progress Indicators
// =============================
import BaseLoadingSpinner from './BaseLoadingSpinner.vue'
import BaseLoadingShimmerElement from './BaseLoadingShimmerElement.vue'
// ================
// Modal / Dialogue
// ================
import BaseModal from './modal/BaseModal.vue'
import BaseModalHeader from './modal/BaseModalHeader.vue'
import BaseModalForm from './modal/BaseModalForm.vue'
import BaseModalButtonWrapper from './modal/BaseModalButtonWrapper.vue'

import BaseTooltip from './BaseTooltip.vue'
import BaseAccordion from './accordion/BaseAccordion.vue'
// =====
// Menu
// =====
import MenuItem from './menu/MenuItem.vue'
import MenuItemGroup from './menu/MenuItemGroup.vue'
import MenuItemList from './menu/MenuItemList.vue'

import SelectMenu from './picker/SelectMenu.vue'
import BaseSelect from './picker/BaseSelect.vue'

/**
 * BaseComponents Index
 * @type {Object}
 */
const BaseComponents = {
  // Button
  BaseButton,
  BaseIconButton,
  //
  BaseInput,
  BaseTextArea,
  BaseRouterLink,
  BaseAvatar,
  BaseIcon,
  BaseToggle,
  BaseBanner,
  BaseSeparator,
  BaseSpan,
  BaseCheckbox,
  // Loading Indicators
  BaseLoadingSpinner,
  BaseLoadingShimmerElement,
  // Modal
  BaseModal,
  BaseModalHeader,
  BaseModalForm,
  BaseModalButtonWrapper,
  // Popover
  BaseTooltip,
  //
  BaseAccordion,
  // Menu
  MenuItem,
  MenuItemGroup,
  MenuItemList,
  // Pickers
  SelectMenu,
  BaseSelect
}

/**
 * BaseComponents Plugin for App
 *
 * TODO: just import * from './'
 */
export default {
  /**
   * https://v3.vuejs.org/guide/plugins.html#using-a-plugin
   * Plugin-styled function to globally import certain
   * "Base" components into app components.
   * Eliminates the need for manual imports on these
   * common components.
   */
  install: (app, options) => {
    Object.keys(BaseComponents).forEach(key => {
      app.component(key, BaseComponents[key])
    })
  },
  components: BaseComponents
}
