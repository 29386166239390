export class ModelDTO {
  createdAt
  createdBy
  modelId
  modelName
  exports
  paths
  partId
  projectId

  /**
   * Distinct string of the model's current status.
   * Used to determine if the mesh is being registered, if there's an error,
   * if the model is stable/ready for viewing, etc.
   * @type {'cleaning queued'|'ready'|'new'}
   */
  modelStatus

  /** @type {Object[]} */
  scans
  updatedAt
  updatedBy
  /** @type {import('../models').Version[]} */
  versions
  uploadUrls
}
