/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment($objectId: ID!, $type: commentType!) {
    onCreateComment(objectId: $objectId, type: $type) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment($objectId: ID!, $type: commentType!) {
    onDeleteComment(objectId: $objectId, type: $type) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const onDeleteCommentReply = /* GraphQL */ `
  subscription OnDeleteCommentReply($objectId: ID!) {
    onDeleteCommentReply(objectId: $objectId) {
      replyTo
      objectId
      commentId
      body
      createdAt
      updatedAt
      author
      handle
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment($objectId: ID!) {
    onUpdateComment(objectId: $objectId) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const onUpdateCommentReply = /* GraphQL */ `
  subscription OnUpdateCommentReply($replyTo: ID!) {
    onUpdateCommentReply(replyTo: $replyTo) {
      replyTo
      objectId
      commentId
      body
      createdAt
      updatedAt
      author
      handle
    }
  }
`;
export const onCreateCommentReply = /* GraphQL */ `
  subscription OnCreateCommentReply($replyTo: ID!) {
    onCreateCommentReply(replyTo: $replyTo) {
      replyTo
      objectId
      commentId
      body
      createdAt
      updatedAt
      author
      handle
    }
  }
`;
export const onCreateModelSession = /* GraphQL */ `
  subscription OnCreateModelSession($modelId: ID!) {
    onCreateModelSession(modelId: $modelId) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateModelSession = /* GraphQL */ `
  subscription OnUpdateModelSession($sessionId: ID!) {
    onUpdateModelSession(sessionId: $sessionId) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
