import axios from 'axios'
import { getSignedStorageUrl } from './'

export const fetchJSON = async unsignedUrl => {
  const signedUrl = await getSignedStorageUrl(unsignedUrl)
  const response = await axios.get(signedUrl)
  if (response?.status === 200) {
    return response.data
  }
}
