// useUpdateCommentReply.js
// consumes { commentId, body, objectId }
import { useMutation } from '@vue/apollo-composable'
import { ApolloManager } from '../'
import { updateCommentReply as updateCommentReplyMutation } from '@/graphql/mutations'
import gql from 'graphql-tag'

const UPDATE_COMMENT_REPLY = gql`
  ${updateCommentReplyMutation}
`

/**
 * Composable for updating a comment reply. Returns a function that component can call
 * with relevent payload
 *
 * @return {{
 *   updateCommentReply: (commentReply: object) => void
 * }}
 * Object with a function to call for updating a comment reply. Comment argument is destructured to provide
 * graphql only with necessary paramters (commentId, body, objectId)
 *
 * ```js
 * const { updateCommentReply } = useUpdateCommentReply()
 *
 * const updatedCommentReply = reactive({ ...orginalCommentReply })
 *
 * const sendUpdate = () => {
 *  updateCommentReply(updatedCommentReply)
 * }
 * ```
 */
export const useUpdateCommentReply = () => {
  const { mutate: updatedCommentReply } = useMutation(
    UPDATE_COMMENT_REPLY,
    () => ({ ...ApolloManager.DEFAULT_QUERY_OPTIONS, fetchPolicy: 'no-cache' })
  )

  const appendUpdateCommentReply = (vars = {}) => {
    const { commentId = null, body = null, objectId = null } = vars
    if (!commentId || !body || !objectId) {
      console.log('useUpdateCommentReply() has missing or invalid paramter', {
        commentId,
        body,
        objectId
      })
      return
    }
    updatedCommentReply({ commentId, body, objectId })
  }

  return {
    updateCommentReply: appendUpdateCommentReply
  }
}
