<script>
import SelectMenu from '../../picker/SelectMenu.vue'
import StatefulPositioner from '../../utils/StatefulPositioner.vue'
import StatefulTeleport from '../../utils/StatefulTeleport.vue'

import Pane from '../../layers/Pane.vue'
import * as CommonProps from '../../common/props'

export default {
  name: 'NestedMenu',
  components: {
    Pane,
    StatefulPositioner,
    StatefulTeleport
  },
  emits: {
    close: null,
    'sync-state': null
  },
  props: {
    ...SelectMenu.props,
    /**
     *
     */
    placement: {
      ...StatefulPositioner.props.placement,
      default: 'right-start'
    },
    /**
     *
     */
    level: CommonProps.NUMBER,
    /**
     *
     */
    isVisible: CommonProps.BOOLEAN,
    /**
     *
     */
    manualUpdateProperty: CommonProps.STRING
  }
}
</script>

<template>
  <stateful-teleport>
    <stateful-positioner
      :is-visible="isVisible"
      :target-ref="selectorEl"
      :offset="offset"
      :placement="placement"
      :manual-update-property="manualUpdateProperty"
    >
      <pane :width="width" :max-height="maxHeight" rounding="small" shadow="large" y-scroll>
        <MenuItemList
          @close="$emit('close')"
          :items="items"
          :level="level"
          :manual-update-property="manualUpdateProperty"
        />
      </pane>
    </stateful-positioner>
  </stateful-teleport>
</template>

<style></style>
