import { useQuery } from '@vue/apollo-composable'
import { listCommentReplies } from '@/graphql/queries'
import {
  onCreateCommentReply,
  onUpdateCommentReply,
  onDeleteCommentReply
} from '@/graphql/subscriptions'
import { watch, ref, onBeforeUnmount, computed } from 'vue'
import { get, set, useToggle } from '@vueuse/core'
import { ApolloManager } from '../'
import { updateQuery } from '../utils'
import gql from 'graphql-tag'

const LIST_COMMENT_REPLIES = gql`
  ${listCommentReplies}
`

const ON_UPDATE_COMMENT_REPLY = gql`
  ${onUpdateCommentReply}
`

const ON_CREATE_COMMENT_REPLY = gql`
  ${onCreateCommentReply}
`

const ON_DELETE_COMMENT_REPLY = gql`
  ${onDeleteCommentReply}
`

/**
 * useQuery() for the replies of the given comment id.
 * Can be manually enabled/disabled if args.enabled is provided,
 * but this composable will also disable itself through the vue component onBeforeUnmount
 * hook.
 *
 * @param {args} args Args object
 * @param {string} args.commentId The commentId for which to list replies
 * @param {string} args.objectId The Object Id
 *
 * @return {Object} Because replies should be fetched by user input (expanding a menu, for example)
 * manually expose the option to enable the listCommentReplies query from the destructured composable.
 * When the visibilty changes, just update the enabled state to trigger the query (subscription state should match internally).
 */
export const useListCommentReplies = ({ commentId = null, objectId = null }) => {
  const [enabled, toggleEnableQuery] = useToggle()

  const { loading, result, subscribeToMore } = useQuery(
    LIST_COMMENT_REPLIES,
    () => ({
      commentId: get(commentId),
      count: 1000
    }),
    () => ({
      ...ApolloManager.DEFAULT_QUERY_OPTIONS
    })
  )

  const subscriptionDefaults = {
    updateQuery,
    ...ApolloManager.DEFAULT_QUERY_OPTIONS
  }

  subscribeToMore(() => ({
    ...subscriptionDefaults,
    document: ON_CREATE_COMMENT_REPLY,
    variables: { replyTo: get(commentId) }
  }))

  subscribeToMore(() => ({
    ...subscriptionDefaults,
    document: ON_UPDATE_COMMENT_REPLY,
    variables: { replyTo: get(commentId) }
  }))

  subscribeToMore(() => ({
    ...subscriptionDefaults,
    document: ON_DELETE_COMMENT_REPLY,
    variables: { objectId: get(objectId) }
  }))

  watch(enabled, currentEnabled => {
    if (!currentEnabled) {
      console.log(
        '[APOLLO]: Unsubscribed from comment replies'
      )
    }
  })

  // loading returned from useQuery() is a ref
  // that generally referes to the state of the graphql query
  // is is true during the initial query
  // but it is also true for any subsequent subscription modifications,
  // so if a user adds a reply or edits a comment, it'll trigger the loading
  // state.
  // To solve this, just falsify a loading state when the composable is called,
  // and trip it to false once the initial query changes its loading state.
  const isFetchingReplies = ref(true)
  watch(loading, (prevLoad, newLoad) => {
    if (!prevLoad && newLoad && isFetchingReplies.value) {
      set(isFetchingReplies, false)
    }
  })

  watch(commentId, () => {
    set(isFetchingReplies, true)
  })

  // safety unsub
  onBeforeUnmount(() => {
    if (enabled.value) {
      set(enabled, false)
      console.log(
        `[APOLLO]: Unsubscribed from comment replies for ${commentId}`
      )
    }
  })

  return {
    loading,
    toggleEnableQuery,
    isFetchingReplies,
    replies: computed(() => result.value?.listCommentReplies.items ?? [])
  }
}
