<script>
import { computed } from 'vue'
import * as CommonProps from '@/components/_base/common/props'

export default {
  name: 'Pane',
  props: {
    /**
     * Width of the pane in pixels.
     * If 0, use 'auto' for informed width.
     */
    width: CommonProps.NUMBER,
    /**
     * Max height of the pane.
     * Defaults to auto if undefined.
     */
    maxHeight: {
      type: [Number, undefined],
      required: false,
      default: undefined
    },
    /**
     * Level for border radius
     */
    rounding: {
      ...CommonProps.SIZE_STRING,
      default: 'small'
    },
    /**
     *
     * Level of box shadow.
     */
    shadow: {
      ...CommonProps.STRING,
      default: ''
    },
    /**
     *
     */
    yScroll: CommonProps.BOOLEAN
  },
  emits: {
    'set-ref': null
  },
  setup (props, { emit }) {
    const setPaneRef = e => {
      emit('set-ref', e)
    }

    const widthPixels = computed(() => `${props.width}px`)
    const maxHeightPixels = computed(() => `${props.maxHeight}px`)

    return {
      setPaneRef,
      widthPixels,
      maxHeightPixels
    }
  }
}
</script>
<template>
  <div
    :class="{
      'rounded-sm': rounding === 'small',
      'rounded-md': rounding === 'medium',
      'shadow-sm': shadow === 'small',
      'shadow-md': shadow === 'medium',
      'shadow-lg': shadow === 'large',
      'no-mh': maxHeight === undefined || maxHeight === 0,
      'set-mh': maxHeight !== undefined && maxHeight > 0,
      'set-w': width !== 0 && width !== undefined,
      'w-auto': width === 0 || width === undefined,
      'overflow-y-auto': yScroll
    }"
    class="relative"
    role="menu"
    aria-orientation="vertical"
    aria-labelledby="menu-button"
    tabindex="-1"
    :ref="setPaneRef"
  >
    <slot />
  </div>
</template>

<style scoped>
div {
  @apply overflow-x-visible origin-top-right bg-white ring-1 ring-black/5 focus:outline-none border-separate;
}
.no-mh {
  max-height: none;
}
.set-mh {
  max-height: v-bind(maxHeightPixels);
}
.set-w {
  width: v-bind(widthPixels);
}
</style>
