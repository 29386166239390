export class PartDTO {
  createdAt
  failReason
  failedVersion
  /** @type {import('../models').Model[]} */
  models = []
  partId
  partName
  partStatus
  paths
  projectId
  updatedAt
  updatedBy
  /** @type {import('../models').ObjectPermissions} */
  permissions
}
