/**
 * @fileoverview
 * Custom yet shared prop defintions for Vue SFCs.
 */
import iconData from '@/components/_base/icon/icons.json'
import { ANIM_STATES } from '../constants'
import {
  Organization,
  Project,
  Part,
  Model,
  UserPermissions,
  Scan,
  Device
} from '@/store/models'

// ==========
// Primitives
// ==========
export const BOOLEAN = {
  type: Boolean,
  required: false,
  default: false
}

export const STRING = {
  type: String,
  required: false,
  default: ''
}

export const NUMBER = {
  type: Number,
  required: false,
  default: 0
}

export const ARRAY = {
  type: Array,
  required: false,
  default: () => []
}

export const OBJECT = {
  type: Object,
  required: false,
  default: () => ({})
}

// ========
// Internal
// ========
export const ICON = {
  type: String,
  required: false,
  default: '',
  validator: function (value) {
    if (value === '') return true
    const icons = iconData.icons
    return icons.includes(value)
  }
}

const SIZE_STRINGS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
}

export const SIZE_STRING = {
  type: String,
  required: false,
  default: 'small',
  validator: function (value) {
    return Object.values(SIZE_STRINGS).includes(value)
  }
}

export const ANIMATION_STATE = {
  ...STRING,
  validator: function (value) {
    if (value === '') return true
    return Object.values(ANIM_STATES).includes(value)
  }
}

// ======
// MISC.
// ======
export const HTMLEl = {
  type: HTMLElement,
  required: false,
  default: null
}

// =====
// STORE
// =====
export const ORGANIZATION = {
  type: Organization,
  required: false
}

export const PROJECT = {
  type: Project,
  required: false
}

export const PART = {
  type: Part,
  required: false
}

export const MODEL = {
  type: Model,
  required: false
}

export const USER_PERMISSIONS = {
  type: UserPermissions,
  required: false
}

export const SCAN = {
  type: Scan,
  required: false
}

export const DEVICE = {
  type: Device,
  required: false
}
