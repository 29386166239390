import { ApiNames, Methods, callLambdaApi } from '@/api'

export class DeviceApiCommunicator {
  static CONTROL_OPERATIONS = {
    CONNECTION_START: 'connection-start',
    CONNECTION_END: 'connection-end',
    SCAN_START: 'scan-start',
    SCAN_ABORT: 'scan-abort',
    SCAN_FINISH: 'scan-finish',
    CAPTURE_START: 'capture-start',

    FINISH: 'finish'
  }

  constructor (deviceId) {
    this.deviceId = deviceId
    this.projectId = null
    this.partId = null
  }

  static fetchDeviceList () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Devices,
      path: '/devices'
    })
  }

  startConnection () {
    const { CONNECTION_START } = DeviceApiCommunicator.CONTROL_OPERATIONS
    const requestBody = this.getDefaultControlRequestBody()
    return this.callOperation(CONNECTION_START, requestBody)
  }

  startScan () {
    const { SCAN_START } = DeviceApiCommunicator.CONTROL_OPERATIONS
    const requestBody = this.getDefaultControlRequestBody()
    return this.callOperation(SCAN_START, requestBody)
  }

  finishScan () {
    return this.callOperation(
      DeviceApiCommunicator.CONTROL_OPERATIONS.SCAN_FINISH,
      this.getDefaultControlRequestBody()
    )
  }

  startCapture (scanName) {
    const { CAPTURE_START } = DeviceApiCommunicator.CONTROL_OPERATIONS
    const requestBody = {
      ...this.getDefaultControlRequestBody(),
      scanName
    }
    return this.callOperation(CAPTURE_START, requestBody)
  }

  endConnection () {
    const { CONNECTION_END } = DeviceApiCommunicator.CONTROL_OPERATIONS
    const requestBody = this.getDefaultControlRequestBody()
    return this.callOperation(CONNECTION_END, requestBody)
  }

  abortScan () {
    const { SCAN_ABORT } = DeviceApiCommunicator.CONTROL_OPERATIONS
    const requestBody = this.getDefaultControlRequestBody()
    return this.callOperation(SCAN_ABORT, requestBody)
  }

  getDefaultControlRequestBody () {
    return {
      projectId: this.projectId,
      partId: this.partId,
      deviceId: this.deviceId
    }
  }

  callOperation (operation, requestBody) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Devices,
      path: `/device/control/${operation}`,
      payload: {
        body: requestBody
      }
    })
  }
}
