/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $body: String!
    $objectId: ID!
    $type: commentType
    $metadata: AWSJSON
    $annotationAnchor: AWSJSON
  ) {
    createComment(
      body: $body
      objectId: $objectId
      type: $type
      metadata: $metadata
      annotationAnchor: $annotationAnchor
    ) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $commentId: ID!
    $body: String!
    $objectId: ID
    $metadata: AWSJSON
    $annotationAnchor: AWSJSON
  ) {
    updateComment(
      commentId: $commentId
      body: $body
      objectId: $objectId
      metadata: $metadata
      annotationAnchor: $annotationAnchor
    ) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const updateCommentReply = /* GraphQL */ `
  mutation UpdateCommentReply($commentId: ID!, $body: String!) {
    updateCommentReply(commentId: $commentId, body: $body) {
      replyTo
      objectId
      commentId
      body
      createdAt
      updatedAt
      author
      handle
    }
  }
`;
export const createCommentReply = /* GraphQL */ `
  mutation CreateCommentReply($replyTo: ID!, $body: String!) {
    createCommentReply(replyTo: $replyTo, body: $body) {
      replyTo
      objectId
      commentId
      body
      createdAt
      updatedAt
      author
      handle
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($commentId: ID!, $objectId: ID) {
    deleteComment(commentId: $commentId, objectId: $objectId) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const deleteCommentReply = /* GraphQL */ `
  mutation DeleteCommentReply($commentId: ID!, $objectId: ID) {
    deleteCommentReply(commentId: $commentId, objectId: $objectId) {
      replyTo
      objectId
      commentId
      body
      createdAt
      updatedAt
      author
      handle
    }
  }
`;
export const createModelSession = /* GraphQL */ `
  mutation CreateModelSession($modelId: String!, $transformation: AWSJSON) {
    createModelSession(modelId: $modelId, transformation: $transformation) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
export const updateModelSession = /* GraphQL */ `
  mutation UpdateModelSession($sessionId: String!, $transformation: AWSJSON) {
    updateModelSession(sessionId: $sessionId, transformation: $transformation) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
export const deleteModelSession = /* GraphQL */ `
  mutation DeleteModelSession($modelId: String!) {
    deleteModelSession(modelId: $modelId) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
export const joinModelSession = /* GraphQL */ `
  mutation JoinModelSession($sessionId: String!) {
    joinModelSession(sessionId: $sessionId) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
