import { loadBlob } from '@/api'
import { ensurePrependedPathObject } from '@/store'
import { VersionDTO } from '../dtos'
import { BlobDataType } from '@GrabCAD/mesh-vue-component'

/**
 * Version of a Model. Contains the loading and caching of mesh
 * blobs for mvc.
 */
export default class Version extends VersionDTO {
  static TRANSFORMATION_KEYS = {
    EMPTY: null,
    CLEAN: 'clean',
    REPAIR: 'repair',
    MRAPCCSEGMENTIZE: 'mraPCCSegmentize',
    ARAPIDEALIZE: 'arapIdealize'
  }

  static TRANSFORMATION_PROCESS = {
    REGISTRATION: 'registration',
    USER_UPLOAD: 'user upload'
  }

  constructor (json = {}) {
    super(json)

    this.blobs = []
    this.createdAt = json.createdAt
    this.createdBy = json.createdBy
    this.modelId = json.modelId
    this.modelVersionId = json.modelVersionId
    this.modelVersionStatus = json.modelVersionStatus
    this.processingFinishedAt = json.processingFinishedAt
    this.processingStartedAt = json.processingStartedAt
    this.shouldChain = json.shouldChain
    this.sourceVersionId = json.sourceVersionId
    this.transformationInstructions = json.transformationInstructions
    this.transformationKey = json.transformationKey
    this.transformationProcess = json.transformationProcess
    this.transforms = json.transforms || []
    this.tranformationSettingsOverride = json.tranformationSettingsOverride

    const paths = json.paths || {}
    this.paths = ensurePrependedPathObject(paths)
  }

  getRegistrationMetadataBlobIndex () {
    const blobs = this.getBlobs()
    return blobs.findIndex(
      (b) => b.dataType === BlobDataType.RegistrationMetadata
    )
  }

  isClean () {
    return this.transformationKey === Version.TRANSFORMATION_KEYS.CLEAN
  }

  isRegistration () {
    const { REGISTRATION } = Version.TRANSFORMATION_PROCESS
    const process = this.transformationProcess
    return process === REGISTRATION
  }

  isUserUpload () {
    const { USER_UPLOAD } = Version.TRANSFORMATION_PROCESS
    const process = this.transformationProcess
    return process === USER_UPLOAD
  }

  getPaths () {
    return this.paths || {}
  }

  hasPaths () {
    const paths = this.getPaths()
    const extantPathsValues = Object.values(paths).filter((v) => {
      return v !== null && v !== undefined
    })
    return extantPathsValues.length > 0
  }

  getMeshPath () {
    const paths = this.getPaths()
    return paths.mesh
  }

  getBlobs () {
    return this.blobs || []
  }

  hasBlobs () {
    const blobs = this.getBlobs()
    return blobs.length > 0
  }

  getTransforms () {
    return this.transforms || []
  }

  getAlignmentTransform () {
    const transforms = this.getTransforms()
    return transforms[0]
  }

  /**
   * Used to fetch all of a Verions paths
   * for the part inspector and store in the local blob
   * @returns {Promise.<Blob[]>} List of promises for fetching blobs
   */
  async loadBlobsForPaths () {
    /**
     * Index for blob data types to version paths
     * @type {Object.<string, BlobDataType>}
     */
    const dataTypeIndex = {
      mesh: BlobDataType.Mesh,
      // surfacesegments: BlobDataType.Segments,
      geo: BlobDataType.GeoFeatures,
      decimatedRegistrationMetadata: BlobDataType.RegistrationMetadata
      // Other...should be used?
      // previewdecimations
      // holesPlot
      // transformations
    }

    // store all the potential path / blob fetches
    // in a promise all
    const blobPromises = Object.keys(this.paths)
      .filter((p) => this.paths[p] && dataTypeIndex[p]) // if there's an unsigned url to load
      .map((p) => loadBlob(this.paths[p], dataTypeIndex[p])) // load the blob for the present version paths

    this.blobs = await Promise.all(blobPromises)

    return this.blobs
  }
}
