import tailwindconfig from '../../tailwind.config'
import resolveConfig from 'tailwindcss/resolveConfig'

export const useTailwindConfig = () => {
  const config = resolveConfig(tailwindconfig)
  const colors = config.theme.colors

  return {
    config,
    colors
  }
}
