import { reactive, watch } from 'vue'

/**
 * Open, close, or toggle a boolean to govern a local Modal's visibility.
 * Call also watches for the updated show flag
 * to reset the provided state.
 * Because modals mount when the app loads,
 * normal lifecycle methods like mount or unmount don't work
 * @param {Object} initialModalState Local state for the modal
 * to be set into and returned as a reactive object
 */
const useModal = (initialModalState = {}) => {
  /**
   * @type {{
   *  show: boolean,
   *  open: () => void,
   *  close: () => void,
   *  update: (key: string, value: any) => void
   * }} State (props) for Modal,
   * can be populated with custom key/value pairs
   * @property {boolean} show Modal's visibility
   */
  const ModalState = reactive({
    show: false,
    open: () => {
      ModalState.show = true
    },
    close: () => {
      ModalState.show = false
    },
    update: (key, value) => {
      ModalState[key] = value
    },
    // TORefs?
    ...initialModalState // spread provided state object into local state,
  })

  const openModal = () => {
    ModalState.show = true
  }

  const closeModal = () => {
    ModalState.show = false
  }

  const toggleModal = (override = null) => {
    const existingState = ModalState.show
    ModalState.show = !existingState
    ModalState.show =
      override !== null && typeof override === 'boolean'
        ? override
        : !existingState
  }

  const updateModalState = (key, value) => {
    ModalState[key] = value
  }

  /**
   * Watch for changes in the modal state to reset it
   * back to provided state
   */
  watch(
    () => ModalState.show,
    (isShowing, wasShowing) => {
      if (wasShowing) {
        // Clear the state when the modal becomes invisible
        // Wrap it in a brief timeout
        // to ensure the modal isn't de-populated of data for
        // rendering before its 'fade-out' animation completes
        setTimeout(() => {
          Object.keys(initialModalState).forEach(k => {
            ModalState[k] = initialModalState[k]
          })
        }, 500)
      }
    }
  )

  return {
    ModalState,
    openModal,
    closeModal,
    toggleModal,
    updateModalState
  }
}

export default useModal
