/**
 * Linter requires using
 * Object.prototype.hasOwnProperty.call
 * for Object.hasOwnProperty.
 * This is just shorthand for this common function.
 *
 * @param {Object} object
 * @param {string} property
 * @return {boolean}
 */
export const objectHasProperty = (object = {}, property = '') => {
  return Object.prototype.hasOwnProperty.call(object, property)
}
