// useUpdateComment.js

import { useMutation } from '@vue/apollo-composable'
import { ApolloManager } from '..'
import { updateComment as updateCommentMutation } from '@/graphql/mutations'
import gql from 'graphql-tag'

const UPDATE_COMMENT = gql`
  ${updateCommentMutation}
`

/**
 * Composable for updating a comment. Returns an object containing the function
 * for updating a comment that must be fed a comment with the internal data
 * containing the modifications.
 *
 * @return {{
 *  updateComment: (comment: object) => void
 * }} Object with a function to call for updating a comment. Comment argument is destructured to
 *    provide graphql only with necessary parameters (commentId, body, objectId, metadata, annotationAnchor).
 *
 * @example
 * ```js
 * const { updateComment } = useUpdateComment()
 *
 * const commentUpdates = reactive({ ...originalComment })
 *
 * const onUpdate = () => {
 *   updateComment(commentUpdates)
 * }
 *
 * ```
 */
export const useUpdateComment = () => {
  const { mutate: updateComment } = useMutation(UPDATE_COMMENT, () => ({
    ...ApolloManager.DEFAULT_QUERY_OPTIONS,
    fetchPolicy: 'no-cache'
  }))
  return {
    updateComment: ({
      commentId,
      body,
      objectId,
      metadata,
      annotationAnchor
    }) => {
      if (!commentId || !body || !objectId) {
        console.log('useUpdateComment() parameter is missing or invalid', {
          commentId,
          body,
          objectId
        })
        return
      }
      updateComment({ commentId, body, objectId, metadata, annotationAnchor })
    }
  }
}
