/**
 * Takes a list of promises and returns mapped such that
 * no promise will cancel the Promise.all, instead returning
 * null. As a result awaiting this will not throw.
 *
 * @param {Promise[]} promises
 * @param {( index: number, err: Error ) => void} onError
 *
 * @returns {Promise[]}
 */
export const promiseListSkipErrors = (promises, onError) =>
  Promise.all(
    promises.map((p, i) =>
      p.catch(err => {
        onError && onError(i, err)
        // return null
        // so that Promise.all may continue
        return null
      })
    )
  )
