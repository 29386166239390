<script>
import { watch, ref, toRef } from 'vue'
import * as CommonProps from '@/components/_base/common/props'

/**
 * Base checkbox component
 */
export default {
  name: 'BaseCheckbox',
  emits: {
    /**
     *
     */
    change: null
  },
  props: {
    /**
     *
     */
    checked: CommonProps.BOOLEAN
  },
  setup (props) {
    const localChecked = ref(props.checked)

    watch(toRef(props, 'checked'), _checked => {
      // sync the state
      localChecked.value = _checked
    })

    return {
      localChecked
    }
  }
}
</script>

<template>
  <input
    @click.stop="$emit('change')"
    type="checkbox"
    class="rounded text-brand-blue focus:ring-brand-blue border-brand-gray-dark cursor-pointer"
    :checked="localChecked"
  />
</template>

<style scoped></style>
