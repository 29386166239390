export const MIXPANEL_EVENTS_USER = {
  USER_SIGN_IN: 'user_sign_in',
  USER_SIGN_IN_ERROR: 'user_sign_in_error',

  USER_SIGN_UP: 'user_sign_up',
  USER_SIGN_UP_ERROR: 'user_sign_up_error',

  USER_SIGN_OUT: 'user_sign_out',

  USER_FORGOT_PASSWORD: 'user_forgot_password',
  USER_FORGOT_PASSWORD_CODE: 'user_forgot_password_code',
  USER_FORGOT_PASSWORD_CODE_ERROR: 'user_forgot_password_code_error',

  USER_ORG_LIST: 'user_list_orgs', // TODO: add this somewhere

  // router
  USER_PROFILE_VIEWED: 'user_profile_viewed',
  USER_ORGS_VIEWED: 'user_orgs_viewed'
}
