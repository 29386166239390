<script>
/**
 * Form wrapper for modals so that flows may use enter to submit
 * actions.
 */
export default {
  name: 'BaseModalForm'
}
</script>

<template>
  <form class="grid grid-flow-row gap-y-6">
    <slot />
  </form>
</template>

<style></style>
