import { DeviceApiCommunicator } from '@/api'
import { DeviceJob } from './'

/**
 * Device = scanner
 *
 */
export class Device {
  static STATUSES = {
    IDLE: 'idle',
    ONLINE: 'online',
    CONNECTING: 'connecting',
    SCANNING: 'scanning',
    SCANNING_PAUSED: 'scanning-paused',
    CAPTURING: 'capturing',
    OFFLINE: 'offline',
    BUSY: 'busy'
  }

  static MOCK_DATA = {
    active: false,
    createdAt: Date.now() - 100000,
    deviceId: 'mockId',
    deviceMetadata: {},
    deviceName: 'My Test Device',
    deviceSerial: 'bbb',
    deviceStatus: Device.STATUSES.ONLINE,
    macAddress: 'mmm',
    orgId: 'ooo',
    updatedAt: Date.now()
  }

  constructor (json = {}) {
    this.apiCommunicator = new DeviceApiCommunicator(json.deviceId)
    this.active = json.active
    this.createdAt = json.createdAt
    this.deviceId = json.deviceId
    this.deviceMetadata = json.deviceMetadata
    this.deviceName = json.deviceName
    this.deviceSerial = json.deviceSerial
    this.deviceStatus = json.deviceStatus
    this.macAddress = json.macAddress
    this.orgId = json.orgId
    this.updatedAt = json.updatedAt
    this.jobData = null

    const jobData = json.jobData
    if (jobData) {
      this.jobData = new DeviceJob(jobData)
    }
  }

  isMock () {
    return this.deviceId === 'mockId'
  }

  getJobData () {
    return this.jobData
  }

  setJobData (jobData) {
    this.jobData = new DeviceJob(jobData)
  }

  setDeviceStatus (status) {
    this.deviceStatus = status
    return this
  }

  isOnline () {
    const { ONLINE } = Device.STATUSES
    return this.deviceStatus === ONLINE
  }

  isConnecting () {
    const { CONNECTING } = Device.STATUSES
    return this.deviceStatus === CONNECTING
  }

  isIdle () {
    const { IDLE } = Device.STATUSES
    return this.deviceStatus === IDLE
  }

  isScanning () {
    const { SCANNING } = Device.STATUSES
    return this.deviceStatus === SCANNING
  }

  isScanningPaused () {
    const { SCANNING_PAUSED } = Device.STATUSES
    return this.deviceStatus === SCANNING_PAUSED
  }

  isCapturing () {
    const { CAPTURING } = Device.STATUSES
    return this.deviceStatus === CAPTURING
  }

  isOffline () {
    const { OFFLINE } = Device.STATUSES
    return this.deviceStatus === OFFLINE
  }

  isBusy () {
    const { BUSY } = Device.STATUSES
    return this.deviceStatus === BUSY
  }

  // set the devices data context
  // for future operations
  prepare ({ projectId, partId } = {}) {
    const apiCommunicator = this.apiCommunicator
    apiCommunicator.projectId = projectId
    apiCommunicator.partId = partId
  }

  async startConnection () {
    const apiCommunicator = this.apiCommunicator
    const res = await apiCommunicator.startConnection()
    console.log('startConnection()', res)
  }

  async startScan () {
    const apiCommunicator = this.apiCommunicator
    const res = await apiCommunicator.startScan()
    console.log('startScan() response', res)
  }

  async finishScan () {
    const apiCommunicator = this.apiCommunicator
    const res = await apiCommunicator.finishScan()
    console.log('finishScan() response', res)
  }

  async startCapture (scanName) {
    const apiCommunicator = this.apiCommunicator
    const res = await apiCommunicator.startCapture(scanName)
    console.log('startCapture() response', res)
  }

  async abortScan () {
    const apiCommunicator = this.apiCommunicator
    const res = await apiCommunicator.abortScan()
    console.log('abortScan() response', res)
  }

  async endConnection () {
    const apiCommunicator = this.apiCommunicator
    const res = await apiCommunicator.endConnection()
    console.log('endConnection() resposne', res)
  }
}
