const navbar = () =>
  import(/* webpackChunkName: 'navbar' */ '@/components/shared/TheNavbar.vue')

export const userAccountProfile = {
  name: 'user-account-profile',
  path: 'user-account-profile',
  component: () => import('../views/UserAccountProfile.vue')
}

export const userAccountOrganizations = {
  name: 'user-account-organizations',
  path: 'user-account-organizations',
  component: () => import('../views/UserAccountOrganizations.vue')
}

export const userAccount = {
  name: 'user-account',
  path: 'user-account',
  components: {
    default: () =>
      import(/* webpackChunkName: 'user-account' */ '../layouts/Settings.vue'),
    leftSidebar: () =>
      import('@/components/user-account/TheUserAccountLeftSidebar.vue'),
    navbar
  },
  meta: { requiresAuth: true },
  children: [userAccountProfile, userAccountOrganizations]
}
