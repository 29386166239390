import { computed, reactive } from 'vue'
/**
 * Placeholders in BaseAvatar.vue
 * supplied with a 'handle' prop generate an
 * avatar with a randomize (then cached) background behin
 * the handle's as initials.
 *
 * To preserve a user's 'color' for consistency, store
 * the color in a reactive object that persists in user sessions.
 *
 */
const colorIndex = reactive({})

/**
 * Retruns a random tailwind class color for baseavatar.
 *
 * @returns {string} The random color as a tailwind background class.
 */
const randomColor = () => {
  // list possible bg colors for the user avatar
  // need to provide full tailwind class to bypass purge css
  const potentialColors = [
    'bg-pink-400',
    'bg-green-400',
    'bg-yellow-300'
  ]
  const randomIndex = Math.floor(Math.random() * (potentialColors.length + 1))
  return potentialColors[randomIndex]
}

const setColorForId = id => {
  const color = randomColor()
  colorIndex[id] = color
  return color
}

const colorForId = id => {
  if (colorIndex[id]) {
    return colorIndex[id]
  }
  // else we'll set it in the local store,
  // and return it
  return setColorForId(id)
}

/**
 * Composable for using user cached UI info.
 * Currently only stores userColor for userId for BaseAvatar.vue
 *
 * @param {string} userId The user Id.
 * @return {{
 *   userColor: computed
 * }} Object with destructurable properties relating to user info.
 */
export const useAvatarColorCache = id => {
  return {
    randomColor: computed(() => colorForId(id))
  }
}
