import { ApiNames, Methods, callLambdaApi, ModelApiCommunicator } from '@/api'
import { Project, SearchService } from '@/store/models'
import { baseNameFromFile } from '@/utils'

export class ProjectApiCommunicator {
  projectId
  useSearchAPIForListParts

  constructor (projectId) {
    this.useSearchAPIForListParts = true
    this.projectId = projectId
  }

  /**
   * Due to a permissions workaround, the parts list
   * returned from the api fetch has to be ignored.
   * We instead need to use the search API to populate a project instance's parts list.
   */
  async fetch () {
    const projectObj = await this.fetchDetails()
    if (this.useSearchAPIForListParts) {
      const partsSearchResults = await this.fetchPartsFromSearch()
      projectObj.parts = partsSearchResults.items || []
    }
    return projectObj
  }

  fetchDetails () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: `/projects/${this.projectId}`
    })
  }

  fetchPartsFromAPI () {
    // unused as of now
    return []
  }

  fetchPartsFromSearch () {
    const from = 0
    const size = 50
    const query = ''
    const endpoint = '/parts'
    const path = SearchService.buildQueryString(
      endpoint,
      query,
      from,
      size,
      this.projectId
    )
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Search,
      path
    })
  }

  rename (newName) {
    return callLambdaApi({
      method: Methods.PUT,
      apiName: ApiNames.Projects,
      path: `/projects/${this.projectId}`,
      payload: {
        body: {
          projectName: newName
        }
      }
    })
  }

  delete () {
    return callLambdaApi({
      method: Methods.DELETE,
      apiName: ApiNames.Projects,
      path: `/projects/${this.projectId}`
    })
  }

  fetchModels () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: `/projects/${this.projectId}/models`
    })
  }

  duplicate (projectName) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `/projects/${this.projectId}/duplicate`,
      payload: {
        body: {
          projectName
        }
      }
    })
  }

  /**
   * Currently ProjectCreate API can return an object of uploadURLs
   * if it is provided with an uploadFileName. The returned
   * structure of the project, however, does not match the strucutre
   * of projects else where (there is no parts array and models array
   * is on the project object).
   *
   * To get around this current UI needs to follow this flow because other
   * applications are dependent on this API flow.
   *
   * The process is:
   *
   * - Create the project using the file's name (without the file extension)
   *   as the project name.
   * - Cache the uploadURLs object from the return and use it for the aws Storage
   *   put.
   * - Refetch the project and add it to the projects list in the store.
   *
   * @param {File} file The VALIDATED file to upload to the default model.
   * @returns {Promise<Project>} Promise for project fetch
   *
   */
  static async createWithMeshUpload (file) {
    const projectName = baseNameFromFile(file.name)
    const uploadFileName = file.name
    // get the new project as a
    // a temporary object
    // to cache and use its models key value
    const projectAsObject = await ProjectApiCommunicator.create(
      projectName,
      uploadFileName
    )
    const defaultModel = projectAsObject.models[0]
    const { unsignedUrl, uploadBucket } = defaultModel.uploadUrls

    await ModelApiCommunicator.uploadMesh(file, { unsignedUrl, uploadBucket })

    const newProject = ProjectApiCommunicator.fetch(projectAsObject.projectId)

    return newProject
  }

  /**
   * Makes post to API to create project with provided name.
   * If the API return object has 'models' as a key, the
   * return of this call will  be an object and not a Project instance.
   *
   * @see {ProjectApiCommunicator.createWithMeshUpload}
   *
   * @param {string} projectName The name of the new project
   * @param {?string} uploadFileName Optional name for an upload file used for uploading
   * a mesh to default model in project creation. Note, this flow is different than uploading
   * a reference CAD.
   * @returns {Promise<Object>}
   */
  static async create (projectName, uploadFileName) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: '/projects',
      payload: {
        body: {
          projectName,
          uploadFileName
        }
      }
    })
  }

  // TODO:
  //  - [ ] Add another create inside Project class
  //        that returns as project yet calls this create.
  static async createAndGetAsProject (projectName) {
    const projectResponse = await ProjectApiCommunicator.create(projectName)
    return new Project(projectResponse)
  }
}
