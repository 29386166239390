<script>
import { computed } from 'vue'
import { useTailwindConfig } from '@/composables'
import * as CommonProps from '../common/props'

export default {
  name: 'BaseIconButton',
  emits: {
    /**
     *
     */
    emit: null
  },
  props: {
    /**
     *
     */
    bgColor: {
      ...CommonProps.STRING,
      default: 'transparent'
    },
    /**
     *
     */
    icon: CommonProps.ICON,
    /**
     *
     */
    iconSize: {
      ...CommonProps.NUMBER,
      default: 16
    },
    /**
     *
     */
    iconColor: CommonProps.STRING,
    /**
     *
     */
    border: CommonProps.BOOLEAN
  },
  setup (props) {
    const { colors } = useTailwindConfig()
    const ringColor = computed(() => {
      const bgColor = props.bgColor
      return bgColor === 'transparent' ? colors['brand-gray'].dark : bgColor
    })

    const computedIconColor = computed(() => {
      const iconColor = props.iconColor
      return iconColor || colors['brand-gray'].dark
    })

    return {
      ringColor,
      computedIconColor
    }
  }
}
</script>
<template>
  <button
    :class="{
      'border border-brand-gray-light py-1 px-2 hover:bg-brand-gray-light transition-colors': border,
      'p-0.5': !border
    }"
  >
    <BaseIcon :icon="icon" :size="iconSize" :color="computedIconColor" />
  </button>
</template>

<style scoped>
button {
  @apply rounded-md focus:ring-2 active:ring-2;
  --tw-ring-color: v-bind(ringColor);
  background-color: v-bind(bgColor);
  width: max-content;
  height: max-content;
}
</style>
