import { StoreManager } from '@/store'
import config from '@/config'

const ORG_ID_HEADER_KEY = 'x-org-id'
const APP_ID_HEADER_KEY = 'x-app-id'
const APP_ID_HEADER_VALUE = config.Amplify.Auth.userPoolWebClientId

export const resolveHeaders = orgId => {
  const resolvedXOrgId = resolveXOrgId(orgId)
  let headers = {}
  if (resolvedXOrgId) {
    headers = {
      ...headers,
      ...formatOrgIdHeader(resolvedXOrgId)
    }
  }
  headers = { ...headers, ...formatAppIdHeader() }

  return headers
}

const resolveXOrgId = orgId => {
  const store = StoreManager.storeInstance
  const cachedOrgId = store.activeOrgId
  return orgId || cachedOrgId
}

const formatOrgIdHeader = orgId => ({
  [ORG_ID_HEADER_KEY]: orgId
})
const formatAppIdHeader = () => ({ [APP_ID_HEADER_KEY]: APP_ID_HEADER_VALUE })
