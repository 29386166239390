export const MIXPANEL_EVENTS_ORGANIZATION = {
  ORG_ADD: 'org_add', // create org
  ORG_DELETE: 'org_delete',
  ORG_INVITE_ACCEPT: 'org_invite_accept',
  ORG_MAKE_DEFAULT: 'org_make_default',
  ORG_MEMBER_ADD: 'org_member_add',
  ORG_MEMBER_DELETE: 'org_member_delete',
  ORG_OWNERSHIP_TRANSFERRED: 'org_ownership_transferred',
  ORG_SWITCH: 'org_switch',
  // Routers
  ORG_SETTINGS_VIEWED: 'org_settings_viewed',
  ORG_MEMBERS_VIEWED: 'org_members_viewed'
}
