import { provide, watch } from 'vue'
import { StoreManager } from '@/store'
import { ApolloManager } from './'
import { DefaultApolloClient } from '@vue/apollo-composable'

/**
 * Create and provide the session apollo client
 * @returns {ApolloClient} session's apollo client
 */
export const provideApolloClient = () => {
  const apolloClient = initApolloClient()
  provide(DefaultApolloClient, apolloClient)
  watchUserAndClearCacheOnLogout()
  return apolloClient
}

/**
 * Create the apollo client
 * @returns {ApolloClient} session's apollo client
 */
const initApolloClient = () => {
  const apolloManager = new ApolloManager()
  const apolloClient = apolloManager.initClient()
  return apolloClient
}

const watchUserAndClearCacheOnLogout = () => {
  const store = StoreManager.storeInstance
  const authedUserWatcher = () => store?.currentUser

  watch(authedUserWatcher, (currentUser, previousUser) => {
    const wasLoggedIn = previousUser && previousUser.isValid()
    const didLogout = !currentUser || !currentUser.isValid()

    if (wasLoggedIn && didLogout) {
      resetApolloClientStore()
    }
  })
}

const resetApolloClientStore = () => {
  const client = ApolloManager.instance
  client.resetStore()
}
