const brandBlue = {
  // Selected menu items / Icon buttons
  light: '#EAEFF2',
  // Buttons, main CTA
  DEFAULT: '#325d7e',
  dark: '#244259',
  darker: '#273543'
}

const brandGray = {
  // Hover state for comments, dropdown menu items, etc.
  lighter: '#F2F2F2',
  // Outlines/borders/dividers
  light: '#E6E6E6',
  // Placholder text
  dark: '#999999',
  // Secondary text
  darker: '#666666'
}

const brandBackground = {
  // BG color for dashboard/modal
  'brand-background': '#F9F9FA'
}

const brandAccent = {
  // Snackbar CTA
  'brand-accent': '#4EADA9'
}

const brandRed = {
  light: '#FDEBEB',
  // Error text, destructive buttons
  DEFAULT: '#F03738',
  // Accent text color dialog, Pass or fail dialog
  dark: '#B4292A'
}

const brandGreen = {
  // Color dialog background, Pass or fail dialog
  light: '#EBF9EB',
  // Success text
  DEFAULT: '#3CC13B',
  // Accent text color dialog, Pass or fail dialog
  dark: '#2D912C'
}

const brandYellow = {
  DEFAULT: '#FCD34D'
}

const purgeFileMatch = [
  './public/index.html',
  './src/**/*.stories.{js,mdx}',
  './src/**/*.{vue,js,ts,jsx,tsx}',
  './src/composables/useUserCache.js' // needed for randomized avatar color
]

/**
 * @type {import('tailwindcss').Config}
 */
module.exports = {
  content: purgeFileMatch,
  // needed for jit mode,
  // seems to be a bug with fast-glob in tailwindcss not respecting
  // nested file.
  // Components requiring jit features need to be manually set here
  // Alternatively, you can save this file which'll invalidate the webpack css
  // cache and trigger a rebuild...
  theme: {
    extend: {
      colors: {
        'brand-blue': brandBlue,
        'brand-gray': brandGray,
        'brand-red': brandRed,
        'brand-green': brandGreen,
        'brand-yellow': brandYellow,
        ...brandBackground,
        ...brandAccent
      },
      spacing: {
        72: '18rem',
        80: '20rem'
      }
    },
    screens: {
      sm: '640px',
      // => @media (min-width: 640px) { ... }
      md: '768px',
      // => @media (min-width: 768px) { ... }
      lg: '1024px',
      // => @media (min-width: 1024px) { ... }
      xl: '1280px',
      // => @media (min-width: 1280px) { ... }
      '2xl': '1536px',
      // => @media (min-width: 1536px) { ... }
      '3xl': '2160px'
    }
  },
  plugins: [require('@tailwindcss/forms')]
}
