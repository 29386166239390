/**
 * Takes in a file name and returns the regex split between name and its extension
 * @param {string} fileName Name of the file
 * @returns {string[]}
 */
const splitFileName = fileName => {
  return (
    (
      (fileName && fileName.match(/^(.*?[^.]\.*?)(?:\.(\w*)){0,1}$/)) ||
      []
    ).slice(1) || []
  )
}

export default splitFileName
