/**
 * @param {Error} err
 */
export const errorImpliesLimitError = (err = {}) => {
  const { data = {} } = err.response || {}
  if (data.issue === 'over-limit') {
    return true
  }
  return false
}
