module.exports = {
  MAX_ATTACHMENT_SIZE: 500000000,
  ENV: process.env.NODE_ENV,
  sqs: {
    REGION: process.env.VUE_APP_AWS_REGION
  },
  mixpanel: {
    token: process.env.VUE_APP_MIXPANEL_TOKEN
  },
  sentry: {
    dsn: process.env.VUE_APP_SENTRY_DSN,
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENRTY_TRACES_SAMPLE_RATE),
    environment: process.env.VUE_APP_SENTRY_ENV
  },
  pusher: {
    appKey: process.env.VUE_APP_PUSHER_APP_KEY,
    appCluster: process.env.VUE_APP_PUSHER_APP_CLUSTER
  },
  logs: {
    level: process.env.VUE_APP_AMPLIFY_LOG_LEVEL
  },
  AWS: {
    s3CDN: process.env.VUE_APP_AWS_S3_CDN,
    userUploadBucket: process.env.VUE_APP_AWS_USER_UPLOAD_BUCKET
  },
  Amplify: {
    Auth: {
      mandatorySignIn: false,
      region: process.env.VUE_APP_AWS_REGION,
      userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
      identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
      userPoolWebClientId: process.env.VUE_APP_COGNITO_APP_CLIENT_ID
    },
    Storage: {
      region: process.env.VUE_APP_AWS_REGION,
      bucket: process.env.VUE_APP_AWS_USER_STORAGE_BUCKET,
      identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID
    },
    API: {
      endpoints: [
        {
          name: 'moditprojectsapi',
          endpoint: process.env.VUE_APP_API_GATEWAY_URL,
          region: process.env.VUE_APP_AWS_REGION
        },
        {
          name: 'deviceapi',
          endpoint: process.env.VUE_APP_DEVICE_API_GATEWAY_URL,
          region: process.env.VUE_APP_AWS_REGION
        },
        {
          name: 'orgapi',
          endpoint: process.env.VUE_APP_ORG_API_GATEWAY_URL,
          region: process.env.VUE_APP_AWS_REGION
        },
        {
          name: 'searchapi',
          endpoint: process.env.VUE_APP_SEARCH_API_GATEWAY_URL,
          region: process.env.VUE_APP_AWS_REGION
        }
      ]
    }
  },
  APP_VERSION: require('../package.json').version,
  Rover: {
    app: {
      allowRegistration: true,
      meshMaxUploadMB: 250,
      cadMaxUploadMB: 250,
      legacyDashboardURL: process.env.VUE_APP_LEGACY_DASHBOARD_URL,
      modelActions: {
        allowReregister: false,
        allowSelectPoseRegistration: false
      }
    }
  }
}
