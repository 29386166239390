import { getFileBaseName } from '@GrabCAD/mesh-vue-component'
import axios from 'axios'
import { getSignedStorageUrl } from './'
/**
 * Loads and returns a formatted blob
 * from given unsigned url and data type
 *
 * @async
 * @param {string} unsignedUrl
 * @param {?string} dataType
 *
 * @returns {Promise<Blob>}
 */
export const loadBlob = async (unsignedUrl, dataType = null) => {
  const name = getFileBaseName(unsignedUrl)
  const signedUrl = await getSignedStorageUrl(unsignedUrl)
  const response = await axios.get(signedUrl, { responseType: 'arraybuffer' })
  if (response) {
    const blob = Object.assign(
      new Blob([response.data], {
        type: response.headers['content-type']
      }),
      {
        name,
        dataType
      }
    )
    return blob
  }
}
