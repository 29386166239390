import { watch, onUnmounted } from 'vue'
import { noop } from '@/utils'
import { get } from '@vueuse/core'

/**
 * @typedef {Object} CheckClickOutisdeComponentOptions
 * @property {els} componentRef List of component template refs to consider
 * @property {ref} componentRef The main component to check if click events are within
 * @property {Function} onClickOutside
 * @property {ref} watchProperty Truthy attaches event listeners
 * @property {ref} selectorRef Sibling element that can also toggle componentRef, need
 * to check against it to prevent eventListener and sibling onClick handler from interfering
 * with one another
 */

/**
 * Used for popup/dropdown menus to check if a click occurs outside the component. Used for
 * closing menus.
 * @param {CheckClickOutisdeComponentOptions} options
 */
export const useCheckClickOutsideComponent = ({
  els = [],
  componentRef,
  onClickOutside = noop,
  watchProperty,
  selectorRef = null
}) => {
  /**
   * Checks provided event to see if it was within the provided DOM context.
   * @param {Event} e Click event
   */
  const checkClickIsWithinComponent = e => {
    const clickTarget = e.target
    if (get(els).length > 0) {
      // use list of els instead of two distinct
      // TODO:
      // - [ ] Convert implementations to use arr
      const elsList = get(els)
      let targetIsInList = false
      elsList.forEach(elRef => {
        const el = get(elRef)
        const clickIsWithinEl = el?.contains(clickTarget)
        if (clickIsWithinEl) {
          targetIsInList = true
        }
      })
      !targetIsInList && get(onClickOutside)(e)
      return
    }

    const clickIsWithinComponent = get(componentRef)?.contains(e.target)
    // There is a sibling dom element and the click was within it
    const clickIsWithinSelectorEl =
      get(selectorRef) && get(selectorRef).contains(e.target)

    if (!clickIsWithinComponent && !clickIsWithinSelectorEl) {
      get(onClickOutside)(e)
    }
  }

  const destroyListener = () => {
    document.removeEventListener('mouseup', checkClickIsWithinComponent, false)
  }

  watch(watchProperty, isShowing => {
    if (isShowing) {
      document.addEventListener('mouseup', checkClickIsWithinComponent, false)
    } else {
      destroyListener()
    }
  })

  onUnmounted(destroyListener)
}
