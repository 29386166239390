import { loadBlob, ModelApiCommunicator, fetchJSON } from '@/api'
import { Version, Scan } from './'
import { simpleSort } from '@/utils'
import { COMMENT_TYPE } from '@/apollo'
import { ensurePrependedPathObject } from '../utils'
import { ModelDTO } from '../dtos'
import { Logger } from '@aws-amplify/core'

const logger = new Logger('@/store/models/Model')

/**
 * @typedef {Object} ModelCompareStats
 * @property {number[]} diagonal_scale_CAD
 * @property {object} histogram
 * @property {number} invalid_count
 * @property {object} negative_displacement
 * @property {object} overall_displacement
 * @property {object} positive_displacement
 * @property {number} total_count
 * @property {number[]} transform_rotation
 * @property {number[]} transform_translation
 */

/**
 * @typedef {Object} ModelUserMetadata
 * @property {string} material
 * @property {string} productionMethod
 */

/**
 * Model class that acts as service/interaction layer for core
 * model data.
 */
export default class Model extends ModelDTO {
  static STATUSES = {
    NEW: 'new',
    WAITING_ON_UPLOAD: 'waiting on upload',
    WAITING_UPLOAD: 'waiting upload',
    REGISTERING: 'registering',
    CLEANING_QUEUED: 'cleaning queued',
    CLEANING: 'cleaning',
    REPAIR_QUEUED: 'repair queued',
    REPAIRING: 'repairing',
    RECOGNIZE_QUEUED: 'recognize queued',
    RECOGNIZING: 'recognizing',
    IDEALIZE_QUEUED: 'idealize queued',
    IDEALIZING: 'idealizing',
    READY: 'ready',
    READY_FOR_PART: 'ready for part',
    ERROR: 'error',
    /**
     * Generating compare, should still have viewable
     * mesh on instance..
     */
    COMPARING: 'comparing'
  }

  static VALID_EXPORT_FORMATS = ['stlb', 'ply', 'step', 'parasolid']

  static VALID_EXPORT_SHAPES = ['design', 'real']

  constructor (json = {}) {
    super(json)

    /**
     * @type {Version[]}
     */
    this.versions = []

    /**
     * List of of blobs for the comparison mesh
     * generated between model and referenceCAD.
     * @type {Blob[]}
     */
    this.meshCompareBlobs = []

    /**
     * @type {Scan[]}
     */
    this.scans = []

    /**
     * @type {ModelApiCommunicator}
     */
    this.apiCommunicator = new ModelApiCommunicator({
      projectId: json.projectId,
      modelId: json.modelId,
      partId: json.partId
    })

    /**
     * Fetched from S3 on demand.
     * @type {ModelCompareStats}
     */
    this.compareStats = {}

    this.uploading = false
    this.uploadProgress = 0

    this.setWithJSON(json)
  }

  /**
   * @param {Object|Model} json
   */
  setWithJSON (json = {}) {
    this.createdAt = json.createdAt
    this.createdBy = json.createdBy
    this.failReason = json.failReason
    this.modelId = json.modelId
    this.modelName = json.modelName
    this.modelStatus = json.modelStatus
    this.partId = json.partId
    this.projectId = json.projectId
    this.updatedAt = json.updatedAt
    this.updatedBy = json.updatedBy
    this.uploadUrls = json.uploadUrls

    const self = this
    const versions = json.versions || json.modelVersions || []
    versions.forEach(v => {
      const asVersion = new Version(v)
      self.addVersionToList(asVersion)
    })

    const scans = json.scans || []
    scans.forEach(s => {
      const asScan = new Scan(s)
      self.addScanToList(asScan)
    })

    const paths = json.paths || {}
    this.paths = ensurePrependedPathObject(paths)

    const exports = json.exports || {}
    this.exports = this.getPrependedExports(exports)

    return this
  }

  getPrependedExports (exports = {}) {
    /**
     *
     * exports.clean.stlb.real
     * exports.arapidealize.stlb.design
     *
     * exports['sourceLayer']['format']['shapeType']
     */
    const prependedExports = { ...exports }
    const layerKeys = Object.keys(prependedExports)
    layerKeys.forEach(layerK => {
      const formatsForLayer = Object.keys(prependedExports[layerK])
      formatsForLayer.forEach(formatK => {
        const pathObj = prependedExports[layerK][formatK]
        prependedExports[layerK][formatK] = ensurePrependedPathObject(pathObj)
      })
    })
    return prependedExports
  }

  getPaths () {
    return this.paths || {}
  }

  isBeingProcessed () {
    const status = this.modelStatus
    const nonProcessingStatuses = [
      Model.STATUSES.NEW,
      Model.STATUSES.READY,
      Model.STATUSES.ERROR,
      Model.STATUSES.READY_FOR_PART,
      Model.STATUSES.COMPARING
    ]
    return !nonProcessingStatuses.includes(status)
  }

  isWaitingOnUpload () {
    const status = this.modelStatus
    return [Model.STATUSES.WAITING_UPLOAD, Model.STATUSES.WAITING_ON_UPLOAD].includes(status)
  }

  isUploading () {
    return this.uploading
  }

  hasError () {
    const status = this.modelStatus
    return status === Model.STATUSES.ERROR
  }

  getFailReason () {
    return this.failReason || ''
  }

  getCommentContext () {
    return { type: COMMENT_TYPE.MODEL, objectId: this.modelId }
  }

  get hasComparisonMesh () {
    return this.getHasComparisonMesh()
  }

  getHasComparisonMesh () {
    return !!this.getPaths().compareresult
  }

  getHasWebPreview () {
    return !!this.getWebPreview()
  }

  getWebPreview () {
    return this.getPaths().webpreview
  }

  getWAMPath () {
    const paths = this.getPaths()
    if (Object.prototype.hasOwnProperty.call(paths, 'wam_stl')) {
      return paths.wam_stl
    } else {
      return paths.wam
    }
  }

  hasWAM () {
    const paths = this.getPaths()
    return Object.prototype.hasOwnProperty.call(paths, 'wam') || Object.prototype.hasOwnProperty.call(paths, 'wam_stl')
  }

  async uploadExistingScanMesh (file) {
    const api = this.apiCommunicator
    const { unsignedUrl, uploadBucket } = this.uploadUrls
    const self = this
    self.uploading = true
    try {
      await api.uploadMesh(file, {
        unsignedUrl,
        uploadBucket,
        progressCallback: progress => {
          console.log('Progress!', progress.loaded / progress.total)
          self.uploadProgress = Math.round(progress.loaded * 100 / progress.total)
        }
      })
      logger.info('uploadExistingScanMesh(file) success')
    } catch (err) {
      logger.error(err)
    }
    self.uploading = false
  }

  /**
   * Loads the comparison mesh as a blob and stores on member variable.
   *
   * @returns {Promise<Blob[]>} List of blobs for comparison mesh.
   */
  async loadMeshCompareBlobs () {
    const compareMesh = this.paths.compareresult
    const blob = await loadBlob(compareMesh)
    this.meshCompareBlobs = [blob]
    return this.meshCompareBlobs
  }

  async loadCompareResultStats () {
    const paths = this.getPaths()
    const unsignedStatsUrl = paths.compareresulthistogram
    if (unsignedStatsUrl) {
      const res = await fetchJSON(unsignedStatsUrl)
      this.compareStats = res
    }
  }

  getCompareStats () {
    return this.compareStats || {}
  }

  getMeshCompareBlobs () {
    return this.meshCompareBlobs || []
  }

  hasMeshCompareBlobs () {
    return this.getMeshCompareBlobs().length > 0
  }

  /** @returns {Version[]} Current model's versions sorted by updated timestamp */
  versionsByUpdatedTimestamp (asc = false) {
    return this.versions.sort((a, b) =>
      simpleSort(a.updatedAt, b.updatedAt, asc)
    )
  }

  getVersions () {
    return this.versions || []
  }

  getVersionsByUpdatedTimestamp (list, asc = false) {
    if (!list) {
      list = this.versions
    }
    return list.sort((a, b) => {
      const aUpdatedAt = a.updatedAt
      const bUpdatedAt = b.updatedAt
      return simpleSort(aUpdatedAt, bUpdatedAt, asc)
    })
  }

  hasCleanVersion () {
    return this.getCleanVersions().length > 0
  }

  hasCleanVersionWithPath () {
    return this.getCleanVersionsWithPaths().length > 0
  }

  getFirstCleanVersion () {
    const cleanVersions = this.getCleanVersions()
    return cleanVersions?.[0]
  }

  getCleanVersions () {
    return this.versions.filter(v => v.isClean())
  }

  getCleanVersionsWithPaths () {
    return this.versions.filter(v => v.isClean() && v.hasPaths())
  }

  /**
   * If the model contains a version with "userUpload"
   * transformationProcess key-value, assume this model
   * was generated through uploading existing mesh.
   */
  versionsImplyUserUpload () {
    const versions = this.getVersions()
    return versions.filter(v => v.isUserUpload()).length > 0
  }

  hasVersionInList (version) {
    const versionIdList = this.versions.map(v => v.modelVersionId)
    return versionIdList.includes(version.modelVersionId)
  }

  addVersionToList (version) {
    if (!this.hasVersionInList(version)) {
      this.versions.push(version)
    }
    return this.versions
  }

  updateVersionInList (version) {
    const versions = this.versions
    if (version) {
      this.versions = versions.map(v => {
        if (v.modelVersionId === version.modelVersionId) {
          return versions
        }
        return v
      })
    }
    return this.versions
  }

  /** @returns {?Version} Current model's most recently updated version */
  get latestUpdatedVersion () {
    return this.getLatestUpdatedVersion()
  }

  getLatestUpdatedVersion () {
    const versions = this.versionsByUpdatedTimestamp()
    const latestVersion = versions[0]
    return latestVersion
  }

  /**
   * @returns {Scan[]}
   */
  getScans () {
    return this.scans || []
  }

  getScanCount () {
    return this.getScans().length
  }

  /**
   * @returns {{
   *  poseId: Scan[]
   * }}
   */
  getScansGroupedByPose () {
    const scans = this.getScans()
    return scans.reduce((acc, scan) => {
      const poseId = scan.poseId
      const existingList = acc[poseId] || []
      return {
        ...acc,
        [poseId]: [...existingList, scan]
      }
    }, {})
  }

  /**
   *
   * @param {string} poseId
   * @returns {Scan[]}
   */
  getScansByPoseId (poseId) {
    return this.getScans().filter(s => s.poseId === parseInt(poseId))
  }

  /**
   * @param {Scan} scan
   * @returns {boolean}
   */
  hasScanInList (scan) {
    const scanIdList = this.getScans().map(s => s.scanId)
    return scanIdList.includes(scan.scanId)
  }

  /**
   * @param {Scan} scan
   * @returns {Scan[]}
   */
  addScanToList (scan) {
    if (!this.hasScanInList(scan)) {
      this.scans.push(scan)
    }
    return this.getScans()
  }

  async fetchAndStoreDetails () {
    const apiCommunicator = this.apiCommunicator
    const modelResponse = await apiCommunicator.fetch()
    this.setWithJSON(modelResponse)
    return this
  }

  /** @returns {Promise<Object[]>} List of versions */
  async fetchAndStoreVersions () {
    const apiCommunicator = this.apiCommunicator
    const versions = await apiCommunicator.fetchVersions()
    this.versions = versions.map(v => new Version(v))
    return this.versions
  }

  /**
   * @param {string} modelName
   */
  async rename (modelName) {
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.update({ modelName })
    this.modelName = modelName
  }

  /**
   * @returns {ModelUserMetadata|Object}
   */
  getUserMetadata () {
    return this.userMetadata || {}
  }

  /**
   * @param {ModelUserMetadata} userMetadata
   */
  async updateUserMetadata (userMetadata) {
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.update({ userMetadata })
    this.userMetadata = {
      ...this.userMetadata,
      ...userMetadata
    }
  }

  async update ({ modelName = null, userMetadata = null }) {
    const payload = {
      ...(modelName && { modelName }),
      ...(userMetadata && { userMetadata })
    }
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.update(payload)
    if (modelName) {
      this.modelName = modelName
    }
    if (userMetadata) {
      this.userMetadata = {
        ...this.userMetadata,
        ...userMetadata
      }
    }
  }

  delete () {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.delete()
  }

  copyToPart (destinationProjectId, destinationPartId) {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.copy(destinationProjectId, destinationPartId)
  }

  register ({ poses = [], scanIds = [] } = {}) {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.register({ poses, scanIds })
  }

  generateExport (layer, fileTypes, shapeTypes) {
    const apiCommunicator = this.apiCommunicator
    // VALIDATE THE ARGS
    console.warn('TODO: VALIDATE THE ARGS')
    return apiCommunicator.generateExport(layer, fileTypes, shapeTypes)
  }

  getExports () {
    return this.exports || {}
  }

  /**
   * @param {'clean'|'arapidealize'} layer
   */
  getExportsForLayer (layer) {
    return this.getExports()[layer] || {}
  }

  getExportsForLayerFormat (layer, format) {
    return this.getExportsForLayer(layer)[format] || {}
  }

  getExportForLayerFormatShape (layer, format, shape) {
    return this.getExportsForLayerFormat(layer, format)[shape]
  }

  hasExportKey (layer, format, shape) {
    return Object.prototype.hasOwnProperty.call(
      this.getExportsForLayerFormat(layer, format),
      shape
    )
  }

  exportIsProcessing (layer, format, shape) {
    return (
      this.hasExportKey(layer, format, shape) &&
      this.getExportForLayerFormatShape(layer, format, shape) === null
    )
  }
}
