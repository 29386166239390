import {
  Auth,
  PusherManager,
  User,
  Organization,
  OrganizationPlan
} from './models'
import { OrganizationApiCommunicator } from '@/api'
import { trackEvent } from '@/analytics'
import { MIXPANEL_EVENTS_ORGANIZATION } from '@/analytics/events'
import { Logger } from '@aws-amplify/core'

const logger = new Logger('Store')

/**
 * Root store class
 */
class Store {
  constructor () {
    /**
     * @type {Auth}
     */
    this.auth = new Auth()

    this.activeOrgId = null

    /**
     * @type {Organization[]}
     */
    this.organizationList = []

    /**
     * @type {PusherManager}
     */
    this.pusherManager = new PusherManager()

    /**
     * @type {User}
     */
    this.currentUser = null
  }

  getCurrentUser () {
    return this.currentUser
  }

  setCurrentUser (userData) {
    this.currentUser = new User(userData)
  }

  async fetchAndStoreUser () {
    const auth = this.auth
    const userInfo = await auth.fetchUser()
    this.setCurrentUser(userInfo)
  }

  /**
   * @type {Organization}
   * This is interpreted as a vue computed property.
   * The user's active org is determined by this.activeOrgId.
   * But all of the actual org instances are cached in this.organizationList,
   * so that we can better control caching of instance properties.
   */
  get organization () {
    return this.getActiveOrganization()
  }

  getOrgList () {
    return this.organizationList
  }

  getActiveOrganization () {
    return this.getOrgById(this.activeOrgId)
  }

  setActiveOrg (org) {
    this.activeOrgId = org.orgId
  }

  orgIsInList (org) {
    const orgIds = this.organizationList.map(o => o.orgId)
    return orgIds.includes(org.orgId)
  }

  addOrgToList (org) {
    if (!this.orgIsInList(org)) {
      this.organizationList.push(org)
    }
  }

  /**
   * @param {Organization} org
   */
  replaceOrgInList (org) {
    const orgs = this.getOrgList()
    const updatedList = orgs.map(o => {
      if (o.orgId === org.orgId) {
        return org
      }
      return o
    })

    this.organizationList = updatedList
    return this.getOrgList()
  }

  /**
   * @param {Organization|Object} orgToUpdate
   */
  updateOrgInList (orgToUpdate) {
    const cachedOrg = this.getOrgById(orgToUpdate.orgId)
    cachedOrg?.setWithJSON(orgToUpdate)
    return this.getOrgList()
  }

  /**
   * @param {Organization} org
   */
  switchOrg (org) {
    this.setActiveOrg(org)
    this.auth.changeOrganization(org.orgId)
    this.pusherManager.changeOrganization(org.orgId)
    this.currentUser.fetchAndStoreDefaults()

    org.fetchAndStoreDetails()
    org.fetchAndStorePlanDetails()

    trackEvent(MIXPANEL_EVENTS_ORGANIZATION.ORG_SWITCH, {
      orgId: org.orgId
    })
  }

  setOrgFromCache (orgData = {}) {
    const lastSessionOrgId = orgData.currentOrgId
    const defaultOrg = orgData.defaultOrg

    let org
    if (lastSessionOrgId) {
      org = new Organization({ orgId: lastSessionOrgId })
    } else if (defaultOrg) {
      org = new Organization(defaultOrg)
    }
    org && this.setActiveOrg(org)
  }

  /**
   * When user leaves an org,
   * ensure it's not the 'active org'. If it is.
   * Switch them to another here.
   */
  checkShouldSwitchOrgOnLeave (orgId) {
    const store = this
    if (orgId === this.activeOrgId) {
      const remainingOrgs = this.organizationList.filter(o => o.orgId !== orgId)
      const defaultOrg =
        remainingOrgs.find(o => o.userDefault) || remainingOrgs[0]
      store.switchOrg(defaultOrg)
    }
  }

  getUserDefaultOrg () {
    return (
      this.organizationList.find(o => o.userDefault) || this.organizationList[0]
    )
  }

  removeOrgFromList (orgId) {
    this.organizationList = this.organizationList.filter(o => o.orgId !== orgId)
    return this.organizationList
  }

  getOrgById (orgId) {
    return this.organizationList.find(o => o.orgId === orgId) || null
  }

  async fetchAndStoreOrgsList () {
    try {
      const response = await OrganizationApiCommunicator.fetchList()
      const fullOrgList = response?.data || []
      const store = this

      fullOrgList.forEach(o => {
        const asOrg = new Organization(o)
        if (this.orgIsInList(asOrg)) {
          // at this point we should already have a cached active org
          // in this.organization (computed property)
          // There's a few key values we need from the list GET that need to be
          // mapped onto this instance. Because this is an edge case, only do it here.
          const activeOrg = store.organization
          activeOrg.isOwner = asOrg.isOwner
          activeOrg.userDefault = asOrg.userDefault
          activeOrg.roles = asOrg.roles
        } else {
          store.addOrgToList(asOrg)
        }
      })
      return this.organizationList
    } catch (err) {
      logger.error('fetchOrgsList() error: ', err)
    }
  }

  pusherDataHandler ({ message }) {
    if (!message) {
      logger.warn('[PUSHER]: pusherDataHandler({ message }) payload was empty')
      return
    }

    const event = PusherManager.getEventFromUpdateMessage(message)
    const record = PusherManager.getRecordDataFromUpdateMessage(message)
    const {
      ORGS,
      PROJECTS,
      PARTS,
      MODELS,
      DEVICES,
      SCANS
    } = PusherManager.UPDATE_EVENTS

    logger.debug('[PUSHER]: pusherDataHandler({ message })', message)

    const orgId = record.orgId
    const projectId = record.projectId
    const partId = record.partId
    if (event === ORGS) {
      const org = this.getOrgById(orgId)
      const updatedOrg = new Organization({ ...org, ...record })
      const updatedOrgPlan = new OrganizationPlan({
        details: { features: record.features, limits: record.limits },
        plan: record.planTier || record.plan
      })
      updatedOrg.orgPlan = updatedOrgPlan
      this.updateOrgInList(updatedOrg)
    } else if (event === PROJECTS) {
      const org = this.getOrgById(orgId)
      org && org.processPusherUpdateForProjects(message)
    } else if (event === PARTS) {
      const project = this.organization.getProjectByProjectId(projectId)
      project && project.processPusherUpdateForParts(message)
    } else if (event === MODELS) {
      const project = this.organization.getProjectByProjectId(projectId)
      const part = project?.getPartById(partId)
      part && part.processPusherUpdateForModels(message)
    } else if (event === DEVICES) {
      const org = this.getOrgById(orgId)
      const orgDeviceManager = org.getDeviceManager()
      orgDeviceManager &&
        orgDeviceManager.processPusherUpdateForDevices(message)
    } else if (event === SCANS) {
      // Store does not currently account for such updates,
      // catch only to acknowledge and prevent console spam.
    } else {
      logger.debug('Unhandled update from pusherDataHandler()', message)
    }
  }

  clear () {
    this.auth && this.auth.clear()
    this.pusherManager && this.pusherManager.clear()
    this.currentUser = null
    this.activeOrgId = null
    this.organizationList = []
  }

  clearLocalStorage () {
    localStorage.clear()
  }
}

export default Store
