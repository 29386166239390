export const ANIM_STATES = {
  BEFORE_ENTER: 'beforeEnter',
  ENTER: 'enter',
  AFTER_ENTER: 'afterEnter',
  ENTER_CANCELLED: 'enterCancelled',
  BEFORE_LEAVE: 'beforeLeave',
  LEAVE: 'leave',
  AFTER_LEAVE: 'afterLeave',
  LEAVE_CANCELLED: 'leaveCancelled'
}
