import { Storage } from 'aws-amplify'
import { ApiNames, callLambdaApi, Methods } from '@/api'
import { noop } from '@/utils'

export class ModelApiCommunicator {
  constructor ({ projectId, modelId, partId }) {
    this.projectId = projectId
    this.modelId = modelId
    this.partId = partId
  }

  static uploadMesh (file, { unsignedUrl, uploadBucket }) {
    return Storage.put(unsignedUrl, file, {
      level: 'private',
      bucket: uploadBucket,
      contentType: file.type
    })
  }

  uploadMesh (file, { unsignedUrl, uploadBucket, progressCallback = noop }) {
    return Storage.put(unsignedUrl, file, {
      level: 'private',
      bucket: uploadBucket,
      contentType: file.type,
      progressCallback
    })
  }

  /**
   * If creating a model from existing scan mesh,
   * API requires additional parameters in request payload.
   */
  createWithScanMesh ({ modelName = '', uploadFileName = '' } = {}) {
    const requestBody = {
      modelName,
      uploadFileName
    }
    console.log('createWithScanMesh()')
    return this.create(requestBody)
  }

  /**
   * @param {object} requestBody
   * @param {string} requestBody.partId
   * @param {string} requestBody.modelName
   * @param {?string} requestBody.uploadFileName - Needs to exist for item to return uploadUrls
   */
  create (requestBody = { modelName: '' }) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `/projects/${this.projectId}/models`,
      payload: {
        body: {
          partId: this.partId,
          ...requestBody
        }
      }
    })
  }

  fetch () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: this._buildBaseURL()
    })
  }

  fetchVersions () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: `${this._buildBaseURL()}/versions`
    })
  }

  fetchScans () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: `${this._buildBaseURL()}/scans`
    })
  }

  update (requestBody) {
    return callLambdaApi({
      method: Methods.PUT,
      apiName: ApiNames.Projects,
      path: this._buildBaseURL(),
      payload: { body: requestBody }
    })
  }

  delete () {
    return callLambdaApi({
      method: Methods.DELETE,
      apiName: ApiNames.Projects,
      path: this._buildBaseURL()
    })
  }

  copy (destinationProjectId, destinationPartId) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `${this._buildBaseURL()}/copy`,
      payload: { body: { destinationProjectId, destinationPartId } }
    })
  }

  generateExport (sourceLayer, fileTypes, shapeTypes) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `${this._buildBaseURL()}/export`,
      payload: {
        body: { sourceLayer, fileTypes, shapeTypes }
      }
    })
  }

  register ({ poses = [], scanIds = [] }) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `${this._buildBaseURL()}/registration`,
      payload: {
        body: { poses, scanIds }
      }
    })
  }

  /**
   * Returns the default serverless api endpoint
   * using the instance's project and model ids.
   * @private
   */
  _buildBaseURL () {
    return `/projects/${this.projectId}/models/${this.modelId}`
  }
}
