import config from '@/config'

export const ApiNames = {
  Projects: config.Amplify.API.endpoints[0].name,
  Devices: config.Amplify.API.endpoints[1].name,
  Org: config.Amplify.API.endpoints[2].name,
  Search: config.Amplify.API.endpoints[3].name
}

export const ApiEndpoints = {
  Projects: config.Amplify.API.endpoints[0].endpoint,
  Devices: config.Amplify.API.endpoints[1].endpoint,
  Org: config.Amplify.API.endpoints[2].endpoint,
  Search: config.Amplify.API.endpoints[3].endpoint
}

export const Methods = {
  GET: 'get',
  POST: 'post',
  PUT: 'put',
  DELETE: 'del'
}
