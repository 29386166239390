/**
 * Wrapper class for Mesh Vue Component viewer option props.
 */
export default class MeshVueComponentViewerOptions {
  /**
   * TODO:
   * Move this out...it's not longer an MVC prop, but is still used by
   * part inspector components
   */
  static SELECTION_MODE = {
    SELECT: 'select',
    MEASURE: 'measure',
    COMMENT: 'comment'
  }

  constructor () {
    this.setDefaults()
  }

  setDefaults () {
    this.isTransformationEnabled = false
    this.isSegmentEditorEnabled = false
    this.isCenteringEnabled = true
    this.isFeaturesVisible = true
    this.shouldHideNonSelected = false
    this.isRegistrationMetadataVisible = false
    this.isReferencePlanesVisible = true
    this.isReferenceGridVisible = false
    this.isBoundingBoxVisible = false
    this.isBoundingCylinderVisible = false

    this.isSegmentSelectionEnabled = false
    this.isInfoPanelVisible = false

    this.showGoNoGo = false
    this.showCompareLUT = false

    const { SELECT } = MeshVueComponentViewerOptions.SELECTION_MODE
    this.setSelectionMode(SELECT)

    this.setDefaultMeshSceneOptions()
  }

  setDefaultMeshSceneOptions () {
    /**
     * @type {object}
     * @property {string} backgroundColor Hexcode color
     * @property {string} backgroundColorAlpha 0-1.0 alpha value
     */
    this.meshSceneOptions = {
      backgroundColor: '#F9F9FA', // brand-background tailwind,
      backgroundColorAlpha: 1.0
    }
  }

  setSelectionMode (ctx) {
    const {
      MEASURE,
      COMMENT,
      SELECT
    } = MeshVueComponentViewerOptions.SELECTION_MODE
    if (ctx === MEASURE) {
      this.isMeasurementEnabled = true
    } else {
      this.isMeasurementEnabled = false
    }
    if (ctx === MEASURE || ctx === COMMENT) {
      this.isVertexSelectionEnabled = true
    } else if (ctx === SELECT) {
      this.isVertexSelectionEnabled = false
    }

    this.selectionMode = ctx
  }

  getSelectionMode () {
    return this.selectionMode
  }

  // TODO:
  // rename from ctx -> mode
  isInCommentContext () {
    return (
      this.getSelectionMode() ===
      MeshVueComponentViewerOptions.SELECTION_MODE.COMMENT
    )
  }

  // @deprecated
  isInMeasureContext () {
    const { MEASURE } = MeshVueComponentViewerOptions.SELECTION_MODE
    return this.getSelectionMode() === MEASURE
  }

  isInCommentMode () {
    const { COMMENT } = MeshVueComponentViewerOptions.SELECTION_MODE
    return this.getSelectionMode() === COMMENT
  }

  isInMeasureMode () {
    const { MEASURE } = MeshVueComponentViewerOptions.SELECTION_MODE
    return this.getSelectionMode() === MEASURE
  }

  isInSelectMode () {
    const { SELECT } = MeshVueComponentViewerOptions.SELECTION_MODE
    return this.getSelectionMode() === SELECT
  }

  /**
   * note: if toggling, isBoundingCylinderVisible or isBoundingBoxVisible
   * the other will be set to false, as they can't both be active simultaneously.
   *
   * @param {string}   k Key of MeshViewer to toggle
   * @param {?boolean} v Optional override. If omitted, this function simply switches the boolean.
   */
  toggleOption (k, v = null) {
    const newToggle = typeof v === 'boolean' ? v : !this[k]
    this[k] = newToggle
    // unset the sibling view options
    // as they shouldn't both be active at the same time.
    if (k === 'isBoundingCylinderVisible') {
      this.isBoundingBoxVisible = false
    } else if (k === 'isBoundingBoxVisible') {
      this.isBoundingCylinderVisible = false
    }
  }
}
