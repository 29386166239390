import { StoreManager } from '@/store'
import { routeIndex } from '../routes'
/**
 * Dashboard now pushes to /project
 * but without a pre-fetched, proejctId.
 * In this case, since the query parameter of the projectId
 * is optional, recognize that difference here, and update the meta tag of the route
 * so other checks can recognize it.
 * The flow:
 *
 * 1. Check if route requires a redirect to some default project.
 *    This is done by seeing if the projectId in the route is null or empty.
 * 2. In the dashboard component, fetch the projects from network or store cache.
 * 3. Redirect the router to the default project from the retrieved projects list.
 *
 */
export const preparePotentialDashboardRedirect = to => {
  if (routeRequiresDefaultRedirectPreparation(to)) {
    to.meta.requiresRedirect = true
  }
}

/**
 *
 */
const routeRequiresDefaultRedirectPreparation = to => {
  const projectId = to.params.projectId
  const defaultProjectRequest = projectId === 'default' || !projectId
  return defaultProjectRequest
}

/**
 *
 */
export const shouldRedirectRoute = to => to.meta.requiresRedirect

/**
 * Requires passing router as arg,
 * requiring in file leads to circular dependency
 */
export const redirectDashboardByDefaultProject = router => {
  const store = StoreManager.storeInstance
  const defaultProject = store.organization.getDefaultProject()
  router.replace({
    name: routeIndex.dashboard.name,
    params: { projectId: defaultProject?.projectId }
  })
}
