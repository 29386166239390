import { convertUnits } from '@/utils'
import { compareUnitIndex } from '@/store'

export default class GoNoGoResults {
  pointsFill
  pointsIn
  pointsOut
  tolerance
  totalPoints

  constructor (data, units) {
    this.setWithObject(data)
    this.setDefaults(units)
  }

  setDefaults (units) {
    const defaultTolerance = this.getDefaultTolerance(units)
    this.tolerance = defaultTolerance
  }

  setWithObject (data = {}) {
    this.pointsFill = data.pointsFill
    this.pointsIn = data.pointsIn
    this.pointsOut = data.pointsOut
    this.totalPoints = data.totalPoints
  }

  passingPointPercentage () {
    return this.pointsIn / this.totalPoints
  }

  isPassing () {
    const requiredPercentage = this.tolerance.passPercentRequired
    return this.passingPointPercentage() >= requiredPercentage
  }

  setPassingThreshold (threshold) {
    this.tolerance.passPercentRequired = threshold
  }

  getDefaultTolerance (units = 'µm') {
    return {
      value: compareUnitIndex[units]?.passFail.default,
      units,
      min: 2,
      max: 2500,
      adjust: compareUnitIndex[units]?.passFail.default,
      passPercentRequired: 0.99
    }
  }

  updateToleranceUnits (toUnits) {
    const fromUnits = this.tolerance.units
    if (toUnits === fromUnits) {
      // prevent looping reactive updates
      return
    }
    const amount = this.tolerance.value
    this.tolerance.value = convertUnits(fromUnits, toUnits, amount)
    this.tolerance.units = toUnits
  }

  updateTolerance (key, value) {
    this.tolerance[key] = value
  }
}
