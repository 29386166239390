<script>
import { computed } from 'vue'
import MenuItemChildMenuResponder from './nested-menu/MenuItemChildMenuResponder.vue'
import NestedMenu from './nested-menu/NestedMenu.vue'
import BaseList from '../list/BaseList.vue'

import * as CommonProps from '@/components/_base/common/props'

/**
 * @typedef {Object} Item
 * @property {string} label
 * @property {string} leadingIcon
 * @property {boolean} isSelected
 * @property {boolean} inset
 * @property {string} secondaryLabel
 * @property {SelectMenuProps} childMenu
 */

/**
 * @typedef {Object} ItemGroup
 * @property {?string} label
 * @property {Item[]|ItemGroup} items
 */

/**
 * This component is responsible for the list rendering
 * of MenuItems, either provided as a grouped index
 * or list of type Item.
 *
 * It provides the childmenuresponder compoenents
 * to handle the child menu logic while keeping it separate
 * from the pure component display tree.
 */
export default {
  name: 'MenuItemList',
  components: {
    BaseList,
    MenuItemChildMenuResponder,
    NestedMenu
  },
  emits: {
    close: null
  },
  props: {
    /**
     * List of Items or Object of ItemGroup
     * @type{Item[]|ItemGroup}
     */
    items: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    /**
     *
     */
    isRoot: CommonProps.BOOLEAN,
    /**
     *
     */

    parentEl: CommonProps.HTMLEl,
    /**
     *
     */
    level: CommonProps.NUMBER,
    /**
     *
     */
    manualUpdateProperty: CommonProps.STRING
  },
  setup (props, { emit }) {
    const showGroupItems = computed(() => {
      return isItemGroup(props.items)
    })

    const isItemGroup = items => {
      return typeof items === 'object' && !Array.isArray(items)
    }

    const itemHasChildMenuItems = item => {
      const hasChildMenu = !!item?.childMenu
      if (!hasChildMenu) {
        return false
      }
      const childMenu = item.childMenu
      const isGroupMenu = isItemGroup(childMenu.items)
      let hasChildItems = false
      if (isGroupMenu) {
        hasChildItems = Object.keys(childMenu || {}).length > 0
      } else {
        hasChildItems = item?.childMenu?.items?.length > 0
      }

      return hasChildItems
    }

    const checkShouldCloseMenu = async (item, ev) => {
      if (item.closeMenuOnClick) {
        // ensure we fire the items
        // callback before closing the menu
        // TODO:
        // need to revisit this.
        // For some reason, if the item originates in a nested
        // menu, it does not automatically inerit the event bindings of its
        // props. It does if it's at the root level thogh..
        if (!props.isRoot) {
          item.onClick?.()
        }
        emit('close')
      } else {
        // stop event popogation to interrupt
        // event listener that closes
        // select menu on click outside component
        ev.stopPropagation()
      }
    }

    return {
      showGroupItems,
      itemHasChildMenuItems,
      checkShouldCloseMenu
    }
  }
}
</script>
<template>
  <template v-if="showGroupItems">
    <base-list separator>
      <menu-item-group
        v-for="(itemGroup, gIndex) in items"
        :key="gIndex"
        v-bind="itemGroup"
      >
        <template v-for="(item, index) in itemGroup.items" :key="item.label">
          <menu-item-child-menu-responder :level="level" :index="index">
            <template #default="{ showChildMenu, processHoverRequest }">
              <menu-item
                @mouseup="ev => checkShouldCloseMenu(item, ev)"
                @delayed-mouse-enter="processHoverRequest"
                v-bind="item"
                :index="index"
                :key="index"
              >
                <template #child-menu="{ rootRef }">
                  <NestedMenu
                    v-if="itemHasChildMenuItems(item)"
                    v-bind="item.childMenu"
                    @close="$emit('close')"
                    :selector-el="rootRef"
                    :level="level + 1"
                    :is-visible="showChildMenu"
                    :manual-update-property="manualUpdateProperty"
                  />
                </template>
              </menu-item>
            </template>
          </menu-item-child-menu-responder>
        </template>
      </menu-item-group>
    </base-list>
  </template>
  <template v-else>
    <base-list>
      <menu-item-child-menu-responder
        v-for="(item, index) in items"
        :key="index"
        :level="level"
        :index="index"
      >
        <template #default="{ showChildMenu, processHoverRequest }">
          <menu-item
            @mouseup="ev => checkShouldCloseMenu(item, ev)"
            @delayed-mouse-enter="processHoverRequest"
            v-bind="item"
            :index="index"
            :key="index"
          >
            <template #child-menu="{ rootRef }">
              <NestedMenu
                v-if="itemHasChildMenuItems(item)"
                v-bind="item.childMenu"
                @close="$emit('close')"
                :selector-el="rootRef"
                :level="level + 1"
                :is-visible="showChildMenu"
                :manual-update-property="manualUpdateProperty"
              />
            </template>
          </menu-item>
        </template>
      </menu-item-child-menu-responder>
    </base-list>
  </template>
</template>

<style scoped></style>
