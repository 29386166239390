import { inject } from 'vue'
import { mixpanelProviderKey } from '../mixpanel'

/**
 * General vue3 provide of app's mixpanel instance.
 * @link {https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanel}
 */
export const useMixpanel = () => {
  const mixpanel = inject(mixpanelProviderKey)
  return mixpanel
}
