// useDeleteCommentReply.js

import { useMutation } from '@vue/apollo-composable'
import { ApolloManager } from '../'
import { decrementReplyCount } from './useReplyCountForComment'
import { deleteCommentReply as deleteCommentReplyMutation } from '@/graphql/mutations'
import gql from 'graphql-tag'

const DELETE_COMMENT_REPLY = gql`
  ${deleteCommentReplyMutation}
`

/**
 * Composable for deleting a commentReply. Returns an object with a
 * function that must be provuded the objectId and commentId to delete.
 *
 * @returns{{
 *   deleteCommentReply: ({ objectId: string, commentId: string }) => void
 * }}
 *
 * @example
 * ```js
 * const { deleteCommentReply } = useDeleteCommentReply()
 *
 * const onConfirmDeleteReply = () => {
 *   deleteCommentReply({ objectId, commentId })
 * }
 * ```
 */
export const useDeleteCommentReply = () => {
  const { mutate: deleteCommentReply } = useMutation(
    DELETE_COMMENT_REPLY,
    () => ({ ...ApolloManager.DEFAULT_QUERY_OPTIONS, fetchPolicy: 'no-cache' })
  )
  return {
    deleteCommentReply: ({
      objectId = null,
      commentId = null,
      replyTo = null
    }) => {
      if (!objectId || !commentId) {
        console.log('useDeleteCommentReply() parameter is missing or invalid', {
          objectId,
          commentId
        })
        return
      }
      deleteCommentReply({ commentId, objectId })
      // manually decrement the reply count in our state
      // replyTo is the commentId of the parent comment object
      decrementReplyCount(replyTo)
    }
  }
}
