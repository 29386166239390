import { inject, provide } from 'vue'

const MenuSymbol = Symbol('$MenuContext')

export const provideMenuContext = (menuApi = {}, options = {}) => {
  provide(MenuSymbol, menuApi)
}

export const useMenuContext = () => {
  const menuContext = inject(MenuSymbol, {})
  if (!menuContext) {
    console.warn('Failed to retrieve menu context useMenuContext()')
  }
  return menuContext
}
