import AnalyticsManager from '../AnalyticsManager'

const DEBUG_LOG = false

export const trackEvent = (event, properties = {}) => {
  const mixpanel = AnalyticsManager.mixpanelInstance
  logMixpanel(event, properties)
  return mixpanel.track(event, properties)
}

const logMixpanel = (event, properties) => {
  DEBUG_LOG && console.log(`[MIXPANEL]: trackEvent ${event}`, properties)
}
