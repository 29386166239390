export default class Histogram {
  binCount
  binWidth
  maximumValue
  minimumValue
  overCount
  underCount
  values = []

  // parse flots to two decimal places
  trimTrailingDecimal (binVal) {
    return binVal.toFixed(2)
  }

  setWithObject (data) {
    Object.keys(data).forEach(k => {
      if (k === 'values') {
        const values = data.values.map(v => ({ ...v, bin: this.trimTrailingDecimal(v.bin) }))
        this.values = values
      } else {
        this[k] = data[k]
      }
    })
  }

  constructor (data) {
    this.setWithObject(data)
  }
}
