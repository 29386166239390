import VueStore from 'vue-class-store'
import { provide, inject } from 'vue'
import { UIStore } from './states'

/**
 * Unique key for inject/providing UIStore
 * in app.
 */
const UIStoreSymbol = Symbol('$uistore')

/**
 * Creates a VueStore instance from UIStore class
 * @returns {UIStore} UIStore instance
 */
const createUIStore = () => {
  const store = VueStore.create(new UIStore())
  UIStoreManager.storeInstance = store
  //  add to store manager if necessary
  if (process.env.NODE_ENV === 'development') {
    window.uistore = store
  }

  return store
}

/**
 * Provides store instance in calling component. (Should be App root,
 * so children components can inject).
 * @returns {UIStore}
 */
const provideUIStore = () => {
  const store = createUIStore()
  provide(UIStoreSymbol, store)
  return store
}

/**
 * Injects the UIStore into calling vue component.
 * @returns {UIStore}
 */
const useUIStore = () => {
  const store = inject(UIStoreSymbol)
  return store
}

/**
 * Currently unused, but idea for simplifying access to nested
 * parts of the UIStore
 * @returns {MeshViewer}
 */
const useMeshViewerState = () => {
  const store = useUIStore()
  return store.meshViewer
}

class UIStoreManager {
  /**
   * Instance of ui store from vue class store.
   * Used so sub classes can interface with one another.
   * @type {UIStore}
   */
  static storeInstance
}

export {
  createUIStore,
  provideUIStore,
  useUIStore,
  useMeshViewerState,
  UIStoreManager,
  UIStoreSymbol
}

export * from './constants'

export default {
  /**
   * @param {import('vue').App} app
   */
  install: app => {
    const store = createUIStore()
    app.provide(UIStoreSymbol, store)
  }
}
