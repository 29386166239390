import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { routeIndex } from '@/router/routes'

/**
 * Set of computed value utilites for determing the
 * user's route location within the app.
 *
 * @returns {{
 *  currentRoute: *,
 *  isAtDashboard: ComputedRef<boolean>,
 *  isAtPartInspector: ComputedRef<boolean>,
 *  isAtLogin: ComputedRef<boolean>,
 *  isAtRegistration: ComputedRef<boolean>,
 *  isAtForgotPassword: ComputedRef<boolean>,
 *  isAtChangePassword: ComputedRef<boolean>,
 *  isAtOrgManagementSettings: ComputedRef<boolean>,
 *  isAtOrgManagementMembers: ComputedRef<boolean>
 * }} Computed boolean key values regarding the currentRoute within the app.
 *
 * @NOTE to use, must extract with .value, as the booleans are vue computed properties.
 *
 */
export const useAppRouterLocation = () => {
  const currentRoute = useRoute()
  const isAtDashboard = computed(() => currentRoute.name === routeIndex.dashboard.name)
  const isAtPartInspector = computed(
    () => currentRoute.name === routeIndex.partInspector.name
  )
  const isAtLogin = computed(() => currentRoute.name === routeIndex.login.name)
  const isAtRegistration = computed(
    () => currentRoute.name === routeIndex.registration.name
  )
  const isAtForgotPassword = computed(
    () => currentRoute.name === routeIndex.forgotPassword.name
  )
  const isAtChangePassword = computed(
    () => currentRoute.name === routeIndex.changePassword.name
  )
  const isAtOrgManagementSettings = computed(() => currentRoute.name === routeIndex.orgManagementSettings.name)
  const isAtOrgManagementMembers = computed(() => currentRoute.name === routeIndex.orgManagementMembers.name)

  const isAtUserAccountProfile = computed(() => currentRoute.name === routeIndex.userAccountProfile.name)
  const isAtUserAccountOrganizations = computed(() => currentRoute.name === routeIndex.userAccountOrganizations.name)

  return {
    currentRoute,
    // Route utils & values
    // auth
    isAtLogin,
    isAtRegistration,
    isAtForgotPassword,
    isAtChangePassword,
    // Authed Roots
    isAtPartInspector,
    isAtDashboard,
    // org management
    isAtOrgManagementSettings,
    isAtOrgManagementMembers,
    // user account
    isAtUserAccountProfile,
    isAtUserAccountOrganizations
  }
}
