<script>
/**
 * Header Component for modal.
 */
export default {
  name: 'BaseModalHeader',
  emits: {
    close: null
  },
  props: {
    showClose: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup () {}
}
</script>

<template>
  <div class="max-w-full pb-6 grid auto-rows-min gap-y-4">
    <div class="grid grid-flow-col gap-x-4">
      <base-span type="title" rank="primary">
        {{ label }}
      </base-span>
      <div @click="$emit('close')" class="my-auto ml-auto mr-0 cursor-pointer">
        <BaseIcon icon="x" v-if="showClose" />
      </div>
    </div>
    <BaseSeparator />
  </div>
</template>

<style scoped></style>
