/** @typdef {'mm'|'µm'|'in'} Unit */

/**
 * @param {Unit} fromUnit
 * @param {Unit} toUnit
 * @param {number} amount
 */
export const convertUnits = (fromUnit, toUnit, amount) => {
  switch (fromUnit) {
    case 'mm': {
      return mmTo(toUnit, amount)
    }
    case 'in': {
      return inTo(toUnit, amount)
    }
    case `${String.fromCharCode(956)}m`:
    case 'µm': {
      return micronTo(toUnit, amount)
    }
    default:
      return amount
  }
}

/**
 * @param {Unit} toUnit
 * @param {number} amount
 */
const mmTo = (toUnit, amount) => {
  switch (toUnit) {
    case 'mm': {
      return amount
    }
    case 'in': {
      return amount / 25.4
    }
    case `${String.fromCharCode(956)}m`:
    case 'µm': {
      return amount * 1000
    }
  }
}

/**
 * @param {Unit} toUnit
 * @param {number} amount
 */
const inTo = (toUnit, amount) => {
  switch (toUnit) {
    case 'mm': {
      return amount * 25.4
    }
    case 'in': {
      return amount
    }
    case `${String.fromCharCode(956)}m`:
    case 'µm': {
      return amount * 25400
    }
  }
}

/**
 * @param {Unit} toUnit
 * @param {number} amount
 */
const micronTo = (toUnit, amount) => {
  switch (toUnit) {
    case 'mm': {
      return amount / 1000
    }
    case 'in': {
      return amount / 25400
    }
    case `${String.fromCharCode(956)}m`:
    case 'µm': {
      return amount
    }
  }
}
