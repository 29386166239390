import { computed } from 'vue'
import { get } from '@vueuse/core'
import { useTailwindConfig } from './'

/**
 * Returns bindable style object for binding in vue template
 * for creating clipped text rainbows/linear gradients that
 * abide to internal brand colorings.
 *
 * Color at percent is computed value that returns the color
 * at the given percentage within the gradient.
 * RainbowStyle is the obejct that must be bound to the styled component
 * employing this composable.
 * clippedStyle allows for applying the linear gradient using the child text
 * as a mask.
 *
 *
 * @param {Object} args Reactive object
 * @returns {{
 *  colorAtPercent: string,
 *  rainbowStyle: object,
 * clippedStyle: object
 * }}
 */
export const useRainbow = args => {
  const { colors } = useTailwindConfig()
  const brandBlue = colors['brand-blue'].DEFAULT
  const brandRed = colors['brand-red'].DEFAULT
  const brandYellow = colors['brand-yellow'].DEFAULT
  const brandGreen = colors['brand-green'].DEFAULT

  const getColorForFillPercent = percent => {
    if (percent < 50) {
      return brandBlue
    } else if (percent < 100) {
      return brandYellow
    } else {
      return brandRed
    }
  }

  const colorAtPercent = computed(() => {
    const fillPercentage = get(args.percent)
    return getColorForFillPercent(fillPercentage)
  })

  const getRainbowAsLinearGradient = () => {
    return `linear-gradient(
      to right,
      ${brandRed},
      ${brandYellow},
      ${brandGreen},
      ${brandBlue}
    )`
  }

  const rainbowStyle = {
    'background-image': getRainbowAsLinearGradient()
  }

  const clippedStyle = {
    '-webkit-background-clip': 'text',
    'background-clip': 'text',
    'text-fill-color': 'transparent'
  }

  return {
    colorAtPercent,
    rainbowStyle,
    clippedStyle
  }
}
