import Amplify from '@aws-amplify/core'
import config from '../config'

Amplify.Logger.LOG_LEVEL = config.logs.level

export const initAmplify = () => {
  Amplify.configure(config.Amplify)
}

export default {
  install: () => {
    initAmplify()
  }
}
