/**
 * @param {string} string
 */
const makeProperNoun = string => {
  if (typeof string !== 'string') return ''
  const splitBySpaces = string.split(' ')
  return splitBySpaces.map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ')
}

export default makeProperNoun
