import { ref } from 'vue'

const defaults = { minRows: 1, maxRows: 5, lineHeight: 24 }

/**
 * WIP
 * NOT FUNCTIONING
 *
 * @link {https://github.com/Andarist/react-textarea-autosize/blob/master/src/calculateNodeHeight.ts}
 */
const useAutoResizeTextArea = ({ minRows, maxRows, lineHeight } = defaults) => {
  const textArea = ref(null)
  const rows = ref(1)

  const setTextAreaRows = r => {
    if (r < minRows) {
      rows.value = minRows
    } else if (r > maxRows) {
      rows.value = maxRows
    } else {
      rows.value = r
    }
  }

  const resizeTextArea = e => {
    let currRows = Math.floor((textArea.value.scrollHeight - (8 * 2)) / lineHeight)
    // read curr from actual text area here?
    if (currRows >= maxRows) {
      textArea.value.scrollTop = textArea.value.scrollHeight
    }

    if (!e.target.value) {
      currRows = 1
    }
  }

  return {
    textArea,
    resizeTextArea,
    textAreaRows: rows,
    setTextAreaRows,
    resetTextArea: setTextAreaRows(1)
  }
}

export default useAutoResizeTextArea
