import { StoreManager } from '@/store'
import { routeIndex } from '@/router'
import { preparePotentialDashboardRedirect } from './'

/**
 * Use aws amplify on vue-router redirects to ensure active
 * user is authed.
 *
 * This also accounts for redirects (* catch alls), by loading
 * the parts dashboard for the defaultProject (currently just projects[0])
 *
 * This either returns a boolean (which then just proceeds with route push),
 * or a new RouteLocation, which acts as the objects fed to router.push() for
 * dynamic rerouting in the event of auth failures or default redirects.
 *
 * @param {Object} to Vue router object representing destination
 * @param {Object} from Vue router object representing origin
 *
 * @returns {?RouteLocation}
 *
 * Note: Returning a RouteLocation will rerun the processRoute() with the returned value
 * as the 'to' arg.
 * To avoid infinite loops, either return true or nothing at all from this which will allow the router
 * to resolve
 *
 * @link {https://next.router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards}
 *
 * @see routes.js for to and from configuration
 */
export const processRoute = async to => {
  const routeRequestRequiresAuth = to.meta.requiresAuth
  if (routeRequestRequiresAuth) {
    const validAuthState = await authStateIsValid()
    if (!validAuthState) {
      return authErrorFallbackRoute()
    }
    const isDashboard = to.name === routeIndex.dashboard.name
    if (isDashboard) {
      preparePotentialDashboardRedirect(to)
    }
  }
}

const authStateIsValid = async () => {
  const store = StoreManager.storeInstance
  const currentUser = store?.currentUser
  const auth = store.auth
  const userStoreIsAuthed = currentUser?.isValid() || false
  if (userStoreIsAuthed) {
    return true
  }
  try {
    await auth.verifyAuthState()
    return true
  } catch (err) {
    onAuthError()
    return false
  }
}

const onAuthError = () => {
  const store = StoreManager.storeInstance
  store.clear()
}

const authErrorFallbackRoute = () => routeIndex.login
