// useDeleteComment.js
import { useMutation } from '@vue/apollo-composable'
import { ApolloManager } from '../'
import { deleteComment as deleteCommentMutation } from '@/graphql/mutations'
import gql from 'graphql-tag'

/**
 * Composable for deleteing a comment. Returns a function
 * that must be provded with the comment and object ids.
 *
 * @returns {{
 *  deleteComment: ({objectId: string, commentId: string}) => void
 * }} Object with a function to call for deleting a comment.
 *
 * @example
 * ```js
 * const { deleteComment } = useDeleteComment()
 *
 * const onConfirmDelete = () => {
 *   deleteComment({ objectId, commentId })
 * }
 * ```
 */
export const useDeleteComment = () => {
  const { mutate: deleteComment } = useMutation(
    gql`
      ${deleteCommentMutation}
    `,
    () => ({ ...ApolloManager.DEFAULT_QUERY_OPTIONS, fetchPolicy: 'no-cache' })
  )
  return {
    deleteComment: ({ objectId = null, commentId = null }) => {
      if (!objectId || !commentId) {
        console.log('useDeleteComment() parameter is missing or invalid', {
          objectId,
          commentId
        })
        return
      }
      deleteComment({ commentId, objectId })
    }
  }
}
