<script>
import { computed } from 'vue'
import * as CommonProps from './common/props'

/**
 * Span Element to centralize usage of primary and secondary text elements.
 */
export default {
  name: 'BaseSpan',
  props: {
    /**
     * Governs color
     */
    rank: {
      type: String,
      required: false,
      validator: function (value) {
        if (!value) return true
        return ['primary', 'secondary'].includes(value)
      }
    },
    /**
     * Governs font size.
     */
    type: {
      type: String,
      default: 'body',
      required: false,
      validator: function (value) {
        return ['body', 'subcaption', 'caption', 'subheader', 'title'].includes(
          value
        )
      }
    },
    /**
     *
     */
    bold: CommonProps.BOOLEAN,
    /**
     *
     */
    lines: CommonProps.NUMBER,
    /**
     *
     */
    textLeft: CommonProps.BOOLEAN,
    /**
     *
     */
    textCenter: CommonProps.BOOLEAN,
    /**
     *
     */
    textRight: CommonProps.BOOLEAN,
    /**
     * [danger, success]
     */
    intent: CommonProps.STRING,
    /**
     *
     */
    italic: CommonProps.BOOLEAN
  },
  setup (props) {
    const fontSize = () => {
      const type = props.type
      if (type === 'subcaption') {
        return 'text-xs'
      }
      if (type === 'caption') {
        return 'text-sm'
      }
      if (type === 'title') {
        return 'text-lg'
      }
      return 'text-base'
    }

    const fontColor = () => {
      const rank = props.rank
      if (rank === 'secondary') {
        return 'text-brand-gray-darker'
      } else if (rank === 'primary') {
        return 'text-black'
      }

      const intent = props.intent
      if (intent === 'danger') {
        return 'text-brand-red'
      } else if (intent === 'accent') {
        return 'text-brand-blue'
      }
    }

    const fontWeight = () => {
      const bold = props.bold
      if (bold) {
        return 'font-bold'
      }
      return 'font-normal'
    }

    const lineCount = () => {
      const lines = props.lines
      if (!lines || lines === 0) {
        return ''
      }
      return 'clamp'
    }

    const resolveTextPosition = () => ({
      'text-left': props.textLeft,
      'text-center': props.textCenter,
      'text-right': props.textRight
    })

    const spanClass = computed(() => ({
      ...resolveTextPosition(),
      italic: props.italic,
      [fontSize()]: true,
      [fontColor()]: true,
      [fontWeight()]: true,
      [lineCount()]: true
    }))

    return { spanClass }
  }
}
</script>

<template>
  <span :class="spanClass">
    <slot />
  </span>
</template>

<style scoped>
/**
  We use break all because text with special chars
  will not register with word-break: normal.
  This is common with project/part names that
  use underscores and dashes.
*/
.clamp {
  @apply overflow-hidden text-ellipsis break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: v-bind(lines);
}
</style>
