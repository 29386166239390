<script>
import * as CommonProps from '@/components/_base/common/props'
import Popper from 'vue3-popper'
/**
 * Tooltop
 */
export default {
  name: 'BaseTooltip',
  components: {
    Popper
  },
  props: {
    backgroundColor: {
      ...CommonProps.STRING,
      default: '#333333'
    }
  },
  setup () {}
}
</script>

<template>
  <Popper hover class="items-center base__popover">
    <template #default>
      <slot />
    </template>
    <template #content="slotProps">
      <slot name="content" v-bind="slotProps" />
    </template>
  </Popper>
</template>

<style scoped>
.base__popover {
  --popper-theme-background-color: v-bind(backgroundColor);
  --popper-theme-background-color-hover: v-bind(backgroundColor);
  --popper-theme-text-color: white;
  --popper-theme-border-width: 0;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 3px;
  --popper-theme-padding: 0.5rem;
  --popper-theme-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* this is a hack because we need the popover container to be malleable */
/* .base__popover >>> div:first-child { */
/*   display: flex; */
/*   flex: 1; */
/* } */

.base__popover :deep(div:first-child) {
  display: flex;
  flex: 1;
}
</style>
