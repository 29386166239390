import { ref, computed, nextTick } from 'vue'
import { createEventHook, set, get } from '@vueuse/core'
import config from '@/config'

/**
 * To resolve a potential Windows10 bug that improperly infers
 * the file type, use the extension parsed from the file name
 * to determine the file type of the upload.
 *
 * @link {https://github.com/MODit3D/user-web-app/issues/203#issuecomment-933715258}
 */
const PREFER_EXTENSION_FILE_TYPE = true

const MB = 1000 * 1000
const maxUploadSizeMB = config.Rover.app.cadMaxUploadMB

const meshUploadExtensions = ['ply', 'stl', 'PLY', 'STL']
const refCADExtensions = [...meshUploadExtensions, 'stp', 'STP', 'step', 'STEP']
const acceptedFileExtensions = [...meshUploadExtensions, ...refCADExtensions]
const acceptedFileExtensionsStr = acceptedFileExtensions
  .map(f => `.${f}`)
  .join(',')

const acceptedScanMeshExtensionsStr = meshUploadExtensions
  .map(f => `.${f}`)
  .join(',')

const readFile = htmlEvent => {
  let file
  // check existence of file in
  // on of two nested key values
  // as drag and drop event is different than
  // manual file selection resulting in different
  // event objects as the arg.
  if (htmlEvent?.dataTransfer?.files?.[0]) {
    file = htmlEvent.dataTransfer.files[0]
  } else if (htmlEvent?.target?.files?.[0]) {
    file = htmlEvent.target.files[0]
  }
  return file
}

const parseFileType = file => {
  let fileType = file.type
  if (!fileType || PREFER_EXTENSION_FILE_TYPE) {
    // uploading a stl, ply, stp does not
    // show up as the File object's type key
    // so manually set it here.
    // assume last in parse is the filetype.
    const split = file.name.split('.')
    fileType = split[split.length - 1]
  }
  return fileType
}

const fileTypeIsValid = fileType => {
  return isRefCADFileType(fileType) || isScanMeshFileType(fileType)
}

const isRefCADFileType = fileType => {
  return containsFileType(fileType, refCADExtensions)
}

const isScanMeshFileType = fileType => {
  return containsFileType(fileType, meshUploadExtensions)
}

const containsFileType = (fileType, fileTypeList) => {
  const lowerFileType = fileType.toLowerCase().replace('.', '')
  for (const type of fileTypeList) {
    const lowerAcceptedType = type.toLowerCase().replace('.', '')
    if (lowerAcceptedType === lowerFileType) {
      return true
    }
  }
  return false
}

const fileSizeExceedsMax = fileSize => fileSize > maxUploadSizeMB * MB

export const useCADMeshUpload = () => {
  const fileExceedsMax = ref(false)
  const fileIsCAD = ref(false)
  const fileRef = ref(null)
  const fileTypeRef = ref(null)
  const fileInputRef = ref(null)

  const refCADResult = createEventHook()
  const scanMeshResult = createEventHook()
  const errorResult = createEventHook()

  const setDefaults = () => {
    set(fileExceedsMax, false)
    set(fileIsCAD, false)
    set(fileRef, null)
    set(fileTypeRef, null)
    return nextTick()
  }

  const setRefsFromFile = (file = {}) => {
    set(fileRef, file)
    const fileType = parseFileType(file)
    set(fileTypeRef, fileType)

    if (isRefCADFileType(fileType)) {
      set(fileIsCAD, true)
    }

    if (fileSizeExceedsMax(file.size)) {
      set(fileExceedsMax, true)
    }
  }

  const checkAndFireErrorHooks = () => {
    const file = get(fileRef)
    const fileType = get(fileTypeRef)
    let didTriggerError = false

    console.log('File Info', {
      file,
      fileType
    })

    if (!file) {
      errorResult.trigger('Unable to parse file')
      didTriggerError = true
    }

    if (!fileTypeIsValid(fileType)) {
      errorResult.trigger(`Invalid upload file type: ${fileType}`)
      didTriggerError = true
    }

    if (fileSizeExceedsMax(file.size)) {
      errorResult.trigger(`File is too large: ${file.size}`)
      didTriggerError = true
    }

    if (didTriggerError) {
      clearFileInput()
    }

    return didTriggerError
  }

  const checkAndFireSuccessHooks = () => {
    const file = get(fileRef)
    const fileType = get(fileTypeRef)
    if (isRefCADFileType(fileType)) {
      refCADResult.trigger(file)
    }
    if (isScanMeshFileType(fileType)) {
      scanMeshResult.trigger(file)
    }
  }

  const validateAndSetFile = async e => {
    await setDefaults()

    const file = readFile(e)
    setRefsFromFile(file)

    if (!checkAndFireErrorHooks()) {
      checkAndFireSuccessHooks()
    }
  }

  const fileName = computed(() => {
    const file = get(fileRef)
    return file?.fileName
  })

  const clearFileInput = () => {
    if (fileInputRef.value) {
      fileInputRef.value.value = null
    }
  }

  return {
    validateAndSetFile,
    onFileIsRefCAD: refCADResult.on,
    onFileIsScanMesh: scanMeshResult.on,
    onError: errorResult.on,
    acceptedFileExtensionsStr,
    acceptedScanMeshExtensionsStr,
    clearFileInput,
    file: fileRef,
    fileName,
    fileIsCAD,
    fileExceedsMax,
    fileTypeRef,
    fileInputRef
  }
}
