<script>
import { computed, inject } from 'vue'
import * as CommonProps from '@/components/_base/common/props'

export default {
  name: 'MenuItemChildMenuResponder',
  props: {
    /**
     *
     */
    level: CommonProps.NUMBER,
    /**
     *
     */
    index: CommonProps.NUMBER
  },
  setup (props) {
    const menuApi = inject('$child-menu', null)

    const isHoveredEl = computed(() => {
      return menuApi?.isInChain([props.level, props.index])
    })

    const processHoverRequest = () => {
      menuApi?.processHoverRequest([props.level, props.index])
    }

    return {
      processHoverRequest,
      showChildMenu: isHoveredEl
    }
  }
}
</script>
<template>
  <slot
    :show-child-menu="showChildMenu"
    :process-hover-request="processHoverRequest"
  />
</template>

<style scoped></style>
