import md5 from 'md5'
import AwsAuth from '@aws-amplify/auth'
import { makeProperNoun } from '@/utils'
import { UserPermissions } from './'

/**
 * @typedef {{
 * 'custom:ent_admin': string,
 * 'custom:ent_debug_reg': string,
 * 'custom:ent_developer_mode': string,
 * 'custom:ent_measurement': string,
 * 'custom:ent_model_compare': string,
 * email: string,
 * email_verified: boolean,
 * family_name: string,
 * given_name: string,
 * }} UserCognitoAttributes Attributes attached to user through
 * Amazon's Cognito Identity. Custom keys prefixed "custom:"
 */

export default class User {
  constructor (json = {}) {
    this.permissions = new UserPermissions()
    this.roles = []
    this.setWithJSON(json)
  }

  setWithJSON (json = {}) {
    if (json.id) {
      this.id = json.id
    }
    this.createdAt = json.createdAt
    this.email = json.email
    this.emailHash = json.emailHash
    this.family_name = json.family_name
    this.given_name = json.given_name
    this.inviteStatus = json.inviteStatus
    this.invitedBy = json.invitedBy
    this.isOwner = json.isOwner
    this.orgId = json.orgId
    /**
     * @type {UserCognitoAttributes}
     */
    this.attributes = json.attributes
    this.roles = json.roles
    this.updatedAt = json.updatedAt
    this.userDefault = json.userDefault
  }

  isValid () {
    return !!this.id
  }

  /**
   * @returns {UserCognitoAttributes}
   */
  getAttributes () {
    return this.attributes || {}
  }

  isAdmin () {
    const attr = this.getAttributes()
    return attr['custom:ent_admin'] === '1'
  }

  getEmail () {
    return this.email || this.getAttributes().email
  }

  getGivenName () {
    return this.given_name || this.getAttributes().given_name
  }

  getFamilyName () {
    return this.family_name || this.getAttributes().family_name
  }

  getFullName () {
    let fullName = ''
    const givenName = this.getGivenName()
    const familyName = this.getFamilyName()
    if (givenName) {
      fullName += makeProperNoun(givenName) + ' '
    }
    if (familyName) {
      fullName += makeProperNoun(familyName)
    }
    return fullName
  }

  /**
   * @returns {UserPermissions}
   */
  getPermissions () {
    return this.permissions
  }

  /**
   * @returns {string} Get user avatar url
   */
  userAvatar () {
    // eventually expand to include user uploaded avatar
    // for now default to user's gravatar
    if (this.getEmail()) {
      return this.userGravatar()
    }
    return ''
  }

  /**
   * Gets user's gravatar photo from authed email
   * @returns {string} Gravatar url
   */
  userGravatar () {
    const emailHash = md5(this.getEmail().toLowerCase())
    return `https://www.gravatar.com/avatar/${emailHash}?d=mp`
  }

  fetchAndStoreDefaults () {
    return this.getPermissions().fetchAndStore()
  }

  applyOrgRolePermissions (orgList) {
    const permissionIndex = orgList.reduce((accumulatedIndex, currentOrg) => {
      const orgRoles = currentOrg.getFormattedRoleIndexForUserPermissions()
      return {
        ...accumulatedIndex,
        ...orgRoles
      }
    }, {})
    const userPermissions = this.permissions
    userPermissions.setOrgRolePermissions(permissionIndex)
  }

  async updateName ({ familyName, givenName }) {
    const user = await AwsAuth.currentAuthenticatedUser()
    await AwsAuth.updateUserAttributes(user, {
      family_name: familyName || this.getFamilyName(),
      given_name: givenName || this.getGivenName()
    })
    this.getAttributes().family_name = familyName
    this.getAttributes().given_name = givenName
  }
}
