class PartInspectorLeftSidebar {
  constructor () {
    this.showLoadingModels = false
  }

  getShowLoadingModels () {
    return this.showLoadingModels || false
  }

  setShowLoadingModels (show) {
    this.showLoadingModels = show
  }
}

/**
 * This will eventually wrap MeshViewer.js
 * But for now it is mainly to contain state for
 * the PartInspector's other components (comments).
 */
export class PartInspector {
  constructor () {
    this.selectedComment = null
    this.commentIndex = -1
    this.leftSidebar = new PartInspectorLeftSidebar()
  }

  getSelectedComment () {
    return this.selectedComment || {}
  }

  getSelectedCommentIndex () {
    return this.commentIndex
  }

  getSelectedCommentInfo () {
    return {
      selectedComment: this.getSelectedComment(),
      commentIndex: this.getSelectedCommentIndex()
    }
  }

  setSelectedComment (comment, commentIndex) {
    this.selectedComment = comment
    this.commentIndex = commentIndex
  }

  clearSelectedComment () {
    this.setSelectedComment(null, -1)
  }
}
