import { useMutation } from '@vue/apollo-composable'
import { ApolloManager } from '../'
import { createComment as addCommentMutation } from '@/graphql/mutations'
import { commentTypeIsValid } from '../utils'

import gql from 'graphql-tag'

const ADD_COMMENT = gql`${addCommentMutation}`
/**
 * Add a comment to the specified commenttype at objectId.
 *
 * @returns {(objectId: String, type: import('../constants').COMMENT_TYPE, body: String, annotationAnchor: Object) => void} The function
 * to be called for the mutation
 * provided with the objectId, comment type, and text body.
 *
 * @example
 * ```js
 * const { addComment } = useAddComment()
 *
 * const onEnterKeyPress  = () => {
 *   addComment({ objectId, type, body, annotationAnchor })
 * }
 * ```
 */
export const useAddComment = () => {
  const { mutate } = useMutation(
    ADD_COMMENT,
    () => ({
      ...ApolloManager.getDefaultMutationOptions(),
      awaitRefetchQueries: true
    })
  )

  const addComment = (vars = {}) => {
    const {
      objectId = null,
      type = null,
      body = '',
      annotationAnchor = {}
    } = vars
    // should we reject on no annotationAnchor?
    if (!objectId || !type || !body || !commentTypeIsValid(type)) {
      console.log(
        'useListCommentsForObject() parameter is missing or invalid',
        {
          objectId,
          type,
          body
        }
      )
      return
    }
    const annotationAnchorJSONString = JSON.stringify(annotationAnchor)
    mutate({
      type,
      body,
      objectId,
      annotationAnchor: annotationAnchorJSONString
    })
  }

  return {
    addComment
  }
}
