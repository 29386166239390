import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import Auth from '@aws-amplify/auth'
import { from, ApolloClient, InMemoryCache } from '@apollo/client'
import { StoreManager } from '@/store'
import { resolveHeaders } from '@/api'

export class ApolloManager {
  static instance

  static APP_SYNC_CONFIG = {
    url: process.env.VUE_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
    region: process.env.VUE_APP_AWS_REGION,
    auth: {
      type: 'AMAZON_COGNITO_USER_POOLS',
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken()
    },
    disableOffline: true
  }

  /**
   * @link {https://www.apollographql.com/docs/react/api/core/ApolloClient/}
   */
  static APP_SYNC_OPTIONS = {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network'
      }
    }
  }

  /**
   * @static
   * OrgId is silently nulled if absent,
   * composables employing these default query options
   * should be aware of this and check for null before subscribing
   * or fetching data from graphql.
   */
  static get DEFAULT_QUERY_OPTIONS () {
    return {
      ...ApolloManager.getContextHeaders(),
      fetchPolicy: 'cache-and-network'
    }
  }

  /**
   * Dont provide fetchPolicy on mutations
   * @link {https://github.com/apollographql/apollo-client/issues/4577}
   */
  static getDefaultMutationOptions () {
    return { ...ApolloManager.getContextHeaders() }
  }

  static getContextHeaders () {
    const store = StoreManager.storeInstance
    const orgId = store.activeOrgId || ''
    return {
      context: { headers: resolveHeaders(orgId) }
    }
  }

  /** @private */
  getLinks () {
    const authLink = createAuthLink(ApolloManager.APP_SYNC_CONFIG)
    const subscriptionLink = createSubscriptionHandshakeLink(
      ApolloManager.APP_SYNC_CONFIG
    )
    return from([authLink, subscriptionLink])
  }

  initClient () {
    const client = new ApolloClient({
      link: this.getLinks(),
      cache: new InMemoryCache({
        typePolicies: {
          // define custom getters here
          // https://www.apollographql.com/docs/react/caching/cache-field-behavior/
          CommentConnection: {}
        }
      }),
      connectToDevTools: true,
      ...ApolloManager.APP_SYNC_OPTIONS
    })
    ApolloManager.instance = client
    return client
  }
}
