import API from '@aws-amplify/api'
import { resolveHeaders } from './utils'

/**
 * @async
 * @param {Object} params         Object parameters for API call
 * @param {string} params.method  API method
 * @param {string} params.apiName API name for call, the base path
 * @param {string} params.path    API's path to be appended to apiName
 * @param {Object} params.payload Object to be sent as request body to API
 * @param {string} params.orgId   Current user's active organization id
 */
export const callLambdaApi = async params => {
  const { method, apiName, path, payload = {}, orgId = null } = params
  const appendedPayload = {
    ...payload,
    headers: resolveHeaders(orgId)
  }
  return API[method](apiName, path, appendedPayload)
}
