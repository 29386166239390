<script>
import { watch, toRef } from 'vue'
import { set, useToggle } from '@vueuse/core'
import * as CommonProps from '@/components/_base/common/props'
/**
 * BaseAccordion component used for expanding
 * the visibilty of content in a list.
 */
export default {
  name: 'BaseAccordion',
  emits: {
    /**
     *
     */
    open: null,
    /**
     *
     */
    close: null
  },
  props: {
    /**
     * Allows for controlled usage of accordion.
     */
    expanded: CommonProps.BOOLEAN,
    /**
     * The actionable label of the accordion.
     */
    label: CommonProps.STRING
  },
  setup (props, { emit }) {
    const [controlledExpanded, toggleExpanded] = useToggle(props.expanded)

    watch(toRef(props, 'expanded'), _expanded => {
      set(controlledExpanded, _expanded)
    })

    watch(controlledExpanded, _controlledExpanded => {
      const event = _controlledExpanded ? 'open' : 'close'
      emit(event)
    })

    return {
      controlledExpanded,
      toggleExpanded
    }
  }
}
</script>

<template>
  <div class="flex flex-col space-y-2">
    <div
      @click="toggleExpanded"
      class="flex flex-row items-center space-x-4 cursor-pointer pl-4"
      data-test="accordion-cta"
    >
      <base-span v-if="label" type="subheader" rank="primary">
        {{ label }}
      </base-span>
      <slot name="header-content" />
      <BaseIcon
        :icon="controlledExpanded ? 'chevron-right' : 'chevron-down'"
        :size="12"
      />
    </div>
    <BaseSeparator />
    <div v-show="controlledExpanded" data-test="content">
      <slot name="content" />
    </div>
  </div>
</template>

<style scoped></style>
