import { createWebHashHistory, createRouter } from 'vue-router'
import { processRoute, handleChunkLoadError } from './utils'
import { routes } from './routes'

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(processRoute)
router.onError(handleChunkLoadError)

export { router }
export * from './utils'
export * from './routes'
export * from './composables'
