<script>
/**
 * Small Div for visual separation.
 */
export default {
  name: 'BaseSeparator'
}
</script>

<template>
  <div class="shrink-0 h-[1px] bg-brand-gray-light" />
</template>

<style scoped></style>
