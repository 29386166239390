<script>
import { ref, computed } from 'vue'

import NewBaseListItem from '../list/BaseListItem.vue'
import BaseListItemSection from '../list/BaseListItemSection.vue'

import { useHoveredEls } from '@/composables'
import * as CommonProps from '@/components/_base/common/props'
import BaseLoadingSpinner from '../BaseLoadingSpinner.vue'

/**
 *
 */
export default {
  name: 'MenuItem',
  components: {
    NewBaseListItem,
    BaseListItemSection,
    BaseLoadingSpinner
  },
  emits: {
    'delayed-mouse-enter': null,
    'delayed-mouse-leave': null
  },
  props: {
    /**
     *
     */
    label: CommonProps.STRING,
    /**
     *
     */
    labelIntent: CommonProps.STRING,
    /**
     *
     */
    labelItalic: CommonProps.BOOLEAN,
    /**
     *
     */
    leadingIcon: CommonProps.ICON,
    /**
     *
     */
    isSelected: CommonProps.BOOLEAN,
    /**
     *
     */
    inset: CommonProps.BOOLEAN,
    /**
     * TODO:
     * - [ ] Convert into object to encapsulate
     *       <base-span> props
     *      secondaryLabel: { label, rank, intent }
     */
    secondaryLabel: CommonProps.STRING,
    /**
     *
     */
    secondaryLabelIntent: CommonProps.STRING,
    /**
     *
     */
    secondaryLabelItalic: CommonProps.BOOLEAN,
    /**
     *
     */
    trailingIcon: CommonProps.ICON,
    /**
     *
     */
    size: CommonProps.SIZE_STRING,
    /**
     *
     */
    disabled: CommonProps.BOOLEAN,
    /**
     *
     */
    loading: CommonProps.BOOLEAN,
    /**
     *
     */
    index: CommonProps.NUMBER
  },
  setup (props, { emit }) {
    const liRef = ref(null)
    const liRefEl = computed(() => liRef?.value?.$el)

    const { onDelayedMouseEnter, onDelayedMouseLeave } = useHoveredEls(
      [liRefEl],
      {
        leaveDelayMS: 50,
        enterDelayMS: 250,
        listenOnMount: true
      }
    )

    onDelayedMouseEnter(() => emit('delayed-mouse-enter'))
    onDelayedMouseLeave(() => emit('delayed-mouse-leave'))

    return {
      liRef,
      liRefEl
    }
  }
}
</script>

<template>
  <new-base-list-item
    ref="liRef"
    :selected="isSelected"
    :disabled="disabled"
    :loading="loading"
    vertically-centered
    selectable
    dense
  >
    <base-list-item-section v-if="leadingIcon || inset || loading">
      <BaseLoadingSpinner v-if="loading" :size="12" />
      <BaseIcon v-else-if="leadingIcon" :icon="leadingIcon" :size="12" />
      <div class="w-3" v-else-if="inset" />
    </base-list-item-section>

    <base-list-item-section main>
      <base-span
        :lines="1"
        :intent="labelIntent"
        :italic="labelItalic"
        text-left
        type="caption"
      >
        {{ label }}
      </base-span>
    </base-list-item-section>

    <base-list-item-section v-if="secondaryLabel">
      <base-span
        text-right
        :lines="1"
        :intent="secondaryLabelIntent"
        :italic="secondaryLabelItalic"
        type="caption"
      >
        {{ secondaryLabel }}
      </base-span>
    </base-list-item-section>

    <base-list-item-section v-if="trailingIcon">
      <BaseIcon :icon="trailingIcon" :size="12" />
    </base-list-item-section>

    <slot name="child-menu" :root-ref="liRefEl" />
  </new-base-list-item>
</template>

<style></style>
