import { computed, watch, ref, onMounted, onBeforeUnmount } from 'vue'
import { set, get, until, invoke, useCounter, useToggle } from '@vueuse/core'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'

import { StoreManager } from '@/store'
import {
  onCreateComment,
  onUpdateComment,
  onDeleteComment
} from '@/graphql/subscriptions'
import { listComments } from '@/graphql/queries'

import { ApolloManager } from '../'
import { updateReplyCount } from './useReplyCountForComment'
import { updateQuery } from '../utils'

// For now, until pagination is stable and supported
const COMMENT_COUNT_GET = 1000

const LIST_COMMENTS = gql`
  ${listComments}
`
const ON_CREATE_COMMENT = gql`
  ${onCreateComment}
`
const ON_UPDATE_COMMENT = gql`
  ${onUpdateComment}
`
const ON_DELETE_COMMENT = gql`
  ${onDeleteComment}
`

/**
 * List comments for a specified comment type and related id.
 * Handles subscribing and unsubscribing according to component/view lifecycle
 *
 * @param {object} config               Configuration for the graphql query.
 * @param {Ref<string>} config.objectId ObjectId for the graphql query, fed as a ref.
 * @param {string} config.type          CommentType.
 *
 * @returns {{
 *  loading: boolean,
 *  isFetchingComments: boolean,
 *  comments: array
 * }} query result object with comments as a property. In the event of a null
 *    or invalid arg (objectId, type) empty object is returned.
 *
 * `loading` in return object refers to the state of either the query or subscription.
 * Any mutations that trigger relevant subscriptions will set `loading` to true.
 * `isFetchingComments` alternatively, only is true on the initial query.
 * Useful for initial loading states.
 *
 */
export const useListCommentsForObject = ({ objectId = null, type = null }) => {
  // loading returned from useQuery() is a ref
  // that generally referes to the state of the graphql query
  // is is true during the initial query
  // but it is also true for any subsequent subscription modifications,
  // so if a user adds a comment or edits a comment, it'll trigger the loading
  // state, which is unpleasant.
  // To solve this, just falsify a loading state when the composable is called,
  // and trip it to false once the initial query changes its loading state.
  const isFetchingComments = ref(false)
  const [enabled, toggleEnabled] = useToggle()
  const { restart, loading, result, subscribeToMore } = useQuery(
    LIST_COMMENTS,
    () => ({
      objectId: get(objectId),
      type: get(type),
      count: COMMENT_COUNT_GET,
      sortOrder: 'desc' // currently not supported
    }),
    () => ({
      ...ApolloManager.DEFAULT_QUERY_OPTIONS,
      enabled,
      errorPolicy: 'all',
      notifyOnNetworkStatusChange: true
    })
  )

  onMounted(() => {
    if (get(objectId) && get(type)) {
      set(enabled, true)
      set(isFetchingComments, true)
    }
  })

  // subscribeToMore expects a reactive
  // object but we can also use a lambda function
  // to return an object to mock reactivity
  // These are detached/unsubscribed when enabled flips to false
  // options
  //
  // FIXME: There is currently a bug with vue apollo composable
  //        where if it attempts to create another subscription before the previous
  //        has unsubcribed, it throws error and doesn't process new subscriptions.
  //        As a temporary workaround, just restart the query/subscription on the error hook
  //        and check against an counter to avoid potentially infinite looping in case it's
  //        an unrelated throw.
  //
  // LINKS:
  // https://github.com/apollographql/apollo-client/blob/dfd159e0070918ae885d5877e5fba18396350d15/src/core/watchQueryOptions.ts#L129
  // https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/491
  // https://github.com/apollographql/apollo-feature-requests/issues/252
  // https://v4.apollo.vuejs.org/guide-composable/subscription.html#subscribetomore
  // https://v4.apollo.vuejs.org/api/use-query.html#return
  //
  // [2021.05.24] Alex
  const orgId = computed(() => {
    const store = StoreManager.storeInstance
    const orgId = store.activeOrgId
    return orgId || null
  })

  const { count: resetCounter, inc } = useCounter()
  const nextToken = ref(null)
  const MAX_RETRY_COUNT = 3

  invoke(async () => {
    await until(orgId).toBeTruthy()
    subscribeToMore(() => ({
      document: ON_UPDATE_COMMENT,
      variables: { objectId: get(objectId) },
      updateQuery,
      onError: () => {
        if (get(resetCounter) < MAX_RETRY_COUNT) {
          inc()
          setTimeout(restart, 500)
        }
      },
      ...ApolloManager.DEFAULT_QUERY_OPTIONS
    }))

    subscribeToMore(() => ({
      document: ON_DELETE_COMMENT,
      variables: {
        objectId: get(objectId),
        type: get(type)
      },
      updateQuery,
      onError: () => {},
      ...ApolloManager.DEFAULT_QUERY_OPTIONS
    }))

    subscribeToMore(() => ({
      document: ON_CREATE_COMMENT,
      variables: { objectId: get(objectId), type: get(type) },
      updateQuery,
      onError: () => {},
      ...ApolloManager.DEFAULT_QUERY_OPTIONS
    }))
  })

  onBeforeUnmount(() => {
    console.log(
      '[APOLLO]: Unsubscribed from comments for',
      get(type)
    )
  })

  watch(objectId, newObjId => {
    if (newObjId && get(type)) {
      resetCounter.value = 0
      set(isFetchingComments, true)
    }
    if (!get(enabled)) {
      toggleEnabled()
    }
  })

  watch(result, newResults => {
    set(nextToken, newResults?.listComments?.nextToken)
  })

  const comments = computed(() => result.value?.listComments.items ?? [])

  watch(loading, (prevLoad, newLoad) => {
    if (!prevLoad && newLoad && isFetchingComments.value) {
      set(isFetchingComments, false)
      // Set the initial reply count here
      // from the initial query result
      if (comments.value) {
        comments.value.forEach(c => {
          updateReplyCount(c.commentId, c.replyCount)
        })
      }
    }
  })

  return {
    loading,
    isFetchingComments,
    comments
  }
}
