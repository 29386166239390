<script>
import { ref, nextTick, computed, onMounted, watch } from 'vue'
import { get, set } from '@vueuse/core'

const getValueWithinPerecentageLengthLimitations = value => {
  const PERCENTAGE_INPUT_MAX_LENGTH = 5 // four digits, one decimal
  const percentageValueExceedsMaxLength =
    value.length > PERCENTAGE_INPUT_MAX_LENGTH
  if (percentageValueExceedsMaxLength) {
    return value.slice(0, PERCENTAGE_INPUT_MAX_LENGTH)
  }
  return value
}

/**
 * Base Input component. Accepts and updates on v-model
 * see https://v3.vuejs.org/guide/migration/v-model.html#using-v-bind-sync.
 * Or can use :modelValue props to manually infer and update input's value.
 */
export default {
  name: 'BaseInput',
  inheritAttrs: false,
  emits: {
    /**
     * Update the input's value with this event.
     * If v-model is not used (such as when the modelValue is a prop on the component instance),
     * component instance must have @update:modelValue="event" directive.
     */
    'update:modelValue': null
  },
  props: {
    /**
     * The input's value. Assumed with v-model usage.
     */
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * @link {https://v3.vuejs.org/guide/component-custom-events.html#handling-v-model-modifiers}
     */
    modelModifiers: {
      default: () => ({})
    },
    /**
     * Mimic native html as this is not consistent in Vue SFCs
     */
    autofocus: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Label for the input.
     */
    label: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Placeholder
     */
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Specify the icon name, to create a leading icon
     * within the input. This will dynamically add padding to the input
     * on the left. Either use this or leading-content slot.
     */
    leadingIcon: {
      type: String,
      required: false,
      default: ''
    },
    /**
     *
     */
    showError: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Error text to show below input.
     * Is hidden if showError is false.
     */
    errorLabel: {
      type: String,
      required: false,
      default: undefined
    }
  },
  setup (props, { slots, emit }) {
    const input = ref(null)
    const isFocused = ref(false)
    const trailingContentRef = ref(null)
    const leadingContentRef = ref(null)

    const defaultRightPadding = 1
    const defaulLeftPadding = 1
    const paddingRightRem = ref(defaultRightPadding)
    const paddingLeftRem = ref(defaulLeftPadding)

    const blurredInputHasError = computed(() => {
      return props.showError && !get(isFocused)
    })

    const emitValidatedModelUpdate = event => {
      const value = applyModifiers(event.target.value)
      emit('update:modelValue', value)
    }

    const applyModifiers = value => {
      const modelModifiers = props.modelModifiers || {}
      if (modelModifiers.percentage) {
        value = getValueWithinPerecentageLengthLimitations(value)
      }
      return value
    }

    const hasTrailingContent = slots['trailing-content']
    const hasLeadingContent = slots['leading-content']

    const inputId = props.placeholder
      ? `input-${props.placeholder}`
      : `input-${Math.floor(Math.random() * Math.floor(50))}`

    const calculatePaddingFromRef = el => {
      if (!el) {
        return 0
      }
      const rect = el.getBoundingClientRect()
      const pixelHeight = 16
      const inputPaddingRem = Math.round(
        rect.width / pixelHeight + defaultRightPadding
      )
      return inputPaddingRem
    }

    const updatePaddingForTrailingContent = () => {
      const contentEl = get(trailingContentRef)
      const inputPaddingRightRem = calculatePaddingFromRef(contentEl)
      set(paddingRightRem, inputPaddingRightRem)
    }

    const updatePaddingForLeadingContent = () => {
      const leadingContentEl = get(leadingContentRef)
      const inputPaddingLeftRem = calculatePaddingFromRef(leadingContentEl)
      set(paddingLeftRem, inputPaddingLeftRem)
    }

    const inputInferredStyle = computed(() => {
      const rightPadding = get(paddingRightRem) || defaultRightPadding
      const leftPadding = get(paddingLeftRem) || defaultRightPadding
      return `padding-right: ${rightPadding}rem; padding-left: ${leftPadding}rem;`
    })

    const InputClass = computed(() => {
      return {
        'border-brand-gray-light': !get(blurredInputHasError),
        'border-brand-red': get(blurredInputHasError),
        'pl-10': props.leadingIcon !== ''
        // pad the placeholder if there's a leading icon
        // Any additional reactive classes here
      }
    })

    const labelColorIndex = {
      gray: 'text-brand-gray-darker',
      white: 'text-white'
    }

    const labelClass = computed(() => ({
      [labelColorIndex[props.labelColor]]: !get(blurredInputHasError),
      'text-brand-red': get(blurredInputHasError)
    }))

    onMounted(async () => {
      await nextTick()
      if (props.autofocus) {
        focusInput()
      }
    })

    watch(trailingContentRef, ref => {
      if (ref && hasTrailingContent) {
        // falsify settimeout so it falls after the render queue
        setTimeout(updatePaddingForTrailingContent)
      }
    })

    watch(leadingContentRef, ref => {
      if (ref) {
        setTimeout(updatePaddingForLeadingContent)
      }
    })

    /**
     * Expose this on component
     * so parent instances can manually focus when necessary
     */
    const focusInput = () => {
      input.value.focus()
    }

    return {
      input,
      inputId,
      InputClass,
      labelClass,
      isFocused,
      focusInput,
      emitValidatedModelUpdate,
      blurredInputHasError,
      trailingContentRef,
      inputInferredStyle,
      hasTrailingContent,
      leadingContentRef,
      hasLeadingContent
    }
  }
}
</script>

<template>
  <div class="relative flex flex-col w-full space-y-2">
    <label
      v-show="label !== ''"
      :for="inputId"
      :class="labelClass"
      class="block text-sm text-brand-gray-darker"
    >
      {{ label }}
    </label>
    <div class="relative">
      <div
        v-if="leadingIcon !== '' && leadingIcon !== undefined"
        ref="leadingContentRef"
        class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
      >
        <BaseIcon :icon="leadingIcon" />
      </div>
      <div
        v-else-if="hasLeadingContent"
        ref="leadingContentRef"
        class="absolute inset-y-0 left-0 flex items-center pl-1"
      >
        <slot name="leading-content" />
      </div>

      <input
        @input="emitValidatedModelUpdate"
        @focus="isFocused = true"
        @blur="isFocused = false"
        v-bind="$attrs"
        :id="inputId"
        :style="inputInferredStyle"
        :placeholder="placeholder"
        :value="modelValue"
        :class="InputClass"
        autocomplete="on"
        class="block w-full py-2 text-black border rounded placeholder-brand-gray-dark focus:outline-none focus:ring-brand-blue focus:border-brand-blue focus:z-10 sm:text-sm"
        ref="input"
      />
      <div
        v-if="hasTrailingContent"
        :class="isFocused ? 'border-brand-blue' : 'border-brand-gray-light'"
        class="absolute inset-y-0 top-0 right-0 flex items-center px-4 border-l"
        ref="trailingContentRef"
      >
        <slot
          name="trailing-content"
          :trailing-content-ref="trailingContentRef"
        />
      </div>
    </div>
    <span
      v-if="errorLabel"
      v-show="blurredInputHasError"
      class="absolute text-xs text-brand-red top-full"
    >
      {{ errorLabel }}
    </span>
  </div>
</template>

<style scoped>
/* removes chromes auto styling for auto full */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* remove input type=number arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
