// utils.js
// TODO:
// - [] Modularize by file, different functions

/**
 * Apollo client utilities.
 * Currently not exposed outside of directory and used
 * just for sibling files
 */
import { COMMENT_TYPE } from './'

/**
 * This is the inner object of listCommentsResult that contains the paging
 * token and items (comments) array.
 * @typedef {Object} listComments
 * @property {array} items
 * @property {string} nextToken
 */

/**
 * This is the container object for the apollo cache within which
 * comments and paging token are stored.
 * When setting data from an updateQuery, the object provided must
 * be a listCommentsResult.
 * @typedef {Object} listCommentsResult
 * @property {listComments} listComments
 */

/**
 * This is the inner object of listCommentRepliesResult that contains the
 * paging token (nextToken) and items (commentReplies) array.
 * @typedef {Object} listCommentReplies
 * @property {array} items Array of commentReplies
 * @property {string} nextToken Paging token
 */

/**
 * This is the container object for the apollo cache within which
 * commentReplies and paging token are stored.
 * When setting data from an updateQuery, the object provided/returned to apollo
 * must be a listCommentRepliesResult
 * @typedef {Object} listCommentRepliesResult
 * @property {listCommentReplies}
 */

/**
 * The provided comment type is valid for a
 * graphql query, subscription, or mutation.
 *
 * @param {COMMENT_TYPE} type -
 * @returns {Boolean} Is the provided type valid?
 */
const commentTypeIsValid = type =>
  Object.values(COMMENT_TYPE).indexOf(type) !== -1

/**
 * Validates the susbcriptionData.data object
 * from the apollo updateQuery callback.
 *
 * @param {Object} data Update data
 * @return {boolean} is the data valid (is an object with a keyed subscription trigger)
 */
const queryUpdateValidator = data => {
  // validate subscriptionData here
  return !!(data && Object.keys(data).length > 0)
}

/**
 * Adds the given comment to the apollo client cache for list comments
 *
 * @param {listCommentsResult} prevResult The previous result from apollo cache.
 * @param {object} newComment  The comment to add.
 *
 * @returns {listCommentsResult} Return new listCommentsResult object with add modification.
 */
const addComment = (prevResult, newComment) => {
  const prevItems = prevResult.listComments.items
  const prevItemsCommentIds = prevItems.map(c => c.commentId)
  const isAlreadyInList = prevItemsCommentIds.includes(newComment.commentId)
  if (isAlreadyInList) {
    return prevResult
  }
  return {
    listComments: {
      ...prevResult.listComments,
      items: [...prevResult.listComments.items, newComment]
    }
  }
}

/**
 * Updates the given comment to the apollo client cache for list comments
 *
 * @param {listCommentsResult} prevResult The previous result from apollo cache.
 * @param {object} updatedComment  The comment to update.
 *
 * @returns {listCommentsResult} Return new listCommentsResult object with the comment updated.
 */
const updateComment = (prevResult, updatedComment) => ({
  listComments: {
    ...prevResult.listComments,
    items: prevResult.listComments.items.map(c =>
      c.commentId === updatedComment.commentId ? updatedComment : c
    )
  }
})

/**
 * Deletes the given comment from the apollo client cache for list comments
 *
 * @param {listCommentsResult} prevResult The previous result from apollo cache.
 * @param {object} deletedComment  The comment to delete.
 *
 * @returns {listCommentsResult} Return new listCommentsResult object with the comment removed.
 */
const deleteComment = (prevResult, deletedComment) => ({
  listComments: {
    ...prevResult.listComments,
    items: prevResult.listComments.items.filter(
      c => c.commentId !== deletedComment.commentId
    )
  }
})

/**
 * Adds the given comment reply to the apollo cache for list
 * comment replies.
 *
 * @param {listCommentRepliesResult} prevResult The cached result to update.
 * @param {object} newCommentReply The comment reply to add to the cache.
 *
 * @returns {listCommentRepliesResult} Return new listCommentRepliesResult with the added comment reply.
 */
const addCommentReply = (prevResult, newCommentReply) => ({
  listCommentReplies: {
    ...prevResult.listCommentReplies,
    items: [...prevResult.listCommentReplies.items, newCommentReply]
  }
})

/**
 * Updates the given comment reply in the apollo cache for list
 * comment replies.
 *
 * @param {listCommentRepliesResult} prevResult The cached result to update.
 * @param {object} updatedCommentReply The comment reply to update.
 *
 * @returns {listCommentRepliesResult} Return new listCommentRepliesResult with the updated commentReply.
 */
const updateCommentReply = (prevResult, updatedCommentReply) => ({
  listCommentReplies: {
    ...prevResult.listCommentReplies,
    items: prevResult.listCommentReplies.items.map(c =>
      c.commentId === updatedCommentReply.commentId ? updatedCommentReply : c
    )
  }
})

/**
 * Deletes the given comment reply in the apollo cache for list
 * comment replies.
 *
 * @param {listCommentRepliesResult} prevResult The cached result to update.
 * @param {object} deletedCommentReply The comment reply to remove.
 *
 * @returns {listCommentRepliesResult} Return new listCommentRepliesResult without the specified commentReply.
 */
const deleteCommentReply = (prevResult, deletedCommentReply) => ({
  listCommentReplies: {
    ...prevResult.listCommentReplies,
    items: prevResult.listCommentReplies.items.filter(
      c => c.commentId !== deletedCommentReply.commentId
    )
  }
})

/**
 * Gets the subscription key that was triggered by an external update
 * as a string.
 *
 * @param {Object} data data object from updateQuery
 *
 * @returns {(
 * 'onCreateComment'|'onUpdateComment'|'onDeleteComment'|
 * 'onCreateCommentReply'|'onUpdateCommentReply'|'onDeleteCommentReply'|null
 * )}
 *
 */
const triggeredSubscription = data => {
  if (data && Object.keys(data) && Object.keys(data).length) {
    return Object.keys(data)[0] // returns the subscription trigger as string
  }
  return null
}

/**
 * @type {Object}
 * Index for updateQueries on subscriptions, keyed by subscription name,
 * value is the callback to provide the apollo client updateQuery handler.
 *
 */
const subscriptionModiferIndex = {
  // Comments
  onCreateComment: addComment,
  onUpdateComment: updateComment,
  onDeleteComment: deleteComment,
  // Replies
  onCreateCommentReply: addCommentReply,
  onUpdateCommentReply: updateCommentReply,
  onDeleteCommentReply: deleteCommentReply
}

/**
 * updateQuery handler for apollo client subscriptions.
 * Universally fed to `subscribeToMore` for vue/apollo-composable
 *
 * @param {(listCommentsResult|listCommentRepliesResult)} prevResult  The previous result
 * @param {Object} subscriptionData subscriptionData provided by updateQuery
 * @param {Object} subscriptionData.data Data object desctructured from subscriptionData object.
 * @returns {(listCommentsResult|listCommentRepliesResult)} Returns the update listCommentsResult
 * or listCommentRepliesResult object to be set in apollo client's cache.
 */
const updateQuery = (prevResult, { subscriptionData: { data } }) => {
  const subscription = triggeredSubscription(data)
  if (queryUpdateValidator(data) && subscription) {
    logUpdateQuery({ subscription, prevResult, data })
    const modifier = subscriptionModiferIndex[subscription]
    return modifier(prevResult, data[subscription]) // return object to set in apollo client cache
  }
  return prevResult
}

const logUpdateQuery = ({ subscription, prevResult, data } = {}) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(`[APOLLO]: ${subscription}`, {
      prevResult,
      data
    })
  }
}

const getAnnotationAnchor = (comment = {}) => {
  if (comment.annotationAnchor) {
    return JSON.parse(comment.annotationAnchor)
  }
}

export { commentTypeIsValid, updateQuery, getAnnotationAnchor }
