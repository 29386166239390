import { Model, ObjectPermissions, PusherManager } from './'
import { ensurePrependedPathObject } from '@/store'
import { PartApiCommunicator, getSignedStorageUrl, loadBlob } from '@/api'
import { BlobDataType } from '@GrabCAD/mesh-vue-component'
import { Logger } from '@aws-amplify/core'
import { simpleSort, promiseListSkipErrors } from '@/utils'
import { COMMENT_TYPE } from '@/apollo'
import { PartDTO } from '../dtos'

const logger = new Logger('@/store/models/Part')

/**
 * Part model functioning as service/interaction layer for
 * the core PartDTO data.
 */
export default class Part extends PartDTO {
  /** Object keyed by PartId for caching signed image urls */
  static ImageCache = {}

  constructor (json) {
    super(json)

    /**
     * @type {Model[]}
     */
    this.models = []

    /**
     * @type {?Blob[]}
     */
    this.referenceCADBlobs = []

    /**
     * @type {PartApiCommunicator}
     */
    this.apiCommunicator = new PartApiCommunicator(json.projectId, json.partId)

    /**
     * @type {boolean} If the part was created with a reference CAD upload,
     * need a flag to signal to UI for additional loading indicators.
     */
    this.uploading = false
    this.uploadProgress = 0

    this.setWithJSON(json)
  }

  setWithJSON (json = {}) {
    this.createdAt = json.createdAt
    this.failReason = json.failReason
    this.failedVersion = json.failedVersion
    this.partId = json.partId
    this.partName = json.partName
    this.partStatus = json.partStatus
    this.projectId = json.projectId
    this.updatedAt = json.updatedAt
    this.updatedBy = json.updatedBy

    this.permissions = new ObjectPermissions({
      objectType: 'parts',
      objectId: this.partId,
      orgs: json.orgPermissions,
      users: json.userPermissions
    })

    const models = json.models || []
    models.forEach((m) => {
      const asModel = new Model(m)
      this.addModelToList(asModel)
    })

    this.refCadConvertFailReason = json.refCadConvertFailReason

    const paths = json.paths || {}
    this.paths = ensurePrependedPathObject(paths)
  }

  referenceCADCommentContext () {
    return { type: COMMENT_TYPE.REFERENCE_CAD, objectId: this.partId }
  }

  getSignedPreviewImageUrl () {
    const partId = this.partID
    const cached = Part.ImageCache[partId]
    return cached || this.signedPreviewImageUrl || ''
  }

  getPreviewImagePath () {
    const models = this.models
    const modelWithWebPreview = models.find((m) => m.getHasWebPreview())
    if (modelWithWebPreview) {
      return modelWithWebPreview.getWebPreview()
    }
    return this.getRefCADThumbnail()
  }

  getRefCADThumbnail () {
    const paths = this.paths || {}
    return paths.referenceThumbnail
  }

  /**
   * Does this part have at least one model with a webpreview key?
   * @returns {boolean}
   */
  get hasModelWithWebPreview () {
    if (this.models && this.models.length) {
      // does this part have at leasst one model with
      // a `webpreview` key on it?
      return !!(this.models.filter((m) => m.paths?.webpreview).length > 0)
    }
    return false
  }

  async requestSignedImageUrl () {
    const previewImagePath = this.getPreviewImagePath()
    if (previewImagePath) {
      const signedImageUrl = await getSignedStorageUrl(previewImagePath)
      Part.ImageCache[this.partId] = signedImageUrl
      this.signedPreviewImageUrl = signedImageUrl
    }
  }

  getModelCount () {
    return this.getModels().length
  }

  /**
   * @returns {Model[]}
   */
  getModels () {
    return this.models
  }

  /**
   * @returns {Model}
   */
  getDefaultModel () {
    return this.getModels()[0]
  }

  getModelById (modelId) {
    return this.models.find((m) => m.modelId === modelId)
  }

  modelIsInList (model) {
    const modelIds = this.models.map((m) => m.modelId)
    return modelIds.includes(model.modelId)
  }

  getModelsSortedByName (list, asc = true) {
    if (!list) {
      list = this.models
    }
    return list.sort((a, b) => {
      const lowerAName = a.modelName?.toLowerCase() || ''
      const lowerBName = b.modelName?.toLowerCase() || ''
      return simpleSort(lowerAName, lowerBName, asc)
    })
  }

  getModelsSortedByCreatedAt (list, asc = true) {
    if (!list) {
      list = this.models
    }
    return list.sort((a, b) => {
      const aCreatedAt = a.createdAt
      const bCreatedAt = b.createdAt
      return simpleSort(aCreatedAt, bCreatedAt, asc)
    })
  }

  getModelsSortedByRecentActivity (list, asc = true) {
    if (!list) {
      list = this.models
    }
    return list.sort((a, b) => {
      const aUpdatedAt = a.updatedAt
      const bUpdatedAt = b.updatedAt
      return simpleSort(aUpdatedAt, bUpdatedAt, asc)
    })
  }

  getModelsWithCleanVersions () {
    return this.models.filter((m) => m.hasCleanVersion())
  }

  getModelsWithCleanVersionsAndPaths () {
    return this.models.filter((m) => m.hasCleanVersionWithPath())
  }

  getModelsBeingProcessed () {
    return this.models.filter((m) => m.isBeingProcessed())
  }

  getModelsWithErrors () {
    return this.models.filter((m) => m.hasError())
  }

  /**
   * List the models that are being created with uploads (existing scan mesh).
   * As well as those with the S3 buffer after upload.
   */
  getModelsUploading () {
    return this.getModels().filter(m => m.isUploading() || m.isWaitingOnUpload())
  }

  getModelsWithComparisonMesh () {
    const models = this.models
    const modelsWithComparisonMesh = models.filter((m) =>
      m.getHasComparisonMesh()
    )
    return modelsWithComparisonMesh
  }

  getModelWithComparisonMesh () {
    const modelsWithComparisonMesh = this.getModelsWithComparisonMesh()
    // TODO:
    // - [ ] Need to determine this indexing access in some way
    return modelsWithComparisonMesh?.[0]
  }

  hasOneModelWithComparisonMesh () {
    const modelsWithComparisonMesh = this.getModelsWithComparisonMesh()
    return modelsWithComparisonMesh.length > 0
  }

  addModelToList (model) {
    if (!this.modelIsInList(model)) {
      this.models.push(model)
    }
    return this.getModels()
  }

  /**
   * @returns {Model[]} The updated list of models for the part
   */
  replaceModelInList (model) {
    const models = this.models
    if (model) {
      this.models = models.map((m) => {
        if (m.modelId === model.modelId) {
          return model
        }
        return m
      })
    }
    return this.getModels()
  }

  /**
   * @param {Model} model
   * @returns {Model[]}
   */
  updateModelInList (model) {
    const cachedModel = this.getModelById(model.modelId)
    cachedModel?.setWithJSON(model)
    return this.getModels()
  }

  removeModelFromList (modelId) {
    if (modelId) {
      this.models = this.models.filter((m) => m.modelId !== modelId)
    }
    return this.models
  }

  async fetchAndStoreDetails () {
    const apiCommunicator = this.apiCommunicator
    const fullPart = await apiCommunicator.fetch()
    this.setWithJSON(fullPart)
    return this
  }

  unsetTemporaryFlags () {
    this.uploading = false
    this.uploadProgress = 0
  }

  /**
   * @param {!File} file Validated file to upload
   */
  async uploadReferenceCAD (file) {
    const self = this
    //
    // we set the `uploading` flag here to combat a
    // specific condition gap in the UI's loading state, where
    // we can't determine the difference between a part created
    // via reference upload vs scan mesh upload.
    //
    // There's a buffer after an upload is complete on client side to
    // when the upload is processed through an S3 trigger and relayed back
    // to client via pusher.
    // Therefore, unless there's an error on our side, we keep the flag
    // true until a pusher message comes in and unsets it.
    // @see {this.unsetTemporaryFlags}
    //
    self.uploading = true
    const { unsignedUrl, uploadBucket } = await this.getReferenceCADUploadUrl(
      file.name || this.partName
    )
    try {
      await PartApiCommunicator.uploadReferenceCAD({
        unsignedUrl,
        file,
        uploadBucket,
        progressCallback: progress => {
          console.log('Progress!', progress.loaded / progress.total)
          self.uploadProgress = Math.round(progress.loaded * 100 / progress.total)
        }
      })
      logger.info('uploadReferenceCAD(file) success')
    } catch (err) {
      self.uploading = false
      logger.error('uploadReferenceCAD(file)', err)
    }
  }

  /**
   * For the Part create with "Existing Scan Mesh"
   * flow, we upload the given file, to the
   * default created model.
   * @param {!File} file
   */
  async uploadExistingScanMeshForDefaultModel (file) {
    const defaultModel = this.getDefaultModel()
    await defaultModel.uploadExistingScanMesh(file)
  }

  /**
   * Makes POST to get an upload url for reference CAD using
   * this part's parameters and given uploadFileName.
   * @returns {Object} Upload object
   */
  async getReferenceCADUploadUrl (uploadFileName) {
    const apiCommunicator = this.apiCommunicator
    const response = await apiCommunicator.fetchReferenceCADUploadUrl(
      uploadFileName
    )
    return response.upload_url
  }

  getHasOneModelWithCleanVersion () {
    const models = this.models
    return models.filter((m) => m.hasCleanVersion()).length > 0
  }

  getPaths () {
    return this.paths || {}
  }

  /**
   * Does the part have a referenceCAD that has been converted for
   * viewing (stl/ply) in mesh vue component?
   */
  get hasConvertedReferenceCAD () {
    return !!this.getPaths() && Object.keys(this.paths).length > 0
  }

  /**
   * If the part has any items in the path, imples
   * that a CAD has been uploaded to this part, but there's a chance
   * it is either still being converted (Pusher Update should update instance),
   * or there was an error in conversion.
   */
  getHasUploadedReferenceCAD () {
    const paths = this.getPaths()
    const referenceUploadKey = 'referenceUpload'
    const pathKeys = Object.keys(paths)
    return pathKeys.includes(referenceUploadKey)
  }

  /**
   * The converted CAD is available and viewable via mesh vue component.
   */
  getHasConvertedReferenceCAD () {
    const paths = this.getPaths()
    const existingPathKeys = Object.keys(paths)
    const convertedRefCADKey = 'reference'
    const secondaryConvertedRefCADKey = 'referenceStepConverted'
    return (
      existingPathKeys.includes(convertedRefCADKey) ||
      existingPathKeys.includes(secondaryConvertedRefCADKey)
    )
  }

  // TODO: changed to getHasViewableReferenceCAD
  getHasViewableCAD () {
    const paths = this.getPaths()
    const existingPathKeys = Object.keys(paths)
    const refCADKey = 'reference'
    return existingPathKeys.includes(refCADKey)
  }

  getReferenceModelURL () {
    const paths = this.getPaths()
    return paths.reference
  }

  delete () {
    const apiCommunicator = this.apiCommunicator
    // TODO:
    // append with deleteAndRemoveFromStore method
    return apiCommunicator.delete()
  }

  async rename (newName) {
    if (newName === this.partName) return
    try {
      const apiCommunicator = this.apiCommunicator
      await apiCommunicator.rename(newName)
      this.partName = newName
    } catch (err) {
      logger.error(err)
    }
  }

  /**
   * We used to fetch models for project and filter for
   * part return, but that can easily exceed maximum
   * payload size, so we fetch the part details,
   * use the models list on the return and loop through each and fetch
   * details.
   * @link {https://github.com/MODit3D/user-web-app/issues/52}
   */
  async fetchAndStoreModels () {
    try {
      const t0 = performance.now()
      const detailsFetch = await this.apiCommunicator.fetch()
      this.setWithJSON(detailsFetch)
      // map over returned models and fetch details for each
      const models = this.getModels()
      await promiseListSkipErrors(
        models.map((m, i) => {
          return m.fetchAndStoreDetails()
        })
      )
      const t1 = performance.now()
      logger.info(
        `Fetch and store models took ${t1 - t0}ms.
        (${(t1 - t0) / 1000}s).
        For part with ${models.length} models`
      )
    } catch (err) {
      logger.error(err)
    }
  }

  async copy (destinationProjectId) {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.copy(destinationProjectId)
  }

  getReferenceCADBlobs () {
    return this.referenceCADBlobs || []
  }

  hasReferenceCADBlobs () {
    return this.getReferenceCADBlobs().length > 0
  }

  /**
   * If this part has converted an upload ref CAD to possible mesh
   * for viewing, it'll be available in the paths obejct.
   * Fetch the mesh/blobs here and return as list.
   *
   * @returns {Promise<Blob[]>} List of blobs relating to ref cad
   */
  async loadReferenceCADBlobs () {
    if (!this.paths || Object.keys(this.paths).length === 0) {
      logger.info(
        'Part ',
        this.partId,
        'attempted reference CAD blob load, but did not have necessary paths'
      )
      return
    }

    // possible keys returned from this.paths
    // match them to the blob data types for mesh vue component
    const dataTypeIndex = {
      reference: BlobDataType.Mesh,
      referenceStepConverted: BlobDataType.Mesh,
      referenceSegments: BlobDataType.Segments,
      referenceFeatures: BlobDataType.GeoFeatures
    }

    // remove possiblity of duplicates from the scoped paths
    // reference and referenceStepConverted are the same ply
    const paths = {}
    const keys = Object.keys(this.paths)

    // populate the local paths object
    // with deduped key values
    Object.values(this.paths).forEach((p, i, arr) => {
      if (arr.indexOf(p) === i) {
        const key = keys.find((k) => this.paths[k] === p)
        paths[key] = p
      }
    })

    // load the blobs for the scoped paths
    // and cache them, keeps the original paths object clean
    // but ensures we're not duplicating network calls for downloading
    // meshes
    const blobPromises = Object.keys(paths)
      .filter((p) => paths[p] && dataTypeIndex[p]) // if there's a valid unsigned url
      .map((p) => loadBlob(paths[p], dataTypeIndex[p])) // load its blob

    this.referenceCADBlobs = await Promise.all(blobPromises)
    return this.referenceCADBlobs
  }

  processPusherUpdateForModels (message) {
    const operation = PusherManager.getOperationFromUpdateMessage(message)
    const record = PusherManager.getRecordDataFromUpdateMessage(message)
    const eventMessageImpliesDelete =
      PusherManager.getEventMessageImpliesDelete(message)
    const { MODIFY, INSERT } = PusherManager.UPDATE_OPERATIONS

    const newModel = new Model(record)
    const modelId = newModel.modelId

    if (eventMessageImpliesDelete) {
      this.removeModelFromList(modelId)
    } else if (operation === MODIFY) {
      // NOTE: Model records from pusher messages
      // do NOT have versions in them...so we merge the differences here.
      const existingModel = this.getModelById(modelId)
      const existingVersions = existingModel?.getVersions() || []
      newModel.versions = existingVersions
      this.updateModelInList(newModel)
    } else if (operation === INSERT) {
      this.addModelToList(newModel)
    }
  }
}
