<script>
import { reactive, toRef, ref, nextTick, watch } from 'vue'
import { set } from '@vueuse/core'
import { createPopper } from '@popperjs/core'
import { POPPER_PLACEMENT_LIST } from '../constants'
import * as CommonProps from '../common/props'

/**
 * This component serves as a wrapper for Popper.js.
 */
export default {
  name: 'StatefulPositioner',
  props: {
    /**
     * The dom node to which we're relatively positioning the slot
     * content.
     */
    targetRef: CommonProps.HTMLEl,
    /**
     *
     */
    isVisible: CommonProps.BOOLEAN,
    /**
     * [skidding, distance]
     * skiding is the length (px) along the reference.
     * distance is the length (px) away from the reference.
     */
    offset: {
      ...CommonProps.ARRAY,
      default: () => [0, 0]
    },
    /**
     *
     */
    placement: {
      ...CommonProps.STRING,
      default: 'auto',
      validator: function (placement) {
        return POPPER_PLACEMENT_LIST.includes(placement)
      }
    },
    /**
     * The parentVisibility is the desired visibility
     * of the positioner and its
     * slotted content. However, because Popper.js
     * is positioning itself in the mounting process,
     * the animation can be a bit wonky, which is why the animation
     * state and lifecycle are tightly bound in this component.
     * To simply follow the parentVisibility state,
     * use false for manualDestory, otherwise, this component
     * exposes a method to destroy, such as on a transition
     * callback to destory the popper once an animation is finished.
     */
    manualDestroy: CommonProps.BOOLEAN,
    /**
     *
     * Because the UI supports nested menus as flyouts but each
     * one uses a teleport to the root of the dom (to avoid overflow
     * CSS issues), we have to convert Popper's internal means of updating
     * an element (in line style transforms) into a reactive vue
     * property so we can update any extant children nodes.
     * The root then sets a ref for its transformed styles
     * and passes it down for any children positioned els to watch.
     *
     */
    manualUpdateProperty: CommonProps.STRING
  },
  setup (props) {
    const popperRef = ref(null)
    /**
     * This becomes true on the first update
     * and allows to control transform animations that
     * disregard the placing of the popper itself
     * which, in this case uses 'fixed' positioning
     * alongside translations.
     */
    const showSlot = ref(false)
    const parentVisibility = toRef(props, 'isVisible')
    const state = reactive({ isOpen: false, popperInstance: null })
    const transformStr = ref('')

    const isParentModifier = {
      name: 'parentEventBus',
      enabled: true,
      phase: 'afterWrite',
      fn ({ state }) {
        set(transformStr, state?.styles?.popper?.transform)
      }
    }

    const initPopper = async () => {
      const popper = createPopper(props.targetRef, popperRef.value, {
        strategy: 'fixed',
        placement: props.placement,
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              padding: 8
            }
          },
          {
            name: 'offset',
            options: { offset: props.offset }
          },
          {
            name: 'flip',
            options: {
              padding: 8,
              rootBoundary: 'document'
            }
          },
          {
            name: 'computeStyles',
            options: {
              adaptive: false
            }
          },
          {
            name: 'eventListeners',
            enabled: true
          },
          ...(!props.manualUpdateProperty ? [isParentModifier] : [])
        ],
        onFirstUpdate: () => set(showSlot, true)
      })
      state.popperInstance = popper
    }

    watch(parentVisibility, async isVisible => {
      if (isVisible) {
        state.isOpen = true
        await nextTick()
        initPopper()
      } else {
        if (!props.manualDestroy) {
          destroyPositioner()
        }
        set(showSlot, false)
      }
    })

    const destroyPositioner = () => {
      state.isOpen = false
    }

    const updatePositioner = () => {
      state.popperInstance?.update()
    }

    watch(toRef(props, 'manualUpdateProperty'), updatedProperty => {
      if (updatedProperty) {
        state.popperInstance?.update()
      }
    })

    return {
      popperRef,
      state,
      showSlot,
      destroyPositioner,
      updatePositioner,
      transformStr
    }
  }
}
</script>

<template>
  <div v-if="state.isOpen" ref="popperRef" id="popper">
    <slot
      :transform-string="transformStr"
      :update-positioner="updatePositioner"
      :destroy-positioner="destroyPositioner"
      :show-slot="showSlot"
    />
  </div>
</template>

<style scoped>
div {
  z-index: 25;
}
</style>
