import { ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { get, useTimeoutFn, set, createEventHook } from '@vueuse/core'

/**
 * @typedef {Object} useHoveredElsOptions
 * @property {number} enterDelayMS
 * @property {number} leaveDelayMS
 * @property {boolean}  listenOnMount
 */

/** @type {useHoveredElsOptions} */
const defaultHoveredElsOptions = {
  enterDelayMS: 0,
  leaveDelayMS: 0,
  listenOnMount: true
}

/**
 * Event listener to determine if mouse is within given list
 * of refs.
 *
 * @param {HTMLElement[]} els List of element refs
 * @param {useHoveredElsOptions} options
 */
export const useHoveredEls = (els, options = defaultHoveredElsOptions) => {
  const isHovered = ref(false)
  const delayedMouseEnterEvent = createEventHook()
  const delayedMouseLeaveEvent = createEventHook()

  const {
    isPending: startMouseTimerIsPending,
    start: startMouseEnterTimerFn,
    stop: stopMouseEnterTimerFn
  } = useTimeoutFn(
    () => {
      set(isHovered, true)
      delayedMouseEnterEvent.trigger()
    },
    options.enterDelayMS,
    { immediate: false }
  )

  const {
    start: startMouseLeaveTimerFn,
    stop: stopMouseLeaveTimerFn
  } = useTimeoutFn(
    () => {
      // set(isHovered, false)
      delayedMouseLeaveEvent.trigger()
    },
    options.leaveDelayMS,
    { immediate: false }
  )

  const processMouseMove = e => {
    // check if not in container
    // or in it and start timers accordingly
    const refList = get(els)
    const cursorDestination = e.toElement || e.target
    if (refList.length === 0 || !cursorDestination) {
      return
    }

    const hoveredRefs = refList
      .map(r => get(r) && get(r).contains(cursorDestination))
      .filter(Boolean)
    const cursorEventRegardsScopedTree = hoveredRefs.length > 0
    const pendingStartTimer = startMouseTimerIsPending.value

    if (cursorEventRegardsScopedTree && !pendingStartTimer) {
      stopMouseLeaveTimerFn()
      startMouseEnterTimerFn()
    } else if (!cursorEventRegardsScopedTree) {
      stopMouseEnterTimerFn()
      startMouseLeaveTimerFn()
    }
  }

  const attachMouseMoveListener = () => {
    document.addEventListener('mousemove', processMouseMove, false)
  }

  const destroyMouseMoveListener = () => {
    document.removeEventListener('mousemove', processMouseMove, false)
  }

  onBeforeMount(() => {
    const listenOnMount = options.listenOnMount
    listenOnMount && attachMouseMoveListener()
  })

  onBeforeUnmount(destroyMouseMoveListener)

  return {
    isHovered,
    onDelayedMouseLeave: delayedMouseLeaveEvent.on,
    onDelayedMouseEnter: delayedMouseEnterEvent.on
  }
}
