import { useMixpanel } from '.'
import { StoreManager } from '@/store'
import { get } from '@vueuse/core'
import { useAppRouterParams } from '@/router'
import { formatEventInfo, trackEventWithCallback } from '../'

/**
 * @typedef {Object} TrackOptions
 * @property {?string} orgId Supplied orgId for payload (overrides orgIdForView)
 * @property {?string} orgName
 *
 * @property {?string} projectId Supplied projectId (overrides projectIdForView)
 * @property {?string} projectName
 *
 * @property {?string} partId Supplied partId (override partIdForView)
 * @property {?string} partName
 *
 * @property {?boolean} orgForView Use the cached/active org info for payload
 * @property {?boolean} projectForView Use the cached/active project info for payload
 * @property {?boolean} partForView Use the cached/active part infor for payload
 */

/**
 * @typedef {Object} TrackPayload Resolved payload for mixpanel
 * @property {?string} orgId
 * @property {?string} orgName
 * @property {?string} projectId
 * @property {?string} projectName
 * @property {?string} partId
 * @property {?string} partName
 */

const getOrgInfoFromStore = () => {
  const store = StoreManager.storeInstance
  const orgId = store.activeOrgId
  const orgName = store.getActiveOrganization()?.orgName

  return { orgId, orgName }
}

const getProjectInfoFromStore = projectId => {
  const store = StoreManager.storeInstance
  const org = store.getActiveOrganization()
  const project = org.getProjectByProjectId(projectId)
  const projectName = project?.projectName

  return { projectId, projectName }
}

const getPartInfoFromStore = (projectId, partId) => {
  const store = StoreManager.storeInstance
  const org = store.getActiveOrganization()
  const project = org.getProjectByProjectId(projectId)
  const part = project?.getPartById(partId)
  const partName = part?.partName

  return { partId, partName }
}

/**
 * @param {TrackOptions}
 * @returns {TrackPayload}
 */
const resolvePayloadForView = (
  options,
  { projectIdForView, partIdForView } = {}
) => {
  const res = { ...options }
  let orgId = options.orgId
  let orgName = options.orgName
  if (options.orgForView) {
    const orgInfo = getOrgInfoFromStore()
    orgId = orgInfo.orgId
    orgName = orgInfo.orgName
    delete res.orgForView
  }

  let projectId = options.projectId
  let projectName = options.projectName
  if (options.projectForView) {
    const projectInfo = getProjectInfoFromStore(projectIdForView)
    projectId = projectInfo.projectId
    projectName = projectInfo.projectName
    delete res.projectForView
  }

  let partId = options.partId
  let partName = options.partName
  if (options.partForView) {
    const partInfo = getPartInfoFromStore(projectIdForView, partIdForView)
    partId = partInfo.partId
    partName = partInfo.partName
    delete res.partForView
  }

  return {
    ...res,
    ...(orgId && { orgId }),
    ...(orgName && { orgName }),
    ...(projectId && { projectId }),
    ...(projectName && { projectName }),
    ...(partId && { partId }),
    ...(partName && { partName })
  }
}

export const useMixpanelEvents = () => {
  const mixpanel = useMixpanel()
  const { projectIdForView, partIdForView } = useAppRouterParams()

  /**
   * @param {TrackOptions} options
   */
  const track = (event, options = {}, callbacks = {}) => {
    const resolvedOptions = resolvePayloadForView(options, {
      projectIdForView: get(projectIdForView),
      partIdForView: get(partIdForView)
    })
    const eventInfo = formatEventInfo(event, resolvedOptions)
    trackEventWithCallback(eventInfo, callbacks)
  }

  return {
    mixpanel,
    track
  }
}
