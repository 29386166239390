import { StoreManager } from '@/store'
import {
  Part,
  Model,
  SearchService,
  ObjectPermissions,
  PusherManager
} from './'
import { ProjectApiCommunicator } from '@/api'
import { ProjectDTO } from '../dtos'
import { Logger } from '@aws-amplify/core'
import { simpleSort } from '@/utils'

const logger = new Logger('Project')

/**
 * Project class, includes helpers for interacting with core-data
 * and interfacing with Project related internal APIs through
 * ProjectApiCommunicator.
 */
export default class Project extends ProjectDTO {
  static STATUSES = {
    READY: 'ready',
    COPYING: 'copying'
  }

  constructor (json = {}) {
    super(json)
    /**
     * @type {ProjectApiCommunicator}
     */
    this.apiCommunicator = new ProjectApiCommunicator(json.projectId)
    /**
     * @type {Part[]}
     */
    this.parts = []

    this.setWithJSON(json)
  }

  setWithJSON (json = {}) {
    this.createdAt = json.createdAt
    this.createdBy = json.createdBy
    this.indexedAt = json.indexedAt
    this.orgId = json.orgId
    this.projectId = json.projectId
    this.projectName = json.projectName
    this.transformSettings = json.transformSettings
    this.updatedAt = json.updatedAt
    this.updatedBy = json.updatedBy
    this.projectStatus = json.projectStatus

    const permissions = json.permissions || {}
    const permissionObjectType = permissions.objectType
    const permissionObjectId = permissions.objectId
    const orgPermissions = json.orgPermissions || permissions.orgs || {}
    const userPermissions = json.userPermissions || permissions.users || {}

    this.permissions = new ObjectPermissions({
      objectType: permissionObjectType || 'projects',
      objectId: permissionObjectId || json.projectId,
      orgs: orgPermissions,
      users: userPermissions
    })

    const parts = json.parts || []
    parts.forEach(p => {
      const asPart = new Part(p)
      this.addToParts(asPart)
    })
    return this
  }

  isBeingCopied () {
    const status = this.projectStatus
    return !!status && status === Project.STATUSES.COPYING
  }

  get partsByPartId () {
    return this.parts.reduce((acc, val) => ({ ...acc, [val.partId]: val }), {})
  }

  /** @returns {Part} */
  getPartById (partId) {
    return this.parts.find(p => p.partId === partId)
  }

  /**
   * Instances a search service if not available.
   * Then runs a search with given query and caches results
   * in search service.
   *
   * @async
   *
   * @param {!string} query Assumed, non-empty string to search against.
   */
  async searchParts (query) {
    if (!this.searchService) {
      this.searchService = new SearchService()
    }
    this.searchService.query = query.trim()
    let results = await this.searchService.search('/parts/_search')
    results = results
      .map(p => new Part(p))
      .filter(p => p.projectId === this.projectId)
    this.searchService.results = results
    return this.searchService.results
  }

  clearPartsSearch () {
    this.searchService = null
  }

  getParts () {
    return this.parts || []
  }

  getPartCount () {
    return this.getParts().length
  }

  hasPartInList (p) {
    const partIds = this.getParts().map(p => p.partId)
    return partIds.includes(p.partId)
  }

  /**
   * @param {Part} p Part to add
   * @returns {Part[]} Resulting list of parts
   */
  addToParts (p) {
    const isAlreadyInList = this.hasPartInList(p)
    if (!isAlreadyInList) {
      this.parts.push(p)
    }
    return this.parts
  }

  /**
   * @param {Part} part The part to replace in the list.
   * @returns {Part[]} The updated part list
   */
  replacePartInList (part) {
    if (this.parts && part) {
      this.parts = this.getParts().map(p =>
        p.partId === part.partId ? part : p
      )
    }
    return this.parts
  }

  /**
   * @param {Part} part
   * @returns {Part[]}
   */
  updatePartInList (part) {
    const cachedPart = this.getPartById(part.partId)
    cachedPart?.setWithJSON(part)
    cachedPart?.unsetTemporaryFlags()
    return this.getParts()
  }

  removePartFromList (partId) {
    if (partId) {
      this.parts = this.getParts().filter(p => p.partId !== partId)
    }
    return this.parts
  }

  /** @returns {Part[]} List of projects sorted by name */
  listPartsByName (asc = true) {
    return this.getParts().sort((a, b) =>
      simpleSort(a.partName.toLowerCase(), b.partName.toLowerCase(), asc)
    )
  }

  /** @returns {Part[]} List of parts sorted by recent activity timestamp */
  listPartsByRecentActivity (asc = true) {
    return this.getParts().sort((a, b) =>
      simpleSort(a.updatedAt, b.updatedAt, asc)
    )
  }

  /** @returns {Part[]} List of parts sorted by createdAt timestamp */
  listPartsByCreatedTimestamp (asc = true) {
    return this.getParts().sort((a, b) =>
      simpleSort(a.createdAt, b.createdAt, asc)
    )
  }

  /**
   * List of the all the models from the all the project's parts.
   * @returns {Model[]}
   */
  get allModelsInProject () {
    let allModelsInProject = []
    this.parts.forEach(p => {
      allModelsInProject = [...allModelsInProject, ...p.models]
    })
    return allModelsInProject
  }

  /**
   * Even though we update this instance,
   * we need to discreetly update this project within
   * owning organziation's projectList in order to maintain reactvitiy.
   */
  async fetchAndStoreDetails () {
    const apiCommunicator = this.apiCommunicator
    const projectObj = await apiCommunicator.fetch()
    this.setWithJSON(projectObj)
    return this
  }

  /**
   * Make put call to rename project's name
   * @param {string} newName The new name for the project
   */
  async rename (newName) {
    if (newName === this.projectName) return
    try {
      const apiCommunicator = this.apiCommunicator
      await apiCommunicator.rename(newName)
      this.projectName = newName
    } catch (err) {
      logger.error(err)
    }
  }

  /**
   * Instanced call of Project.delete(projectId).
   * This also removes the project from the store if possible.
   *
   * @async
   */
  async deleteAndRemoveFromStore () {
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.delete()

    const store = StoreManager.storeInstance
    const org = store.organization
    org.removeProjectFromList(this.projectId)
  }

  /**
   * Currently the models get is on the Project Api handler,
   * even though models exist as a data structure on a Part.
   *
   * For that reaason, here we fetch the models for the current project
   * then map them onto the correct part within this store.
   */
  async fetchAndStoreModels () {
    const apiCommunicator = this.apiCommunicator
    const modelsListResponse = await apiCommunicator.fetchModels()
    const models = modelsListResponse.map(m => new Model(m))
    // update each part in instance part list
    // with models from fetched list
    if (this.parts) {
      models.forEach(m => {
        if (this.partsByPartId[m.partId]) {
          this.partsByPartId[m.partId].updateModelInList(m)
        }
      })
    }
    return models
  }

  duplicate (projectName) {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.duplicate(projectName)
  }

  processPusherUpdateForParts (message) {
    const operation = PusherManager.getOperationFromUpdateMessage(message)
    const record = PusherManager.getRecordDataFromUpdateMessage(message)
    const eventMessageImpliesDelete = PusherManager.getEventMessageImpliesDelete(
      message
    )

    const { MODIFY, INSERT } = PusherManager.UPDATE_OPERATIONS

    const newPart = new Part(record)
    const partId = newPart.partId

    if (eventMessageImpliesDelete) {
      const partId = newPart.partId
      this.removePartFromList(partId)
    } else if (operation === MODIFY) {
      // NOTE: Part records from pusher messages
      // do NOT have models in them...so we merge the differences here.
      const existingPart = this.getPartById(partId)
      const existingModels = existingPart?.models || []
      existingModels.forEach(model => {
        newPart.addModelToList(model)
      })

      const existingPermissions = existingPart?.permissions || {}
      newPart.permissions = existingPermissions

      const existingBlobs = existingPart?.referenceCADBlobs
      newPart.referenceCADBlobs = existingBlobs

      this.updatePartInList(newPart)
    } else if (operation === INSERT) {
      this.addToParts(newPart)
    }
  }
}
