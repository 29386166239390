<script>
import BaseList from '../list/BaseList.vue'
import { noop } from '@/utils'
export default {
  name: 'MenuItemGroup',
  components: {
    BaseList
  },
  props: {
    /**
     * @type {import('./SelectMenu.vue').ItemGroup}
     */
    label: {
      type: String,
      required: false
    }
  },
  setup () {
    return { noop }
  }
}
</script>
<template>
  <div class="pt-1">
    <base-span v-if="label" class="px-2" rank="primary" type="caption" :lines="1">
      {{ label }}
    </base-span>
    <base-list>
      <slot />
    </base-list>
  </div>
</template>

<style scoped></style>
