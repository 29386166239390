const navbar = () =>
  import(/* webpackChunkName: 'navbar' */ '@/components/dashboard/TheDashboardNavbar.vue')

export const dashboard = {
  name: 'dashboard',
  path: 'project/:projectId?',
  components: {
    default: () =>
      import(
        /* webpackChunkName: 'project-dashboard' */ '../views/Dashboard.vue'
      ),
    leftSidebar: () =>
      import(
        /* webpackChunkName: 'dashboard-sidebar' */
        '@/components/dashboard/TheDashboardLeftSidebar.vue'
      ),
    navbar
  },
  meta: { requiresAuth: true }
}
