import { callLambdaApi, Methods, ApiNames } from '@/api'
import { User } from './'

/**
 * Class for permissions as they relate and belong
 * to a non-user object (Part, Projects).
 * TODO:
 * - [ ] Move api to communicator service
 */
export default class ObjectPermissions {
  constructor (json = {}) {
    const objectType = json.objectType
    const objectId = json.objectId
    /**
     * Object keyed by orgId, value is object with 'roles'
     * key that stores list of roles
     * @type {Object.<string, {{ roles: string[] }}>}
     */
    const orgs = json.orgs || {}

    this.objectType = objectType
    this.objectId = objectId

    const users = json.users || {}
    /**
     * Object of users with access to parent.
     * Keyed by email-hash, value is type User.
     *
     * @type {Object.<string, User>}
     */
    this.users = users
    this.orgs = orgs
  }

  getUsers () {
    return this.users || {}
  }

  getUsersAsList () {
    const asList = []
    const userIndex = this.getUsers()
    Object.keys(userIndex).forEach(emailHash => {
      const user = userIndex[emailHash]
      asList.push(user)
    })
    return asList
  }

  setPermissions ({ orgs = {}, users = {} } = {}) {
    const formattedUsers = {}
    Object.keys(users).forEach(emailHash => {
      const currUserObj = this.users[emailHash] || {}
      // spread a potential existing user object first, that
      // gets updated by key value from loop
      const mergedUser = { ...currUserObj, ...users[emailHash] }
      formattedUsers[emailHash] = new User({
        ...mergedUser,
        emailHash,
        id: emailHash
      })
    })
    this.orgs = { ...this.orgs, ...orgs }
    this.users = { ...this.users, ...formattedUsers }
  }

  async fetch () {
    const permissions = await ObjectPermissions.fetch(
      this.objectType,
      this.objectId
    )
    this.setPermissions(permissions)
    return permissions
  }

  static fetch (objectType, objectId) {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Org,
      path: `/permissions/${objectType}/${objectId}`
    })
  }

  static formatRolePayload (identifier, role) {
    return { [identifier]: [role] }
  }

  async addUserPermission (email, role) {
    const guests = ObjectPermissions.formatRolePayload(email, role)
    const body = { guests }
    const result = await ObjectPermissions.addUserPermission(
      this.objectType,
      this.objectId,
      body
    )
    const updatedTrimUserObj = result.users.permissions

    this.setPermissions({ users: updatedTrimUserObj })

    return this
  }

  static addUserPermission (objectType, objectId, body) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Org,
      path: `/permissions/${objectType}/${objectId}`,
      payload: { body }
    })
  }

  async deleteUserPermission (emailHash) {
    const body = [emailHash]
    try {
      await ObjectPermissions.deleteUserPermission(
        this.objectType,
        this.objectId,
        body
      )
      const currentUsers = this.getUsers()
      delete currentUsers[emailHash]
      this.setPermissions({ users: currentUsers })
    } catch (err) {}
    return this
  }

  static deleteUserPermission (objectType, objectId, body) {
    return callLambdaApi({
      method: Methods.DELETE,
      apiName: ApiNames.Org,
      path: `/permissions/${objectType}/${objectId}`,
      payload: { body }
    })
  }

  setDefaults () {
    this.users = {}
    this.orgs = {}
  }
}
