import config from '@/config'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

/**
 * Error tracking API wrappers.
 */
export default {
  /**
   * Vue plugin API for settings up error tracking.
   *
   * Note:
   * Ad-blockers can mess with the initialization of Sentry's SDK as well
   * as its attempt to ping endpoints.
   * @link {https://stackoverflow.com/questions/70095517/err-blocked-by-client-error-on-xhr-request-sentry-io-maps-googleapis-com-by}
   * @link {https://stackoverflow.com/questions/22318119/i-am-getting-failed-to-load-resource-neterr-blocked-by-client-with-google-chr/50151073#50151073}
   * @link {https://github.com/getsentry/sentry-javascript/issues/2916}
   *
   * Workarounds:
   * @link {https://docs.sentry.io/platforms/javascript/troubleshooting/#dealing-with-ad-blockers}
   *
   * @param {import('vue').App} app
   * @param {{
   * router: import('vue-router').Router
   * }} options
   */
  install: (app, options) => {
    const router = options.router
    Sentry.init({
      app,
      dsn: config.sentry.dsn,
      tracesSampleRate: config.sentry.tracesSampleRate,
      environment: config.sentry.environment,
      integrations: [new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })]
    })
  },
  /**
   * @param {import('@/store/models').User}
   */
  setUser: user => {
    Sentry.setUser(user)
  }
}
