// TODO:
// - [  ] Move these onto static variables for their relevant models.
import { MeasurementUnitProps } from '@GrabCAD/mesh-vue-component'

export const PermissionsRolesIndex = {
  VIEW: 'view',
  COMMENT: 'comment',
  EDIT: 'edit'
}

// todo: merge with Organization.ROLES
export const OrgRolesIndex = {
  OWNER: 'owner',
  ADMIN: 'admin',
  MANAGER: 'manager',
  OPERATOR: 'operator',
  GUEST: 'guest'
}

/**
 * Used for delta/default values in the displacement and
 * pass fail components to scale for cached display units.
 */
export const compareUnitIndex = {
  mm: {
    displacement: {
      default: 0.25,
      delta: 0.25
    },
    passFail: {
      default: 0.25,
      delta: 0.25
    }
  },
  /**
   * The micron aschii code
   * seems to shift in browser and is not the same
   * as the macos 'µ' (alt+m) hotkey.
   * Just use the ascii code to char here.
   */
  [`${String.fromCharCode(956)}m`]: {
    displacement: {
      default: 250,
      delta: 250
    },
    passFail: {
      default: 250,
      delta: 250
    }
  },
  in: {
    displacement: {
      default: 0.01,
      delta: 0.01
    },
    passFail: {
      default: 0.01,
      delta: 0.01
    }
  }
}

// ============
// MVC Mappings
// ============
export const unitSymbols = Object.getOwnPropertySymbols(MeasurementUnitProps)
export const unitLabelList = unitSymbols.map((symbol) => {
  return MeasurementUnitProps[symbol].label
})
