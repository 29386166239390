<script>
import { ref, watch, computed } from 'vue'
import { useToggle, set, get } from '@vueuse/core'
import { useCheckClickOutsideComponent } from '@/composables'
import * as CommonProps from '@/components/_base/common/props'

import Pane from '../layers/Pane.vue'
import StatefulPopover from '../utils/StatefulPopover.vue'
import StatefulPositioner from '../utils/StatefulPositioner.vue'
import StatefulChildMenuProvider from '../menu/nested-menu/StatefulChildMenuProvider.vue'

/**
 *
 */
export default {
  name: 'SelectMenu',
  components: {
    StatefulChildMenuProvider,
    StatefulPopover,
    Pane
  },
  inheritAttrs: false,
  emits: {
    open: null,
    close: null
  },
  props: {
    /**
     *
     */
    items: {
      type: [Array, Object],
      required: false,
      default: () => []
    },
    /**
     *
     */
    placement: StatefulPositioner.props.placement,
    /**
     */
    offset: StatefulPositioner.props.offset,
    /**
     *
     */
    width: {
      ...Pane.props.width,
      default: 200
    },
    /**
     *
     */
    maxHeight: Pane.props.maxHeight,
    /**
     *
     */
    closeOnOutsideClick: {
      ...CommonProps.BOOLEAN,
      default: true
    },
    /**
     * To align the menu to another
     * ref, provide one here, otherwise this'll be attached
     * to the selector templated slot.
     */
    alignmentTarget: CommonProps.HTMLEl,
    /**
     * Can either pass it in, or set from slot props
     */
    selectorEl: CommonProps.HTMLEl
  },
  setup (props, { emit }) {
    const [isVisible, toggleMenuIsVisible] = useToggle(false)
    const hide = () => set(isVisible, false)
    const menuPaneRef = ref(null)
    const localSelectorEl = ref(null)

    const setSelectorEl = comp => {
      const isVueComponent = !!comp?.$el
      if (isVueComponent) {
        localSelectorEl.value = comp.$el
      } else {
        localSelectorEl.value = comp
      }
    }

    const computedSelector = computed(() => {
      if (props.selectorEl) {
        return props.selectorEl
      }
      return get(localSelectorEl)
    })

    useCheckClickOutsideComponent({
      els: [menuPaneRef],
      watchProperty: isVisible,
      selectorRef: computedSelector,
      onClickOutside: () => {
        // this gets interrupted
        // by menuitemlist
        // conditional propogation
        if (props.closeOnOutsideClick) {
          hide()
        }
      }
    })

    watch(isVisible, currentVisibility => {
      if (currentVisibility) {
        emit('open')
      } else {
        emit('close')
      }
    })

    const computedTarget = computed(() => {
      if (props.alignmentTarget) {
        return props.alignmentTarget
      }
      return get(computedSelector)
    })

    return {
      isVisible,
      toggleMenuIsVisible,
      menuPaneRef,
      computedTarget,
      setSelectorEl,
      hide
    }
  }
}
</script>

<template>
  <slot
    name="selector"
    :toggle-menu="toggleMenuIsVisible"
    :set-selector-el="setSelectorEl"
  />
  <stateful-popover
    :is-visible="isVisible"
    :target-ref="computedTarget"
    :offset="offset"
    :placement="placement"
  >
    <template #default="{ transformString }">
      <pane
        @set-ref="ref => (menuPaneRef = ref)"
        :width="width"
        :max-height="maxHeight"
        rounding="small"
        shadow="large"
        y-scroll
      >
        <stateful-child-menu-provider>
          <MenuItemList
            @close="hide"
            :items="items"
            :manual-update-property="transformString"
            is-root
          />
        </stateful-child-menu-provider>
      </pane>
    </template>
  </stateful-popover>
</template>

<style scoped></style>
