<script>
/**
 * Wrapper component for modals
 * which generally present one or two buttons in stylistically similar arragnement.
 * Use common wrapper to guard against informed width issues in firefox/safari.
 */
export default {
  name: 'BaseModalButtonWrapper'
}
</script>

<template>
  <div class="w-full text-right space-x-2">
    <slot />
  </div>
</template>

<style></style>
