/**
 * Belongs to device, records job data for active device
 * connection job.
 *
 * TODO:
 * - [ ] move core data to dto
 */
export class DeviceJob {
  static STATUSES = {
    COMPLETE: 'complete',
    ONLINE: 'online',
    ABORTED: 'aborted'
  }

  /**
   * Debug data to test UI display.
   */
  static MOCK_DATA = {
    jobStatus: 'scanning',
    poseId: 0,
    captureNumber: 3,
    captureTotal: 8,
    modelId: 'modelId',
    userId: 'userId',
    lastUpdated: Date.now()
  }

  constructor (json = {}) {
    this.jobStatus = json.jobStatus
    this.poseId = json.poseId
    this.captureNumber = json.captureNumber
    this.captureTotal = json.captureTotal
    this.allCaptureTotal = json.allCaptureTotal
    this.modelId = json.modelId
    this.userId = json.userId
    this.lastUpdated = json.lastUpdated
    this.userFullName = json.userFullName
  }

  setJobStatus (jobStatus) {
    this.jobStatus = jobStatus
    return this
  }

  getCaptureCompletionPercentage () {
    const percentage = (this.captureNumber / this.captureTotal) * 100
    return percentage.toFixed(2)
  }

  belongsToUser (userId) {
    return this.userId === userId
  }

  isComplete () {
    const { COMPLETE } = DeviceJob.STATUSES
    return this.jobStatus === COMPLETE
  }

  isOnline () {
    const { ONLINE } = DeviceJob.STATUSES
    return this.jobStatus === ONLINE
  }

  isAborted () {
    const { ABORTED } = DeviceJob.STATUSES
    return this.jobStatus === ABORTED
  }
}
