import { OrganizationApiCommunicator } from '@/api'
import { StoreManager, OrgRolesIndex } from '@/store'
import {
  Project,
  SearchService,
  User,
  DeviceManager,
  PusherManager,
  OrgSettings,
  OrganizationPlan
} from './'
import { OrganizationDTO } from '../dtos'
import { simpleSort } from '@/utils'

import { Logger } from '@aws-amplify/core'

const logger = new Logger('Organization')

/**
 * Class for an "Organization".
 * Root of logged in user data/cache.
 * Stores projects list and interfaces with OrganizationApiCommunicator for
 * network calls.
 */
class Organization extends OrganizationDTO {
  // perhaps need
  // a better term to further distinguish
  // these from Permissions.ROLES
  static ROLES = ['Owner', 'Admin', 'Manager', 'Operator', 'Guest']
  static TIERS = {
    FREE: 'free',
    TIER_1: 'tier1',
    TIER_2: 'tier2',
    TIER_3: 'tier3'
  }

  /** @type {SearchService} */
  searchService

  // TODO:
  // - [ ] Move into User class -- these refer to a specific
  //       user's relationship to active org.
  userDefault
  email
  emailHash
  isOwner
  roles

  // this is localized for now
  // at some point it may live through and API
  // but org manager can set the colormap for compare
  // view and units of measure/display
  partViewPreferences

  constructor (json) {
    super(json)
    /**
     * @type {Project[]}
     */
    this.projects = []
    /**
     * @type {OrganizationApiCommunicator}
     */
    this.apiCommunicator = new OrganizationApiCommunicator(json.orgId)
    /**
     * @type {DeviceManager}
     */
    this.deviceManager = new DeviceManager()

    this.setWithJSON(json)
    this.setDefaultPartViewPreferences()
  }

  setWithJSON (json = {}) {
    this.createdAt = json.createdAt
    this.orgId = json.orgId
    this.orgName = json.orgName
    this.updatedAt = json.updatedAt

    this.users = (json.users || []).map(u => new User(u))

    const settings = json.settings || {}
    const settingsWithOrgId = { ...settings, orgId: json.orgId }
    this.settings = new OrgSettings(settingsWithOrgId)

    this.email = json.email
    this.emailHash = json.emailHash
    this.isOwner = json.isOwner
    this.roles = json.roles || []
    this.updatedAt = json.updatedAt
    this.userDefault = json.userDefault || false
    this.planTier = json.planTier

    const projects = json.projects || []
    projects.forEach(p => {
      const asProject = new Project(p)
      this.addProjectsToList(asProject)
    })

    if (json.orgPlan) {
      this.orgPlan = json.orgPlan
    }

    this.internal = json.internal || false
  }

  isInternal () {
    return !!this.internal
  }

  getSettings () {
    return this.settings
  }

  getDeviceManager () {
    return this.deviceManager
  }

  getPlan () {
    return this.orgPlan
  }

  setDefaultPartViewPreferences () {
    // TODO:
    // - [ ] Read off some constant elsewhere, besides hardcoding these
    this.partViewPreferences = { compareColorMapName: 'cet-d1a' }
  }

  setPartViewPreferenceColorMap (colorMap) {
    this.partViewPreferences.compareColorMapName = colorMap
  }

  setPartViewPreferenceUnits (units) {
    this.partViewPreferences.units = units
  }

  getIsSubscriber () {
    return this.planTierImpliesPaying()
  }

  planTierImpliesPaying () {
    const planTier = this.planTier
    const { TIER_1, TIER_2, TIER_3 } = Organization.TIERS
    return planTier === TIER_1 || planTier === TIER_2 || planTier === TIER_3
  }

  planTierImpliesFree () {
    const planTier = this.planTier
    const { FREE } = Organization.TIERS
    return planTier === FREE
  }

  getFormattedRoleIndexForUserPermissions () {
    return {
      [this.orgId]: { roles: this.roles }
    }
  }

  async fetchAndStoreDetails () {
    try {
      const apiCommunicator = this.apiCommunicator
      const details = await apiCommunicator.fetchDetails(this.orgId)
      // the return value here is different than org list get
      // and will override cached properties...
      this.setWithJSON({ ...this, ...details })
      return this
    } catch (err) {
      logger.error('fetchOrgDetails() error: ', err)
      throw err
    }
  }

  async fetchAndStorePlanDetails () {
    const api = this.apiCommunicator
    try {
      const plan = await api.fetchCurrentPlan()
      this.orgPlan = new OrganizationPlan(plan)
    } catch (err) {
      console.error(err)
    }
  }

  /**
   * @param {{
   *  sortField: string,
   *  sortAsc: boolean
   * }} sortOptions
   * @returns {Promise<Project[]>}
   */
  async fetchAndStoreProjectsList (sortOptions = {}) {
    // Only toggle the loading
    // state if projects are empty...
    // so we don't override the UI with
    // shimmer skeleton on paginated fetches
    if (this.getProjectsCount() === 0) {
      this.isFetchingProjects = true
    }
    const apiCommunicator = this.apiCommunicator
    try {
      const projects = await apiCommunicator.fetchProjectsList(
        this.getProjectsCount(),
        sortOptions
      )
      projects.forEach(p => {
        // manually remove parts here
        // since that is populated by parts search by project
        p.parts = []
        const asProject = new Project(p)
        this.addProjectsToList(asProject)
      })
    } catch (err) {}

    this.isFetchingProjects = false
    return this.projects
  }

  hasFetchedAllProjects () {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.hasFetchedAllProjects(this.getProjectsCount())
  }

  getProjects () {
    return this.projects || []
  }

  getProjectsCount () {
    return this.getProjects().length || 0
  }

  projectsListIsEmpty () {
    return this.getProjects().length === 0
  }

  getDefaultProject () {
    return this.getProjects()[0]
  }

  /** @type {{string: Project}} */
  get projectsByProjectId () {
    return this.projects.reduce(
      (acc, val) => ({ ...acc, [val.projectId]: val }),
      {}
    )
  }

  getProjectByProjectId (projectId) {
    return this.getProjects().find(p => p.projectId === projectId)
  }

  projectIsAlreadyInList (project) {
    const projectIds = this.projects.map(p => p.projectId)
    return projectIds.includes(project.projectId)
  }

  addProjectsToList (p) {
    const projectIds = this.projects.map(p => p.projectId)
    const isAlreadyInList = projectIds.includes(p.projectId)
    if (p && !isAlreadyInList) {
      this.projects.push(p)
    }
    return this.getProjects()
  }

  /**
   * @param {Project} project
   */
  replaceProjectInList (project) {
    const projects = this.getProjects()
    this.projects = projects.map(p => {
      if (p.projectId === project.projectId) {
        return project
      }
      return p
    })
    return this.getProjects()
  }

  /**
   * @param {Project} projectToUpdate
   */
  updateProjectInList (projectToUpdate) {
    const cachedProject = this.getProjectByProjectId(projectToUpdate.projectId)
    cachedProject?.setWithJSON(projectToUpdate)
    return this.getProjects()
  }

  /**
   * Removes a project from the cached project's list based
   * on provided project id.
   * Returns the new list.
   * @param {string} projectId Id of the project to remove
   * @returns {Project[]} the filtred list
   */
  removeProjectFromList (projectId) {
    if (projectId) {
      this.projects = this.projects.filter(p => p.projectId !== projectId)
    }
    return this.projects
  }

  /** @returns {Project[]} List of projects sorted by name */
  projectsByName (asc = true) {
    return this.projects.sort((a, b) =>
      simpleSort(a.projectName.toLowerCase(), b.projectName.toLowerCase(), asc)
    )
  }

  /** @returns {Project[]} List of projects sorted by recent activity timestamp */
  projectsByRecentActivity (asc = true) {
    return this.projects.sort((a, b) =>
      simpleSort(a.updatedAt, b.updatedAt, asc)
    )
  }

  /** @returns {Project[]} List of projects sorted by createdAt timestamp */
  projectsByCreatedTimestamp (asc = true) {
    return this.projects.sort((a, b) =>
      simpleSort(a.createdAt, b.createdAt, asc)
    )
  }

  /**
   * Returns list of projects returned
   * from api-search relating to given query.
   */
  async searchProjects (query) {
    const searchService = new SearchService()
    searchService.query = query
    let results = await this.searchService.search('/projects/_search')
    results = results.map(p => new Project(p))
    this.searchService.results = results
    this.searchService = searchService
  }

  async unifiedSearch (query) {
    const searchService = new SearchService()
    searchService.query = query
    const results = await searchService.search('/unified/_search')
    searchService.results = results
    console.log('Unified search results', results)
    this.searchService = searchService
  }

  clearSearch () {
    this.searchService = null
  }

  clearProjectsSearch () {
    this.searchService = null
  }

  clearProjects () {
    this.projects = []
    this.projectsCursor = null
  }

  async rename (newName) {
    const apiCommunicator = this.apiCommunicator
    try {
      await apiCommunicator.rename(newName)
      this.orgName = newName
      return this
    } catch (err) {
      logger.error(err)
    }
  }

  static async createAndFormatOrg (orgName) {
    try {
      const res = await OrganizationApiCommunicator.create(orgName)
      const org = new Organization(res)
      return org
    } catch (err) {
      logger.error(err)
    }
  }

  delete () {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.delete(this.orgId)
  }

  getUsers () {
    return this.users
  }

  getUserByEmailHash (emailHash) {
    const users = this.getUsers()
    return users.find(u => u.emailHash === emailHash) || {}
  }

  getAdmins () {
    const allUsers = this.users
    const { ADMIN } = OrgRolesIndex
    const admins = allUsers.filter(user => {
      const userRoles = user.roles || []
      return userRoles.includes(ADMIN)
    })
    return admins
  }

  async updateUserRoles (emailHash, roles) {
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.updateUser(emailHash, roles)
    const user = this.getUserByEmailHash(emailHash)
    user.roles = roles
  }

  async removeUser (emailHash) {
    const apiCommunicator = this.apiCommunicator
    const didRemove = await apiCommunicator.removeUser(emailHash)
    if (didRemove) {
      // filter the user out of the user list
      this.users = this.users.filter(u => u.emailHash !== emailHash)
    }
    return this.users
  }

  async leave () {
    const orgId = this.orgId
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.leave()

    const store = StoreManager.storeInstance

    store.removeOrgFromList(orgId)
    store.checkShouldSwitchOrgOnLeave(orgId)
  }

  /** @returns {Promise<>} */
  inviteUser (email, roles) {
    const apiCommunicator = this.apiCommunicator
    return apiCommunicator.inviteUser(email, roles)
  }

  async makeDefault () {
    const newDefaultOrgId = this.orgId
    const apiCommunicator = this.apiCommunicator
    await apiCommunicator.makeDefault()

    // API returns nothing,
    // instead of refetching all orgs here.
    // Manually update the store
    const store = StoreManager.storeInstance
    const orgList = store.organizationList

    orgList.forEach(org => {
      if (org.orgId === newDefaultOrgId) {
        org.userDefault = true
        store.auth.setCachedDefaultOrg(org)
      } else {
        org.userDefault = false
      }
    })
  }

  async transferOwnershipAndStoreUpdate (emailHash) {
    const apiCommunicator = this.apiCommunicator
    const didUpdate = await apiCommunicator.transferOwnership(emailHash)
    // process the update...
    console.log('Did Update', didUpdate)
  }

  /**
   * The app store's information for restoring a session regarding the org
   * in the browser's local storage.
   * We can clean up what is stored here so that on next refresh/restore,
   * handlers will correctly fetch new data.
   */
  getAsCacheData () {
    return {
      orgId: this.orgId,
      orgName: this.orgName
    }
  }

  processPusherUpdateForProjects (message) {
    const operation = PusherManager.getOperationFromUpdateMessage(message)
    const record = PusherManager.getRecordDataFromUpdateMessage(message)
    const eventMessageImpliesDelete = PusherManager.getEventMessageImpliesDelete(
      message
    )

    const { MODIFY, INSERT } = PusherManager.UPDATE_OPERATIONS

    const newProject = new Project(record)
    const projectId = newProject.projectId

    if (eventMessageImpliesDelete) {
      this.removeProjectFromList(projectId)
    } else if (operation === MODIFY) {
      // NOTE: Project records from pusher messages
      // do NOT have parts in them...so we merge the differences here.
      // Same applies to permissions
      const existingProject = this.getProjectByProjectId(projectId)
      const existingParts = existingProject.parts
      existingParts.forEach(part => {
        newProject.addToParts(part)
      })

      const existingPermissions = existingProject.permissions
      newProject.permissions = existingPermissions

      this.updateProjectInList(newProject)
    } else if (operation === INSERT) {
      this.addProjectsToList(newProject)
    }
  }

  clear () {
    this.clearProjectsSearch()
    this.clearProjects()

    this.apiCommunicator = null
    this.createdAt = null
    this.orgId = ''
    this.orgName = ''
    this.users = []
    this.updatedAt = null
    this.userDefault = null
    this.email = ''
    this.emailHash = ''
    this.isOwner = false
    this.roles = []
  }
}

export default Organization
