<script>
import { computed } from 'vue'
/**
 * The Base Banner component
 */
export default {
  name: 'BaseBanner',
  props: {
    /**
     * Should show banner
     */
    show: {
      type: Boolean,
      required: true
    },
    /**
     * Whether the banner can be dismissed by user input.
     * Shows a "x" icon if true.
     */
    dismissable: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Label for the banner.
     */
    label: {
      type: String,
      required: true
    },
    /**
     * Provide a label for the primary action.
     * Label will be provided to a BaseButton component.
     * If blank, no primary CTA will be shown.
     */
    primaryActionLabel: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Provide a label for the secondary action.
     * Should only be present if there is also a primaryActionLabel.
     * Label will be provided to a BaseButton component.
     */
    secondaryActionLabel: {
      type: String,
      required: false,
      default: ''
    }
  },
  emits: {
    /**
     * Event for (if visisble and provided) the primary CTA action.
     */
    'primary-action': null,
    /**
     * Event for (if visisble and provided) the secondary CTA action.
     */
    'secondary-action': null,
    /**
     * Event to dismiss/close the banner.
     */
    close: null
  },
  setup (props) {
    return {
      showPrimaryCTA: computed(() => props.primaryActionLabel !== ''),
      showSecondaryCTA: computed(() => props.secondaryActionLabel !== '')
    }
  }
}
</script>

<template>
  <div
    v-show="show"
    class="absolute top-0 left-0 z-50 w-full h-16 bg-brand-blue"
  >
    <div class="flex items-center justify-center h-full pr-3 lg:pr-10 space-x-3">
      <div class="flex-1 font-medium text-center text-white">
        <span>{{ label }}</span>
      </div>
      <BaseButton
        @click="$emit('primary-action')"
        v-if="showPrimaryCTA"
        :label="primaryActionLabel"
      />
      <span
        @click="$emit('secondary-action')"
        v-if="showSecondaryCTA"
        class="text-sm text-white cursor-pointer hover:underline font-regular"
      >
        {{ secondaryActionLabel }}
      </span>
      <BaseIcon
        @click="$emit('close')"
        v-if="dismissable"
        icon="x"
        color="white"
      />
    </div>
  </div>
</template>

<style scoped></style>
