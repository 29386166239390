import { home } from './home'
import {
  registration,
  login,
  forgotPassword,
  changePassword,
  inviteRegistration,
  confirmEmail
} from './auth'
import { dashboard } from './dashboard'
import { partInspector } from './part-inspector'
import {
  orgManagement,
  orgManagementSettings,
  orgManagementMembers
} from './org-management'
import {
  userAccount,
  userAccountProfile,
  userAccountOrganizations
} from './user-account'
import { permissionsDenied } from './permissions-denied'

export const routeIndex = {
  // auth
  registration,
  login,
  forgotPassword,
  changePassword,
  confirmEmail,
  // app
  home,
  dashboard,
  partInspector,
  // org
  orgManagementSettings,
  orgManagementMembers,
  // user
  userAccountProfile,
  userAccountOrganizations,
  // misc
  permissionsDenied
}

const AuthRouteChain = {
  name: 'auth',
  path: '/auth',
  component: () => import('../layouts/AuthLayout.vue'),
  children: [
    login,
    registration,
    forgotPassword,
    changePassword,
    inviteRegistration,
    confirmEmail
  ]
}

const AppRouteChain = {
  name: 'app',
  path: '/app',
  component: () => import('../layouts/AuthedRootLayout.vue'),
  children: [
    dashboard,
    partInspector,
    orgManagement,
    userAccount,
    permissionsDenied
  ]
}

/**
 * TODO:
 * - [] 404 Error Page
 * - [] Page Not Found
 */
export const routes = [home, AuthRouteChain, AppRouteChain]
