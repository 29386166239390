import App from './App.vue'
import './main.css'

import { createApp } from 'vue'
import { router } from '@/router'
import { analyticsProvider as Analytics } from '@/analytics'

import Store from '@/store'
import UIStore from '@/uistore'
import Amplify from '@/amplify'
import BaseComponents from '@/components/_base'
import ErrorTracking from '@/services/errorTracking'

import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

createApp(App)
  .use(Amplify)
  .use(BaseComponents)
  .use(ErrorTracking, { router })
  .use(Analytics)
  .use(Store)
  .use(UIStore)
  .use(router)
  .mount('#app')
