/**
 * @typedef {Object} VersionPaths
 * @property {string} combined_pointcloud
 * @property {string} connect_pointcloud
 * @property {string} log
 * @property {string} mesh
 * @property {string} preview_path
 * @property {string} registration_metadata
 * @property {string} surfacesegments
 */

export class VersionDTO {
  createdAt
  createdBy
  modelId
  modelVersionId
  modelVersionStatus
  /** @type {VersionPaths} */
  paths
  processingFinishedAt
  processingStartedAt
  shouldChain
  sourceVersionId
  transformationInstructions
  transformationKey
  transformationProcess
  transforms
  tranformationSettingsOverride
}
