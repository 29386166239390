import { SORT_OPTIONS } from '../constants'

/**
 *
 */
export class Dashboard {
  constructor () {
    const { CREATED_AT } = SORT_OPTIONS

    /**
     * @type {SORT_OPTIONS}
     */
    this.partSortOption = CREATED_AT
    this.partSortAsc = false

    /**
     * @type {SORT_OPTIONS}
     */
    this.projectSortOption = CREATED_AT
    this.projectSortAsc = false
  }

  setPartSortOption (sort) {
    this.partSortOption = sort
  }

  togglePartSortAsc () {
    this.partSortAsc = !this.partSortAsc
  }

  setProjectSortOption (sort) {
    this.projectSortOption = sort
  }

  toggleProjectSortAsc () {
    this.projectSortAsc = !this.projectSortAsc
  }
}
