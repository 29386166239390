import { useAppRouterLocation } from './'
import { getProjectIdFromRoute, getPartIdFromRoute } from '../'
import { computed } from 'vue'

/**
 * Often components need to derive information to inform
 * API calls, internal logic from the current route.
 *
 * This composable is used for the common purpose of retrieving things
 * like project/part ids from the url.
 *
 * @return {{
 *   partIdForView: computed,
 *   projectIdForView: computed
 * }} Object of part / project ids for the current route.
 */
export const useAppRouterParams = () => {
  const { currentRoute } = useAppRouterLocation()

  const partIdForView = computed(() => {
    return getPartIdFromRoute(currentRoute)
  })

  const projectIdForView = computed(() => {
    return getProjectIdFromRoute(currentRoute)
  })

  return {
    partIdForView,
    projectIdForView
  }
}
