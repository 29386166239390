<script>
import * as CommonProps from '../common/props'

export default {
  name: 'BaseList',
  props: {
    /**
     * Show the dividing line between ListItems?
     */
    separator: CommonProps.BOOLEAN
  }
}
</script>

<template>
  <ul
    :class="{
      'divide-y divide-brand-gray-light': separator
    }"
    class="bg-white"
  >
    <slot />
  </ul>
</template>

<style></style>
