import { PermissionsRolesIndex, OrgRolesIndex } from '@/store'
import { ApiNames, Methods, callLambdaApi } from '@/api'
/**
 * Class for storing and reading user permissions against
 * provided objectIds (project, part) and provided orgs.
 *
 * Project, Part permissions are discerned as "object permissions"
 * and org permissions stands alone.
 *
 * @link {https://docs.google.com/spreadsheets/d/1gqraC0rbQcCR3ab-Gx-grjxqZI13Yaf_pL1zgeTJcYY/edit#gid=478665449}
 *
 * Having access to project implies access to child parts at that 'role' level. i.e.
 * can comment on project = can comment on all parts in that project.
 */
export class UserPermissions {
  /**
   * object keyed by project ids with key:value pair
   * for roles
   */
  projects
  parts

  /**
   * Object keyed by org ids with key:value pair
   * @example
   * ```js
   * this.orgs = {
   *  [orgId]: {
   *    roles: ['owner']
   *  }
   * }
   *
   * ```
   */
  orgs

  constructor () {
    this.projects = {}
    this.parts = {}
  }

  async fetchAndStore () {
    const permissions = await this.fetch()
    this.setObjectPermissions(permissions)
  }

  async fetch () {
    const response = await UserPermissions.fetch()
    return response.permissions
  }

  static fetch () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Org,
      path: '/permissions/me'
    })
  }

  setObjectPermissions (permissions = {}) {
    const { projects, parts } = permissions
    this.projects = projects || {}
    this.parts = parts || {}
  }

  /**
   * Permission index formatted as any entry in permissions obj
   * keyed by email hash value: roles
   */
  addToProjectPermissions (permissionIndex = {}) {
    this.projects = { ...this.projects, ...permissionIndex }
  }

  setOrgRolePermissions (roleIndex = {}) {
    this.orgs = roleIndex
  }

  /** @private */
  rolesHasEditAccess (roles) {
    return roles.includes(PermissionsRolesIndex.EDIT)
  }

  /** @private */
  rolesHasCommentAccess (roles) {
    return roles.includes(PermissionsRolesIndex.COMMENT)
  }

  /** @private */
  rolesHasViewAccess (roles) {
    return roles.includes(PermissionsRolesIndex.VIEW)
  }

  /** @private */
  hasEditAccessForObject (objectType, objectId) {
    const roles = this.getRolesForObject(objectType, objectId)
    return this.rolesHasEditAccess(roles)
  }

  /** @private */
  hasComentAccessForObject (objectType, objectId) {
    // identical to view access for for now
    return this.hasViewAccessForObject(objectType, objectId)
  }

  /** @private */
  hasViewAccessForObject (objectType, objectId) {
    const roles = this.getRolesForObject(objectType, objectId)
    return (
      this.rolesHasEditAccess(roles) ||
      this.rolesHasCommentAccess(roles) ||
      this.rolesHasViewAccess(roles)
    )
  }

  /** @private */
  getRolesForObject (objectType, objectId) {
    const objects = this[objectType] || {}
    const keyedPermissions = objects[objectId] || {}
    const roles = keyedPermissions.roles || []
    return roles
  }

  hasEditAccessForProject (projectId) {
    return this.hasEditAccessForObject('projects', projectId)
  }

  hasCommentAccessForProject (projectId) {
    return this.hasComentAccessForObject('projects', projectId)
  }

  hasViewAccessForProject (projectId) {
    return this.hasViewAccessForObject('projects', projectId)
  }

  hasEditAccessForPart (partId) {
    return this.hasEditAccessForObject('parts', partId)
  }

  hasCommentAccessForPart (partId) {
    return this.hasComentAccessForObject('parts', partId)
  }

  hasViewAccessForPart (partId) {
    return this.hasViewAccessForObject('parts', partId)
  }

  // Org roles

  /**
   * Change org settings, invite others to org.
   * Create projects, parts, etc.
   */
  hasManageAccessForOrg (orgId) {
    const roles = this.getRolesForOrg(orgId)
    return (
      roles.includes(OrgRolesIndex.ADMIN) || roles.includes(OrgRolesIndex.OWNER)
    )
  }

  /**
   * Create projects, parts, etc.
   */
  hasEditAccessForOrg (orgId) {
    const roles = this.getRolesForOrg(orgId)
    return (
      roles.includes(OrgRolesIndex.MANAGER) ||
      roles.includes(OrgRolesIndex.ADMIN) ||
      roles.includes(OrgRolesIndex.OWNER)
    )
  }

  getRolesForOrg (orgId) {
    const orgs = this.orgs || {}
    const orgIndex = orgs[orgId]
    return orgIndex?.roles || []
  }

  // TODO:
  // - [ ] Becuase part permissions can be overriden by permissions
  //       to the parent project, add another set of methods here that
  //       check that, perhaps hasCommentAccessForPartOrProject(partId, projectId).
}
