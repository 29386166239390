<script>
import { provide, reactive } from 'vue'

/**
 * @typedef {number[]} ItemLocation
 * [0] === level
 * [1] === indexAtLevel
 */

/**
 *
 */
export default {
  name: 'StatefulChildMenuProvider',
  setup () {
    const getRootLocation = matrix => {
      return matrix[0]
    }

    const getLatestLocation = matrix => {
      return matrix[matrix.length - 1] || []
    }

    const addToMatrix = (matrix, itemLocation) => {
      matrix.push(itemLocation)
    }

    const setAtIndex = (matrix, index, itemLocation) => {
      const newMatrix = matrix.map((m, i) => (i === index ? itemLocation : m))
      return newMatrix
    }

    const matrixContainsLocation = (matrix, itemLocation) => {
      const matrixStr = JSON.stringify(matrix)
      const locStr = JSON.stringify(itemLocation)
      return matrixStr.indexOf(locStr) > -1
    }

    const childMenuAPI = reactive({
      /**
       * @type {ItemLocation[]}
       * 2xn matrix for caching the list of hovered elems
       * in tree
       *
       * @example
       * ```js
       * [[0, 1], [1, 0]]
       * ```
       *
       * Demonstrates the root element is the 1 index in its respective list.
       * It's child (level 1) is the 0th index of its respective list.
       */
      hoveredItemMatrix: [],
      /**
       * @param {ItemLocation} itemLocation
       */
      isInChain: itemLocation => {
        return matrixContainsLocation(
          childMenuAPI.hoveredItemMatrix,
          itemLocation
        )
      },
      /**
       * Updates the hoveredItemMatrix with regards to the
       * given itemLocation
       *
       * @param {ItemLocation} itemLocation
       */
      processHoverRequest: itemLocation => {
        const api = childMenuAPI
        const currentMatrix = api.hoveredItemMatrix
        const root = getRootLocation(currentMatrix)
        const requestLevel = itemLocation[0]
        const latestLevel = getLatestLocation(currentMatrix)[0]

        if (!root) {
          addToMatrix(currentMatrix, itemLocation)
          return
        }

        if (requestLevel === 0) {
          api.hoveredItemMatrix = [itemLocation]
        } else if (requestLevel > latestLevel) {
          addToMatrix(currentMatrix, itemLocation)
        } else if (requestLevel <= latestLevel) {
          api.hoveredItemMatrix = setAtIndex(
            currentMatrix,
            requestLevel,
            itemLocation
          )
        }
      }
    })

    provide('$child-menu', childMenuAPI)
    return {
      childMenuAPI
    }
  }
}
</script>

<template>
  <slot />
</template>

<style scoped></style>
