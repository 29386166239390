import { objectHasProperty } from '@/utils'
/**
 * @typedef {Object} ObjectLimit
 * @property {number} limit
 * @property {number} remaining
 * @property {number} used
 */

/**
 * @typedef {Object} Features
 * @property {boolean} compare
 * @property {boolean} scanner
 * @property {boolean} meshupload
 */

/**
 * Layer for interfacing with organization's
 * "plan", which represents access and limitations for interacting
 * with UWA platform (i.e. part limits, scanner integration, etc.).
 */
export class OrganizationPlan {
  static TIERS = { FREE: 'free', TIER_1: 'tier1' }

  constructor (json = {}) {
    /**
     * @type {?OrganizationPlan.TIERS}
     */
    this.plan = json.plan || json.planTier

    const details = json.details || {}

    /**
     * @type {Features}
     */
    this.features = details.features

    /**
     * @type {{  parts: ObjectLimit, orgUsers: ObjectLimit  }}
     */
    this.limits = details.limits
  }

  getPlanName () {
    return this.plan
  }

  isSubscriber () {
    const plan = this.plan
    return !!plan && plan !== OrganizationPlan.TIERS.FREE
  }

  hasMeshUploadFeature () {
    return this.hasAccessToFeature('meshupload')
  }

  hasCompareFeature () {
    return this.hasAccessToFeature('compare')
  }

  hasScannerFeature () {
    return this.hasAccessToFeature('scanner')
  }

  /**
   * @param {'compare'|'scanner'|'meshupload'} feature
   * @returns {boolean}
   */
  hasAccessToFeature (feature) {
    const features = this.getFeatures()
    return objectHasProperty(features, feature) && !!features[feature]
  }

  /**
   * @returns {Features|{}}
   */
  getFeatures () {
    return this.features || {}
  }

  /**
   * @returns {ObjectLimit}
   */
  getPartsLimits () {
    return this.getObjectLimits('parts')
  }

  /**
   * @private
   * @param {'parts'|'projects'}
   * @returns {ObjectLimit}
   */
  getOrgUsersLimits () {
    return this.getObjectLimits('orgUsers')
  }

  /**
   * @param {'parts'|'orgUsers'}
   * @returns {ObjectLimit}
   */
  getObjectLimits (objectType) {
    return this.getLimits()[objectType] || {}
  }

  getLimits () {
    return this.limits || {}
  }

  /**
   * @returns {number}
   */
  getPartsUsedPercentage () {
    return this.getObjectUsedPercentage('parts')
  }

  /**
   * @param {'parts'|'orgUsers'}
   * @returns {number}
   */
  getObjectUsedPercentage (objectType) {
    const { limit, used } = this.getObjectLimits(objectType)
    return (used / limit) * 100
  }

  /**
   * @returns {boolean}
   */
  hasRemainingOrgUsers () {
    return this.hasRemainingForObject('orgUsers')
  }

  /**
   * @returns {boolean}
   */
  hasRemainingParts () {
    return this.hasRemainingForObject('parts')
  }

  /**
   * @param {'parts'|'orgUsers'}
   * @returns {boolean}
   */
  hasRemainingForObject (objectType) {
    const limits = this.getObjectLimits(objectType)
    return limits.remaining > 0
  }
}
