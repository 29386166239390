export const MIXPANEL_EVENTS_COMMENT = {
  COMMENT_CREATE: 'comment_create',
  COMMENT_EDIT: 'comment_edit',
  COMMENT_DELETE: 'comment_delete',
  COMMENT_SELECT: 'comment_select',
  // Replies
  COMMENT_REPLY_CREATE: 'comment_reply_create',
  COMMENT_REPLY_EDIT: 'comment_reply_edit',
  COMMENT_REPLY_DELETE: 'comment_reply_delete'
}
