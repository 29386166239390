import { loadBlob, getSignedStorageUrl } from '@/api'

/**
 * @typedef {Object} ScanPaths S3 paths for Scan visual data.
 * @property {string} error_log_path
 * @property {string} intensity_preview_path
 * @property {string} log_path
 * @property {string} output_point_cloud PLY used for rendering pointcloud.
 * @property {string} preview_path Renders preview thumbnail of pointcloud.
 * @property {string} texture_preview_path
 */

export class Scan {
  /**
   * @param {Object} json
   */
  constructor (json = {}) {
    this.setWithJSON(json)

    /**
     * @type {?Blob}
     */
    this.pointCloudBlob = null

    /**
     * @type {string} After requesting signed
     * storage url to load the preview thumbnail,
     * cache it on instance.
     */
    this.signedPreviewImage = null
  }

  /**
   * @param {Object} json
   */
  setWithJSON (json = {}) {
    this.createdAt = json.createdAt
    this.createdBy = json.createdBy
    this.modelId = json.modelId

    /**
     * @type {ScanPaths}
     */
    this.paths = json.paths
    this.poseId = json.poseId
    this.processingSubmittedAt = json.processingSubmittedAt
    this.projectId = json.projectId
    this.reconstructFileName = json.reconstructFileName
    this.reconstructFilePath = json.reconstructFilePath
    this.scanId = json.scanId
    this.scanName = json.scanName
    this.scanStatus = json.scanStatus
    this.sourcePaths = json.sourcePaths
    this.updatedAt = json.updatedAt
    this.updatedBy = json.updatedBy
    this.useConstantMotion = json.useConstantMotion
  }

  /**
   * @returns {ScanPaths}
   */
  getPaths () {
    return this.paths || {}
  }

  /**
   * @returns {ScanPaths.output_point_cloud}
   */
  getOutputPointCloudPath () {
    return this.getPaths().output_point_cloud
  }

  /**
   * @returns {ScanPaths.preview_path}
   */
  getPreviewPath () {
    return this.getPaths().preview_path || ''
  }

  async loadIntensityPreviewPath () {
    const unsignedUrl = this.getPaths().intensity_preview_path
    const signedUrl = await getSignedStorageUrl(unsignedUrl)
    this.signedPreviewImage = signedUrl
    return signedUrl
  }

  /**
   * @returns {?Blob}
   */
  async loadPointCloudBlob () {
    const pointCloudPath = this.getOutputPointCloudPath()
    if (pointCloudPath) {
      const blob = await loadBlob(pointCloudPath)
      this.pointCloudBlob = blob
    }
    return this.pointCloudBlob
  }
}
