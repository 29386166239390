import { ref, computed } from 'vue'
import { get } from '@vueuse/core'

/**
 * Creates and returns template ref
 * and computed html dom node el in array
 * [templateRef, domEl]
 * If the templateRef is bound not bound to a vue
 * component then domEl === templateRef.
 */
export const useRefAndEl = () => {
  const nodeRef = ref(null)
  const nodeRefEl = computed(() => {
    const comp = get(nodeRef)
    const isVueComponent = !!comp?.$el
    if (isVueComponent) {
      return comp.$el
    }
    return comp
  })
  return [nodeRef, nodeRefEl]
}
