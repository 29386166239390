/**
 * Mesh viewer's (mesh vue component) camera state.
 */
export default class CameraState {
  constructor (data) {
    this.matrix = data.matrix || []
    this.viewOffset = data.viewOffset || { x: 0, y: 0 }
    this.up = data.up
  }

  get isValid () {
    return !!(this.matrix.length > 0)
  }
}
