import { DeviceApiCommunicator } from '@/api'
import { Device, PusherManager } from './'

export class DeviceManager {
  constructor () {
    this.devices = []
  }

  async listAndStoreDevices () {
    const { data = [] } = await DeviceApiCommunicator.fetchDeviceList()
    this.devices = data.map(deviceData => new Device(deviceData))
    return this.devices
  }

  getDevices () {
    return this.devices
  }

  getOnlineDevices () {
    const devices = this.getDevices()
    const onlineDevices = devices.filter(d => d.isOnline())
    return onlineDevices
  }

  getIdleDevices () {
    const devices = this.getDevices()
    const idleDevices = devices.filter(d => d.isIdle())
    return idleDevices
  }

  getDeviceById (deviceId) {
    return this.getDevices().find(d => d.deviceId === deviceId)
  }

  deviceIsInList (device) {
    const devices = this.getDevices()
    const deviceIds = devices.map(d => d.deviceId)
    return deviceIds.includes(device.deviceId)
  }

  addDeviceToList (newDevice) {
    if (!this.deviceIsInList(newDevice)) {
      this.devices.push(newDevice)
    }
    return this.devices
  }

  updateDeviceInList (updatedDevice) {
    const devices = this.getDevices()
    const updatedList = devices.map(d => {
      if (d.deviceId === updatedDevice.deviceId) {
        return updatedDevice
      }
      return d
    })
    this.devices = updatedList
    return this.devices
  }

  processPusherUpdateForDevices (message) {
    const operation = PusherManager.getOperationFromUpdateMessage(message)
    const record = PusherManager.getRecordDataFromUpdateMessage(message)
    const { MODIFY, INSERT } = PusherManager.UPDATE_OPERATIONS

    const newDevice = new Device(record)
    if (operation === MODIFY) {
      this.updateDeviceInList(newDevice)
    } else if (operation === INSERT) {
      this.addDeviceToList(newDevice)
    }
  }
}
