<script>
import { ref, watch, onBeforeUnmount, nextTick } from 'vue'
import { set } from '@vueuse/core'
import BaseOverlay from '../overlay/BaseOverlay.vue'
import { useWindowEvent } from '@/composables'

/**
 * Base Modal Wrapper. Used for isolated events and messaging.
 */
export default {
  name: 'BaseModal',
  inheritAttrs: false,
  components: {
    BaseOverlay
  },
  props: {
    /**
     * Is the modal in the DOM and visible.
     */
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    /**
     * An optional id for a DOM element in which we can inject the modal.
     * Default behavior is to nest the modal where instance is created.
     * Use "body" for root element in app and "story-root" for testing with storybook.
     * If this is blank. Component is wrapped in div.
     */
    teleport: {
      type: String,
      required: false,
      default: '#app'
    },
    /**
     * Should the modal close when the overlay (background) is clicked.
     */
    closeOnOverlayClick: {
      type: Boolean,
      required: false,
      default: true
    },
    /**
     * Allow the 'esc' keypress to close the modal?
     * @NOTE for this to function properly,
     * the model must have a v-on:close directive
     * so that the event listeners can properly emit the state update.
     * Applies to closeOnOverlayClick prop as well.
     */
    closeWithEsc: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: {
    /**
     * Close modal event.
     */
    close: null,
    /**
     * Event when modal closes. Useful for state clearing callbacks.
     */
    hide: null,
    /**
     * Event when modal becomes visible.
     */
    show: null,
    /**
     * Event when modal content/body is visible.
     */
    'show-body': null,
    /**
     *
     */
    'before-body-hide': null
  },
  setup (props, { emit, attrs }) {
    const checkShouldCloseModal = () => {
      if (props.closeOnOverlayClick) emit('close')
    }
    const showBody = ref(false)
    const showModal = ref(false)

    // We fudge the modal state's internal visibility here for
    // additive fade in / out animations.
    // The 'show' prop informs the local showBody and showModal states
    // when it becomes true, show the modal (inset, opaque backdrop)
    // wait for the DOM to render it, then toggle the show local body state
    // to allow its <transtion> to animate the body in.
    // When show is false, set the show body to false so it gets animated away
    // then unset the modal as a whole when that animation completes.
    watch(
      () => props.show,
      async (isShowing, wasShowing) => {
        if (isShowing) {
          // component instance should be in DOM
          // show the backdrop attach listeners and emit events.
          set(showModal, true)
          emit('show')
          if (props.closeWithEsc) {
            attachWindowEvents()
          }
          // wait for it to render
          await nextTick()
          // then toggle the body to true so it animates properly
          set(showBody, true)
        } else {
          emit('hide')
          detachWindowEvents()
          // animate the body into hidden state
          // catch the completion of its animation in a callback
          // that removes the modal as a whole from the DOM
          set(showBody, false)
        }
      }
    )

    const { attachWindowEvents, detachWindowEvents } = useWindowEvent({
      keyup: { Escape: () => emit('close') }
    })

    onBeforeUnmount(detachWindowEvents)

    return {
      checkShouldCloseModal,
      showModal,
      showBody,
      onLeave: () => {
        set(showModal, false)
      }
    }
  }
}
</script>

<template>
  <teleport :to="teleport" v-if="showModal">
    <div
      class="fixed inset-0 z-20 overflow-y-auto"
      :class="!show ? 'pointer-events-none' : ''"
    >
      <div
        class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <transition
          @after-leave="onLeave"
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100"
          leave-to-class="opacity-100"
        >
          <BaseOverlay v-show="show" @click="checkShouldCloseModal" />
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <!-- Modal panel, show/hide based on modal state. -->
        <transition
          @after-enter="$emit('show-body')"
          @before-leave="$emit('before-body-hide')"
          enter-active-class="ease-out duration-300"
          enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-from-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            v-show="showBody"
            class="inline-block px-4 pt-5 pb-4 overflow-visiable text-left align-bottom bg-white rounded-lg min-w-max shadow-x transform transition-all sm:my-8 sm:align-middle sm:p-6"
            v-bind="$attrs"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <!--
            @slot Slot content
            -->
            <slot />
          </div>
        </transition>
      </div>
    </div>
  </teleport>
</template>

<style scoped>
.modal-body {
  max-width: 90vw;
}
</style>
