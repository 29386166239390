<script>
import { computed } from 'vue'
import { get } from '@vueuse/core'
import * as CommonProps from '@/components/_base/common/props'

/**
 * The base class for a button. A button is an interactable dom element
 * that fires a click event.
 * @displayName BaseButton
 *
 * TODO:
 * - [ ] Separate out rank from "intent" prop
 *       to allow stylistic variation i.e. danger-primary,
 *       success-secondary
 */
export default {
  name: 'BaseButton',
  inheritAttrs: false,
  props: {
    /**
     * Show the button as a <span> element
     */
    isSpan: CommonProps.BOOLEAN,
    /**
     * Determines whether or not width of button should extend to full
     * width of parent.
     * @type {boolean}
     */
    block: CommonProps.BOOLEAN,
    /**
     * Determines styles and whether or not button is interactable.
     */
    disabled: CommonProps.BOOLEAN,
    /**
     *
     */
    type: { ...CommonProps.STRING, default: 'button' },
    /**
     * Button's label text.
     * @type {string}
     */
    label: CommonProps.STRING,
    /**
     * The button rank and type.
     * @type {string}
     * @values primary, secondary
     */
    rank: {
      ...CommonProps.STRING,
      default: 'secondary',
      validator: function (value) {
        return (
          [
            'primary',
            'secondary',
            'danger',
            'success',
            'secondary-danger'
          ].indexOf(value) !== -1
        )
      }
    },
    /**
     *
     */
    size: CommonProps.SIZE_STRING,
    /**
     *
     */
    icon: CommonProps.ICON,
    /**
     *
     */
    trailingIcon: CommonProps.ICON
  },
  setup (props, { attrs }) {
    const hasIcon = computed(() => {
      const leadingIcon = props.icon
      const trailingIcon = props.trailingIcon
      return leadingIcon || trailingIcon
    })

    const ButtonClassContainer = computed(() => ({
      // Background Classes
      'bg-brand-blue': props.rank === 'primary',
      'bg-white':
        props.rank === 'secondary' || props.rank === 'secondary-danger',
      'bg-brand-red': props.rank === 'danger' && !attrs.class?.includes('bg'),
      'bg-brand-green': props.rank === 'success',
      // Text color
      'text-white':
        props.rank !== 'secondary' && props.rank !== 'secondary-danger',
      'text-brand-red': props.rank === 'secondary-danger',
      // Border Classes
      'border-transparent': props.rank === 'primary' || props.rank === 'danger',
      'border-brand-gray-light': props.rank === 'secondary',
      'border-brand-red': props.rank === 'secondary-danger',
      // Size Classes
      'py-0.5 px-4': props.size === 'small',
      'py-1 px-4': props.size === 'medium',
      'py-2 px-4': props.size === 'large',
      // Block sizing
      'w-full': props.block,
      'max-w-max': !props.block,
      // Content Justification for flex items
      'justify-between': get(hasIcon),
      'justify-center': !get(hasIcon),
      // Border radius:
      'rounded-sm': props.block,
      'rounded-md': !props.block,
      // Hover States (Apply for specific colors when button is not disabled)
      'hover:bg-brand-blue-dark': !props.disabled && props.rank === 'primary',
      'hover:bg-brand-gray-light':
        !props.disabled && props.rank === 'secondary',
      'hover:bg-brand-red-dark': !props.disabled && props.rank === 'danger',
      'hover:bg-brand-green-dark': !props.disabled && props.rank === 'success',
      // Disabled states
      'disabled:opacity-50 disabled:cursor-not-allowed': props.disabled,
      // Active states
      'active:ring-brand-blue active:bg-brand-blue-darker':
        props.rank === 'primary',
      'active:ring-brand-gray active:bg-brand-gray-dark':
        props.rank === 'secondary',
      'active:ring-brand-red':
        props.rank === 'danger' || props.rank === 'secondary-danger',
      'active:ring-brand-green': props.rank === 'success'
    }))

    const iconSpanColor = () => {
      if (props.rank === 'primary') {
        return 'black'
      }
      if (props.rank === 'danger' || props.rank === 'secondary-danger') {
        return 'red'
      }
      return 'gray'
    }

    const iconBtnColor = () => {
      let color = 'gray'
      if (
        props.rank === 'primary' ||
        props.rank === 'danger' ||
        props.rank === 'success'
      ) {
        color = 'white'
      } else if (props.rank === 'secondary') {
        color = 'black'
      } else if (props.rank === 'secondary-danger') {
        color = 'red'
      }
      return color
    }

    const IconColor = computed(() => {
      if (props.isSpan) {
        return iconSpanColor()
      }
      return iconBtnColor()
    })

    const typeFromSize = computed(() => {
      const size = props.size
      if (size === 'small') {
        return 'caption'
      } else if (size === 'large') {
        return 'title'
      }
      return 'body'
    })

    return {
      ButtonClassContainer,
      IconColor,
      typeFromSize
    }
  }
}
</script>

<template>
  <div
    v-bind="$attrs"
    v-if="isSpan"
    class="flex items-center cursor-pointer space-x-2"
  >
    <BaseIcon v-if="icon" :icon="icon" :color="IconColor" :size="12" />
    <base-span
      :rank="rank"
      :type="typeFromSize"
      :class="!disabled ? 'cursor-pointer hover:underline' : ''"
    >
      {{ label }}
    </base-span>
    <BaseIcon
      v-if="trailingIcon"
      :icon="trailingIcon"
      :color="IconColor"
      :size="12"
    />
  </div>

  <button
    v-else
    v-bind="$attrs"
    :type="type"
    :disabled="disabled"
    :class="ButtonClassContainer"
    class="relative inline-flex items-center m-0 border active:outline-none active:ring-2 active:ring-offset-2 transition duration-75 space-x-2"
  >
    <BaseIcon v-if="icon" :icon="icon" :color="IconColor" :size="12" />

    <span v-if="label" class="text-sm font-regular">
      {{ label }}
    </span>
    <slot name="label" />
    <BaseIcon
      v-if="trailingIcon"
      :icon="trailingIcon"
      :color="IconColor"
      :size="12"
    />
  </button>
</template>

<style scoped></style>
