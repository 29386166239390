import { ref } from 'vue'

/**
 * Returns toggle-able boolean for reusable state logic.
 * Flexible as its return values can be aliased once called in their
 * destructor.
 *
 * @returns{{
 *   bool: boolean,
 *   toggleBool: (override: ?boolean) => void
 * }}
 *
 * toggleBool func can be fed (true|false) to override the toggle.
 * Otheriwse it simplyh flips the booleans current state.
 *
 * @example
 * ```js
 *  // alias for specificity
 *  const { bool: isLoadingProjects, toggleBool: toggleIsLoadingProjects  } = useBooleanToggle()
 * ```
 */
const useBooleanToggle = () => {
  const bool = ref(false)

  const toggleBool = (override = null) => {
    const existingVal = bool.value
    bool.value =
      override !== null && typeof override === 'boolean'
        ? override
        : !existingVal
  }

  return {
    bool,
    toggleBool
  }
}

export default useBooleanToggle
