<script>
import { ref } from 'vue'
/**
* Base text area component.
* Used for longer forms of user input.
 */
export default {
  name: 'BaseTextArea',
  emits: {
    /**
     * Escape has been pressed.
     */
    esc: null,
    /**
     * Update the textarea's value with this event.
     * If v-model is not used (such as when the modelValue is a prop on the component instance),
     * component instance must have @update:modelValue="event" directive.
     */
    'update:modelValue': null
  },
  props: {
    /**
     * The input's value. Assumed with v-model usage.
     */
    modelValue: {
      type: String,
      required: false,
      default: ''
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup () {
    const textAreaRef = ref(null)

    return { textAreaRef }
  }
}
</script>

<template>
  <textarea
    @input="$emit('update:modelValue', $event.target.value)"
    @keydown.esc.prevent="$emit('esc')"
    :value="modelValue"
    :placeholder="placeholder"
    class="w-full px-3 py-2 resize-none placeholder-brand-gray-dark border-brand-gray-light focus:ring-brand-blue-light focus:border-brand-blue-light rounded-md"
    ref="textAreaRef"
  />

</template>

<style scoped></style>
