<script>
/**
 * The root of the App.
 */
export default {
  name: 'App'
}
</script>

<template>
  <!--
    To treat each router redirect as distinct
    use :key="$route.fullPath"
    To recreate components
   -->
  <RouterView />
  <div id="teleport" />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100vw;
}
</style>
