import { StoreManager } from '@/store'
import { dashboard } from './dashboard'

const navbar = () =>
  import(/* webpackChunkName: 'navbar' */ '@/components/shared/TheNavbar.vue')

export const orgManagementSettings = {
  name: 'org-management-settings',
  path: 'settings',
  component: () =>
    import(
      /* webpackChunkName: 'org-management-settings' */ '../views/OrgSettings.vue'
    )
}

export const orgManagementMembers = {
  name: 'org-management-members',
  path: 'members',
  component: () =>
    import(
      /* webpackChunkName: 'org-management-members' */ '../views/OrgMembers.vue'
    )
}

export const orgManagement = {
  name: 'org-management',
  path: 'org/:orgId/management',
  components: {
    default: () =>
      import(
        /* webpackChunkName: 'org-management' */ '../layouts/Settings.vue'
      ),
    leftSidebar: () =>
      import('@/components/org-management/OrgManagementLeftSidebar.vue'),
    navbar
  },
  meta: { requiresAuth: true },
  beforeEnter: to => {
    // TODO:
    // - [] Move this out
    // - [] Create a OrgManagement view that extends settings layout
    //      and add this to the before route enter hook.
    const orgId = to.params.orgId
    const store = StoreManager.storeInstance
    const org = store.getOrgById(orgId)

    const currentUser = store.getCurrentUser()
    const userPermissions = currentUser?.getPermissions()
    const hasManageAccessForOrg = userPermissions?.hasManageAccessForOrg(
      org?.orgId
    )

    if (org?.isOwner || hasManageAccessForOrg) {
      // null return means proceed normally to requested route.
      return null
    }
    console.warn('Invalid access level')
    return dashboard
  },
  children: [orgManagementMembers, orgManagementSettings]
}
