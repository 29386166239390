import { StoreManager } from '@/store'
import { get } from '@vueuse/core'
import {
  shouldRedirectRoute,
  getPartIdFromRoute,
  getProjectIdFromRoute
} from './'
import { routeIndex } from '../routes'
import { trackEvent } from '@/analytics'
import {
  MIXPANEL_EVENTS_USER,
  MIXPANEL_EVENTS_PROJECT,
  MIXPANEL_EVENTS_PERMISSIONS_DENIED,
  MIXPANEL_EVENTS_PART,
  MIXPANEL_EVENTS_ORGANIZATION
} from '@/analytics/events'

const {
  dashboard,
  partInspector,
  orgManagementSettings,
  orgManagementMembers,
  permissionsDenied,
  userAccountProfile,
  userAccountOrganizations
} = routeIndex

export const trackRoute = route => {
  const to = Object.keys(route).reduce(
    (acc, k) => ({
      ...acc,
      [k]: get(route[k])
    }),
    {}
  )
  if (!shouldRedirectRoute(to)) {
    const routeName = to.name
    const store = StoreManager.storeInstance
    const org = store.getActiveOrganization()
    const orgId = org?.orgId || ''
    const orgName = org?.orgName

    const projectId = getProjectIdFromRoute(to)
    const project = org?.getProjectByProjectId(projectId)
    const projectName = project?.projectName

    const partId = getPartIdFromRoute(to)
    const part = project?.getPartById(partId)
    const partName = part?.partName

    switch (routeName) {
      case dashboard.name: {
        trackEvent(MIXPANEL_EVENTS_PROJECT.PROJECT_OPEN, {
          ...(orgId && { orgId }),
          ...(orgName && { orgName }),
          ...(projectId && { projectId }),
          ...(projectName && { projectName })
        })
        break
      }
      case partInspector.name: {
        trackEvent(MIXPANEL_EVENTS_PART.PART_OPEN, {
          ...(orgId && { orgId }),
          ...(orgName && { orgName }),
          ...(projectId && { projectId }),
          ...(projectName && { projectName }),
          ...(partId && { partId }),
          ...(partName && { partName })
        })
        break
      }
      case orgManagementSettings.name: {
        trackEvent(MIXPANEL_EVENTS_ORGANIZATION.ORG_SETTINGS_VIEWED, {
          ...(orgId && { orgId }),
          ...(orgName && { orgName })
        })
        break
      }
      case orgManagementMembers.name: {
        trackEvent(MIXPANEL_EVENTS_ORGANIZATION.ORG_MEMBERS_VIEWED, {
          ...(orgId && { orgId }),
          ...(orgName && { orgName })
        })
        break
      }
      case permissionsDenied.name: {
        trackEvent(
          MIXPANEL_EVENTS_PERMISSIONS_DENIED.PERMISSIONS_DENIED_VIEW_OPEN
        )
        break
      }
      case userAccountProfile.name: {
        trackEvent(MIXPANEL_EVENTS_USER.USER_PROFILE_VIEWED, {
          ...(orgId && { orgId }),
          ...(orgName && { orgName })
        })
        break
      }
      case userAccountOrganizations.name: {
        trackEvent(MIXPANEL_EVENTS_USER.USER_ORGS_VIEWED, {
          ...(orgId && { orgId }),
          ...(orgName && { orgName })
        })
        break
      }
      default:
        break
    }
  }
}
