import { ApiNames, Methods, callLambdaApi } from '@/api'
import { Logger } from '@aws-amplify/core'

const logger = new Logger('SearchService')
/**
 * Class used to handle searching.
 * Attached and scoped as instance to other models
 * and used for common API functionality and scoping of search centric
 * properties (i.e. paging, caches)
 */
class SearchService {
  /**
   * From where in the page, listed results do we begin the search query.
   * Defaults to 0.
   * @type {number}
   */
  from

  /**
   * How big (number of results) is the query?
   * Defaults to 20.
   * @type {number}
   */
  size

  /**
   *
   */
  query = ''

  /**
   * A list of cached results for this SearchService instance.
   * Currently this gets set by the class employing this service so that
   * results can be formatted before caching.
   * @type {Project[]|Part[]}
   */
  results = []

  constructor (from, size, query) {
    this.from = from || 0
    this.size = size || 20
    this.query = query || ''
  }

  /**
   * As search API currently scopes by org (even if searching parts)
   * provide getter to filter cached results by some project Id,
   * so that Project instances can scope their own "results".
   *
   * @param {!string} projectId Id of the project to filter results by.
   * @returns {Part[]} List of parts filtered by given projectId
   *
   */
  resultsByProjectId (projectId) {
    return this.results.filter(r => r.projectId === projectId)
  }

  /**
   * Instanced call to search, uses member
   * variables for pagination and sets
   * based on result.
   */
  async search (endpoint) {
    const query = this.query
    const from = this.from
    const size = this.size
    return SearchService.search(endpoint, query, from, size)
  }

  /**
   * @deprecated
   */
  static buildQueryString (endpoint, query, from, size, projectId) {
    let baseQuery = `${endpoint}/?term=${query}&from=${from}&size=${size}`
    baseQuery += projectId ? `&projectId=${projectId}` : ''
    return encodeURI(baseQuery)
  }

  /**
   * Updated version of
   * `buildQueryString` with updated args for clarity
   * @param {{
   * endpoint: string,
   * query: string,
   * from: number,
   * size: number,
   * projectId: ?string,
   * sortField: '',
   * sortAsc: boolean
   * }}
   * @returns {string}
   */
  static buildQueryStringURL ({
    endpoint,
    query = '',
    from = 0,
    size = 50,
    projectId = null,
    sortField = '',
    sortAsc = false
  }) {
    let baseQuery = `${endpoint}/?term=${query}&from=${from}&size=${size}`
    //  Ignore the search in here for now...
    // baseQuery += `&sortField=${sortField}`
    // baseQuery += `&sortOrder=${sortAsc ? 'asc' : 'desc'}`
    baseQuery += projectId ? `&projectId=${projectId}` : ''
    return encodeURI(baseQuery)
  }

  static formatSearchResultsAsList (res = {}) {
    const { items = [] } = res
    const list = items.map(i => i._source)
    return list
  }

  /**
   * Generic search method that calls search api against given endpoint
   * with given query.
   *
   * @param {!string} endpoint The endpoint for the search, does not include
   * the size, paging information as query string parameters.
   * That full endpoint is formed within this funciton.
   * @param {!string} query The non-null and non-empty string to query for.
   */
  static async search (endpoint, query, from, size) {
    try {
      // Build endpoint with query and instance
      // size, page begin
      const path = SearchService.buildQueryString(endpoint, query, from, size)
      const response = await callLambdaApi({
        method: Methods.GET,
        apiName: ApiNames.Search,
        path
      })
      const items = response.items || []
      return items.map(r => r._source)
    } catch (err) {
      logger.error('search(endpoint, query) ERROR:', err)
    }
  }
}

export default SearchService
