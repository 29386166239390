/**
 * the structure loosely matches cadcom KPIS spreadsheet
 * https://docs.google.com/spreadsheets/d/1bg1h-UnI-A3aV1XXDScKYlrQSS9FFV0VnVGxpfofxAI/edit#gid=0
 */
export const MIXPANEL_EVENTS_MESH_VIEWER = {
  REFERENCE_MEASUREMENT_CREATED: 'reference_measurement_created',
  SCAN_MEASUREMENT_CREATED: 'scan_measurement_created',
  COMPARE_VIEW_OPENED: 'compare_view_opened',
  GO_NO_GO_VIEW_OPENED: 'go_no_go_view_opened',
  RESCALING_FACTORS_COPIED: 'rescaling_factors_copied',
  CSV_EXPORTED: 'csv_exported',
  GRID_ENABLED: 'grid_enabled',
  BOUNDING_BOX_ENABLED: 'bounding_box_enabled',
  BOUNDING_CYLINDER_ENABLED: 'bounding_cylinder_enabled',
  RAW_SCAN_MESH_DOWNLOAD: 'raw_scan_mesh_download',
  HIGH_RES_SCAN_MESH_DOWNLOAD: 'high_res_scan_mesh_download',
  MODEL_WAM_DOWNLOAD: 'download_model_wam'
}
