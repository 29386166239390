import VueStore from 'vue-class-store'
import { provide, inject } from 'vue'
import Store from './store'

/**
 * Key for the store inject / hide
 */
const StoreSymbol = Symbol('$store')

/**
 * Creates a VueStore instance from Store class
 * @return {Store} store
 */
const createStore = () => {
  const store = VueStore.create(new Store())
  StoreManager.storeInstance = store
  // Allow for debugging in development
  if (process.env.NODE_ENV === 'development') {
    window.store = store
  }

  return store
}

/**
 * Provides store instance in the app root vue component
 * @returns {Store} store
 */
const provideStore = () => {
  const store = createStore()
  provide(StoreSymbol, store)
  return store
}

/**
 * Inject the store into a vue component
 * @returns {Store} store
 */
const useStore = () => {
  const store = inject(StoreSymbol)
  // TODO: make vue.readyonly?
  return store
}

/**
 * Manager class to abstract instance of store
 */
class StoreManager {
  /**
   * Instance of create vue class store, so that child classes can
   * affect reactive store properties
   * @type {Store}
   */
  static storeInstance
}

export {
  createStore,
  provideStore,
  useStore,
  StoreSymbol,
  StoreManager
}
export * from './constants'
export * from './utils'

export default {
  /**
   * @param {import('vue').App} app
   */
  install: app => {
    const store = createStore()
    app.provide(StoreSymbol, store)
  }
}
