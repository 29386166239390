import { defaultColorMap } from '@GrabCAD/mesh-vue-component'
import {
  Annotation,
  MeshVueComponentViewerOptions,
  CameraState,
  Histogram,
  GoNoGoResults
} from './'
import { toRefs, reactive, computed } from 'vue'
import { reactivePick } from '@vueuse/core'
import { COMMENT_TYPE } from '@/apollo'
import { compareUnitIndex } from '@/store'

const MeshViewerPropKeys = [
  'segments',
  'shouldHideNonSelected',
  'compareResultsRange'
]

/**
 * State container for PartInspectorMeshViewer.vue
 * Also provides methods to create reactive props
 * from members for Mesh Vue Component.
 * TODO:
 *  - [ ] Move Mesh Vue Component events out of this somehow...
 *  - [ ] Wrap in PartInspector state class
 *
 * The plan is to migrate UI states to relevant classes
 * and pull in what is needed here for MVC
 * this class ideally, will just be for itneracting with mesh vue component
 * across the store.
 */
export default class MeshViewer {
  constructor () {
    /**
     * @type {GoNoGoResults}
     */
    this.goNoGoResults = null
    this.displayUnit = null
    this.geometryDisplacement = null
    /**
     * @type {CameraState}
     */
    this.cameraState = null
    this.activeVertexIndex = -1
    this.isLoading = false
    this.isShowingModelMesh = false
    this.isShowingReferenceCAD = false
    this.isShowingCompare = false
    this.isShowingGoNoGo = false
    this.activePartId = null
    this.activeModelId = null
    /**
     * @type {Annotation}
     */
    this.pendingAnnotation = null
    this.compareColorMapNamePreference = ''
    this.compareResultsRange = 0.0
    this.segments = []
    this.selectedSegments = []
    /**
     * @type {Histogram}
     */
    this.displacementHistogram = null

    this.setDefaults()
    this.pointMeasurements = { vertices: [] }
    /** @type {MeshVueComponentViewerOptions} */
    this.meshVueComponentViewerOptions = new MeshVueComponentViewerOptions()
    this.printAccuracyScore = 0.0
  }

  onUpdatePrintAccuracyScore (data) {
    const score = data.printAccuracyScore
    // clip floating paints for display.
    this.printAccuracyScore = parseInt(score)
  }

  onUpdateHistogram (data) {
    const histogram = data.histogram
    this.setDisplacementHistogram(histogram)
  }

  onCalcGoNoGo (payload) {
    this.setGoNoGoResults(payload)
  }

  onUpdatePointMeasurement (payload) {}

  onLoadBlobs (meshScene) {
    const geometry = meshScene.getComparisonResultsGeometry()
    if (geometry) {
      const displacementList = this.getDisplacementArrayFromGeometry(geometry)
      const positionList = this.getPositionArrayFromGeometry(geometry)
      this.setGeometryDisplacement(displacementList)
      this.setGeometryPosition(positionList)
    }
  }

  onSelectVertex (payload = {}) {
    const meshVueComponent = this.meshVueComponentViewerOptions
    const {
      cameraState,
      vertexPosition,
      vertexIndex,
      mouseX,
      mouseY,
      object = {}
    } = payload
    const geometry = object.geometry
    let objectId, commentType

    if (this.isShowingModelMesh || this.isShowingCompare) {
      objectId = this.activeModelId
      commentType = COMMENT_TYPE.MODEL
    } else if (this.isShowingReferenceCAD) {
      objectId = this.activePartId
      commentType = COMMENT_TYPE.REFERENCE_CAD
    }

    if (meshVueComponent.isInCommentContext()) {
      const vertex = { position: vertexPosition, index: vertexIndex }
      this.setupPendingAnnotation({
        objectId,
        commentType,
        cameraState,
        selectedVertices: [vertex],
        positionX: mouseX,
        positionY: mouseY
      })
    }

    const displacements = this.getDisplacementArrayFromGeometry(geometry)
    const positions =
      this.geometryPosition || this.getPositionArrayFromGeometry(geometry)
    if (
      this.isShowingCompare &&
      displacements &&
      positions &&
      meshVueComponent.isInSelectMode()
    ) {
      const vertex = vertexIndex
      const xIndex = vertex * 3
      const x = positions[xIndex]
      const y = positions[xIndex + 1]
      const z = positions[xIndex + 2]
      const displacement = displacements[vertex]
      this.setSelectedDisplacementInfo({
        vertex,
        x,
        y,
        z,
        displacement
      })
    }

    if (meshVueComponent.isInMeasureMode()) {
      this.addPointMeasurementVertex({
        index: vertexIndex,
        position: vertexPosition
      })
      const pointMeasurementVertices = this.getPointMeasurementVertices()
      if (pointMeasurementVertices.length === 2) {
        // consider this the end of a p2p measurement
        // initiate comment
        this.setupPendingAnnotation({
          objectId,
          commentType,
          cameraState,
          selectedVertices: pointMeasurementVertices,
          positionX: mouseX,
          positionY: mouseY
        })
      }
    }
  }

  setSelectedDisplacementInfo ({ vertex, x, y, z, displacement }) {
    this.selectedDisplacementInfo = { vertex, x, y, z, displacement }
  }

  getSelectedDisplacementInfo () {
    return this.selectedDisplacementInfo || null
  }

  getPositionArrayFromGeometry (geometry) {
    const geometryAttrs = geometry.attributes || {}
    const position = geometryAttrs.position || {}
    const positionList = position.array || []
    return positionList
  }

  getDisplacementArrayFromGeometry (geometry = {}) {
    const geometryAttrs = geometry.attributes || {}
    const displacement = geometryAttrs.displacement || {}
    const displacementList = displacement.array || []
    return displacementList
  }

  setGoNoGoResults (payload) {
    const goNoGoParams = payload
    let updatedResults = this.goNoGoResults
    if (updatedResults) {
      updatedResults.setWithObject(goNoGoParams)
      updatedResults.updateToleranceUnits(this.displayUnit)
    } else {
      updatedResults = new GoNoGoResults(goNoGoParams, this.displayUnit)
    }

    this.goNoGoResults = updatedResults
    return this.goNoGoResults
  }

  setGeometryDisplacement (displacement) {
    this.geometryDisplacement = displacement
  }

  setGeometryPosition (position) {
    this.geometryPosition = position
  }

  setDisplacementHistogram (data = null) {
    if (data) {
      this.displacementHistogram = new Histogram(data)
    } else {
      this.displacementHistogram = null
    }
  }

  setCompareResultsRange (range) {
    this.compareResultsRange = range
  }

  // TODO:
  // - [] This doesn't belong in this class
  //      can be an external utility or even
  //      scoped to the component.
  createReactiveMeshVueComponentProps () {
    const meshVueComponentViewerOptions = reactivePick(
      this.meshVueComponentViewerOptions,
      ...Object.keys(this.meshVueComponentViewerOptions)
    )
    const miscOptions = reactivePick(this, ...MeshViewerPropKeys)

    const props = reactive({
      ...toRefs(meshVueComponentViewerOptions),
      ...toRefs(miscOptions),
      tolerance: computed(() => {
        const goNoGo = this.goNoGoResults
        let tolerance = goNoGo?.tolerance?.value
        if (tolerance === undefined) {
          tolerance = compareUnitIndex[this.displayUnit]?.passFail.default
        }
        return tolerance
      })
    })
    return props
  }

  getPointMeasurementVertices () {
    return this.pointMeasurements.vertices || []
  }

  /**
   * @returns {bool} Whether or not the given vertices match
   * the currently active ones on the uistore.
   * this.pointMeasurement.vertices === otherVertices
   */
  pointMeasurementVerticesMatch (otherVertices) {
    const activeVertices = this.getPointMeasurementVertices()
    if (!activeVertices || activeVertices.length === 0) {
      return false
    }
    const activeVertexIndexList = activeVertices.map((a) => a.index)
    activeVertexIndexList.sort((a, b) => a - b)

    const otherVertexIndexList = otherVertices.map((a) => a.index)
    otherVertexIndexList.sort((a, b) => a - b)

    return activeVertexIndexList.join('') === otherVertexIndexList.join('')
  }

  addPointMeasurementVertex (vertex) {
    this.pointMeasurements.vertices.push(vertex)
  }

  setPointMeasurementVertices (vertices) {
    // clean the keys to always match
    const cleanedVertices = vertices.map((vertex) => {
      return {
        index: vertex.vertexIndex || vertex.index,
        position: vertex.vertexPosition || vertex.position
      }
    })
    this.pointMeasurements.vertices = cleanedVertices
  }

  clearPointMeasurement () {
    this.setPointMeasurementVertices([])
  }

  /**
   * This should not be set.
   * It is a computed property.
   * If this is not an empty string when fed to mvc
   * the compare view will be shown.
   * So, guard it's return with a local boolean.
   * Ideally, MVC will be updated to some boolean so it
   * isn't depdendent on a color map name for simple visibility.
   *
   * To set the compareColorMapName, use compareColorMapNamePreference
   * to store user choice for color map.
   */
  getCompareColorMapName () {
    if (this.isShowingCompare) return this.compareColorMapNamePreference
    return ''
  }

  setCompareColorMapPreference (colorMap) {
    this.compareColorMapNamePreference = colorMap
  }

  setupPendingAnnotation (data) {
    this.pendingAnnotation = new Annotation(data)
  }

  clearPendingAnnotation () {
    this.pendingAnnotation = null
  }

  setCameraState (newCamState) {
    this.cameraState = new CameraState(newCamState)
  }

  clearCamState () {
    this.cameraState = null
  }

  setActiveVertexIndex (newActiveVertex) {
    this.activeVertexIndex = newActiveVertex
  }

  setShowCompare (show) {
    this.isShowingCompare = show
    if (!show) {
      this.setDisplacementHistogram(null)
    }
  }

  // isShowingCompare must be true
  // for GoNoGo to display
  setShowGoNoGo (show) {
    const meshVueComponent = this.meshVueComponentViewerOptions
    meshVueComponent.toggleOption('showGoNoGo', show)
  }

  showReferenceCAD () {
    this.isShowingReferenceCAD = true
    this.isShowingModelMesh = false
    this.activeModelId = null
    // Disable any compare options
    this.isShowingCompare = false
    this.setShowGoNoGo(false)
  }

  setActiveModelId (modelId) {
    this.activeModelId = modelId
    return this
  }

  setActivePartId (partId) {
    this.activePartId = partId
    return this
  }

  /**
   * By default, we load the 'versioned mesh' when the component is
   * initialized. User must navigate UI to see comparison version.
   * Thus we only prepare and cache the comparison blob (if available)
   * for when/if that interaction occurs.
   *
   * @param {import('../../store/models').Model} model
   */
  prepareViewForCompareMesh (model) {
    if (model.hasComparisonMesh) {
      model.loadMeshCompareBlobs()
    }
  }

  setupViewForScanMesh (modelId) {
    this.activeModelId = modelId
    this.isShowingModelMesh = true
    this.isShowingReferenceCAD = false
  }

  /** @param {import('../../store/models').Version} version */
  setupViewForVersionMesh (version) {
    this.isShowingModelMesh = true
    this.isShowingReferenceCAD = false
    this.activeModelId = version.modelId
  }

  setupViewForReferenceCAD () {
    this.showReferenceCAD()
  }

  setDefaults () {
    this.isShowingGoNoGo = false
    this.isShowingCompare = false
    this.isShowingModelMesh = false
    this.isShowingReferenceCAD = false
    this.isLoading = false
  }

  setViewOptionsFromOrgSettings (viewPreferences = {}, orgSettings = {}) {
    const compareColorMapName =
      viewPreferences.compareColorMapName || defaultColorMap
    const units = orgSettings?.getGeneral()?.displayUnits || 'µm'
    // set things like units/displacement color map preference
    // if there is a preference from the org
    this.compareColorMapNamePreference = compareColorMapName
    this.setDisplayUnit(units)
    this.compareResultsRange =
      compareUnitIndex[units]?.displacement.default || 0.5
  }

  setDisplayUnit (units) {
    this.displayUnit = units
  }

  clear () {
    this.setDefaults()
    this.meshVueComponentViewerOptions.setDefaults()
    this.clearCamState()
    this.clearPendingAnnotation()
    this.clearPointMeasurement()

    this.activeModelId = null
  }
}
