import { trackEvent } from './'
import { Logger } from '@aws-amplify/core'

const logger = new Logger('@/analytics/utils/trackEventWithCallback')

export const trackEventWithCallback = (eventInfo = {}, callbacks = {}) => {
  const { name, properties } = eventInfo
  const { onSuccess, onFailure } = callbacks
  if (!name) {
    logger.error('Mixpanel event track requires name.')
    return
  }

  const didTrack = trackEvent(name, properties)
  if (didTrack && onSuccess) {
    onSuccess()
  } else if (!didTrack) {
    logger.error(`Unable to track mixpanel event: ${name}.`)
    if (onFailure) {
      onFailure()
    }
  }
}
