import { onBeforeUnmount } from 'vue'
/**
 * This is a composble that allows components to attach
 * listeners onto the browser window.
 * It takes an object of possible events with key validated
 * callbacks as the value.
 * It exposes a method to attach and detach these generated events
 * and automatically detaches on unmount.
 *
 * @param {object} eventDict Dictionary of events with nested keys for the callbacks
 * @returns {{
 *  attachWindowEvents: () => void,
 *  detachWindowEvents: () => void
 * }} two functions for attaching and detaching the event listeners
 *
 * @example
 * ```js
 * const {
 *  attachWindowEvents,
 *  detachWindowEvents
 * } = useWindowEvent({
 *  keyup: {
 *    Escape: () => console.log('Escape has been pressed!') // validate the key event against the escape key
 *  }
 * })
 *
 * onBeforeMount(attachWindowEvents)
 * ```
 *
 * @link {https://developer.mozilla.org/en-US/docs/Web/Events} Web Events API
 */
const useWindowEvent = eventDict => {
  // event dictionary contains object of
  // events and their respective callbacks
  const windowEvs = Object.keys(eventDict)

  // this is the callback given to the actual
  // window event
  // it runs against the provided object and fires any matching callbacks
  const validatedCB = ev => {
    const listEvents = eventDict[ev.type]
    if (listEvents[ev.key] && typeof listEvents[ev.key] === 'function') {
      listEvents[ev.key]()
    }
  }

  const detachWindowEvents = () => {
    windowEvs.forEach(wEv => {
      window.removeEventListener(wEv, validatedCB)
    })
  }

  const attachWindowEvents = () => {
    windowEvs.forEach(wEv => {
      window.addEventListener(wEv, validatedCB)
    })
  }

  // safety detachment of window events from
  // component
  onBeforeUnmount(detachWindowEvents)

  return {
    attachWindowEvents,
    detachWindowEvents
  }
}

export default useWindowEvent
