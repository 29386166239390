/**
 * Check if user has scrolled to bottom of div, fire provided
 * callback if so
 * @param { function() : void } cb
 */
const useCheckIfScrollBottom = cb => {
  /**
   * Scroll event function to test if element's scroll position
   * has reached the bottom
   * @param {HTMLElement} el Scrollable container
   */
  const checkIfScrollIsBottom = el => {
    const src = el.srcElement
    if (!src) {
      return
    }
    const { scrollHeight, scrollTop, clientHeight } = src
    const didScrollBottom = scrollHeight - scrollTop - clientHeight < 1
    if (didScrollBottom) {
      cb()
    }
  }
  return {
    checkIfScrollIsBottom
  }
}

export default useCheckIfScrollBottom
