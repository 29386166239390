<script>
import { ref } from 'vue'
import * as CommonProps from '../common/props'

export default {
  name: 'NewBaseListItem',
  props: {
    /**
     * Is the list item clickable?
     * Adds a hover effect.
     */
    selectable: CommonProps.BOOLEAN,
    /**
     *
     */
    dense: CommonProps.BOOLEAN,
    /**
     *
     */
    selected: CommonProps.BOOLEAN,
    /**
     *
     */
    verticallyCentered: CommonProps.BOOLEAN,
    /**
     *
     */
    disabled: CommonProps.BOOLEAN
  },
  setup () {
    const liRef = ref(null)
    const isHovered = ref(false)

    return {
      liRef,
      isHovered
    }
  }
}
</script>

<template>
  <li
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    :class="{
      'text-sm py-1': dense,
      'text-md py-2': !dense,
      'bg-brand-blue-light text-brand-blue': selected,
      'bg-white text-brand-gray-darker': !selected,
      'text-brand-gray-light': disabled,
      'cursor-default': !selectable,
      'cursor-not-allowed': selectable && disabled,
      'cursor-pointer hover:bg-brand-blue-light': selectable && !disabled
    }"
    class="relative transition-colors"
    ref="liRef"
  >
    <div
      class="px-4 flex flex-row flex-nowrap space-x-4"
      :class="{ 'items-center': verticallyCentered }"
    >
      <slot :li-ref="liRef" :is-hovered="isHovered" />
    </div>
  </li>
</template>
