import { Storage } from 'aws-amplify'
import { callLambdaApi, Methods, ApiNames, ModelApiCommunicator } from '@/api'
import { baseNameFromFile, noop } from '@/utils'

export class PartApiCommunicator {
  projectId
  partId

  constructor (projectId, partId) {
    this.projectId = projectId
    this.partId = partId
  }

  static create (projectId, partName, uploadFileName) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `/projects/${projectId}/parts`,
      payload: { body: { partName, uploadFileName } }
    })
  }

  /**
   * This is very similar to `Project.createWithUpload(file)`
   * call.
   * From ThePartsCardGrid.vue, when a user upload a mesh, use it
   * to create a new part for the contextual project.
   *
   * @deprecated
   * @param {!string} projectId Parent project id
   * @param {!File} file The file to upload after the part is created
   * @return {Promise<Part>} Promise for new part.
   *
   * @see {Project.createWithUpload}
   */
  static async createWithMeshUpload (projectId, file) {
    const partName = baseNameFromFile(file.name)
    const uploadFileName = file.name
    const newPart = await PartApiCommunicator.create(projectId, partName, uploadFileName)
    const { unsignedUrl, uploadBucket } = newPart.defaultModel.uploadUrls
    await ModelApiCommunicator.uploadMesh(file, { unsignedUrl, uploadBucket })
    return newPart
  }

  static uploadReferenceCAD ({
    unsignedUrl,
    file,
    uploadBucket,
    progressCallback = noop
  }) {
    return Storage.put(unsignedUrl, file, {
      level: 'private',
      bucket: uploadBucket,
      contentType: file.type,
      progressCallback
    })
  }

  fetch () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: this.buildBaseEndpoint()
    })
  }

  rename (newName) {
    return callLambdaApi({
      method: Methods.PUT,
      apiName: ApiNames.Projects,
      path: this.buildBaseEndpoint(),
      payload: {
        body: {
          partName: newName
        }
      }
    })
  }

  delete () {
    return callLambdaApi({
      method: Methods.DELETE,
      apiName: ApiNames.Projects,
      path: this.buildBaseEndpoint()
    })
  }

  fetchReferenceCADUploadUrl (uploadFileName) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `${this.buildBaseEndpoint()}/referenceupload`,
      payload: { body: { uploadFileName } }
    })
  }

  copy (destinationProjectId) {
    return callLambdaApi({
      method: Methods.POST,
      apiName: ApiNames.Projects,
      path: `${this.buildBaseEndpoint()}/copy`,
      payload: {
        body: {
          destinationProjectId
        }
      }
    })
  }

  /** @private */
  buildBaseEndpoint () {
    return `/projects/${this.projectId}/parts/${this.partId}`
  }
}
