/**
 * Apollo / graphql uses its own internal cache on all updates
 * triggered by subscriptions / mutations.
 * However, some properties and data need to be client-side modifiable.
 * For example, replyCount of a comment is accurate on initial query, but
 * addCommentReply and deleteCommentReply do not trigger a subscription update
 * for the comment with ownership of the deleted or added replies.
 * Thus, for the UI we need to maintain some client-side state to mock this
 * update. (subsequent fetches will then overwrite the state with the source of truth).
 *
 */
import { onBeforeUnmount, reactive, computed } from 'vue'

/**
 * @type {Object<string, number>}
 * Reactive object of replyCounts keyed by the commentId.
 * Local to this file and exposed to components via computed values.
 *
 */
const replyCountIndex = reactive({})

/**
 * @param {string} commentId The id of the top level comment. If calling from the context
 * of a commentReply, this id is `replyTo`
 */
export const decrementReplyCount = commentId => {
  let count = replyCountForComment(commentId)
  if (count > 0) {
    count--
  }
  updateReplyCount(commentId, count)
}

export const incrementReplyCount = commentId => {
  const count = replyCountForComment(commentId) + 1
  updateReplyCount(commentId, count)
}

export const updateReplyCount = (commentId, count = null) => {
  const setC = count === null ? 0 : count
  replyCountIndex[commentId] = setC
}

const removeCommentFromIndex = commentId => {
  if (replyCountIndex[commentId] !== null && replyCountIndex[commentId] !== undefined) {
    delete replyCountIndex[commentId]
  }
}

const replyCountForComment = commentId => replyCountIndex[commentId] || 0

/**
 * Composable to get a computed property of the given comment id's
 * number of replies.
 * The initial value is set by query in `useListCommentsForObject()`
 * It also deletes the commentId from the replyCountIndex on unmount.
 *
 * @param {string} commentId The id of the comment to get replyCount for.
 * @returns {{
 *   hasReplies: computed,
 *   replyCount: computed
 * }} object with replyCount (computed number) and hasReplies (computed bool)
 * as computed property.
 *
 * @example
 * ```js
 *
 * const { replyCount } =  useReplyCountForComment(props.comment.commentId)
 *
 * console.log(replyCount.value) // 0
 *
 * // in some other component
 * addCommentReply()
 * //
 *
 * console.log(replyCount.value) // 1
 *
 * ```
 */
export const useReplyCountForComment = commentId => {
  if (!commentId) {
    console.log('useReplyCountForComment() has missing or invalid parameter', {
      commentId
    })
    return {}
  }

  onBeforeUnmount(() => removeCommentFromIndex(commentId))

  return {
    hasReplies: computed(() => replyCountForComment(commentId) > 0),
    replyCount: computed(() => replyCountForComment(commentId))
  }
}
