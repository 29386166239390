import { StoreManager } from '@/store'
import { callLambdaApi, Methods, ApiNames } from './'
/**
 * @param {string} url
 * @async
 * @returns {Promise<string>} Signed url for image display
 */
export const getSignedStorageUrl = async url => {
  if (url.startsWith('http')) {
    return url
  } else if (url.startsWith('org')) {
    try {
      const store = StoreManager.storeInstance
      const orgId = store.organization.orgId
      const response = await callLambdaApi({
        method: Methods.POST,
        apiName: ApiNames.Org,
        path: '/download/sign',
        payload: { body: { url } },
        orgId
      })
      return response.signedUrl
    } catch (err) {
      console.error(err)
    }
  }
}
