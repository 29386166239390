<script>
import { computed } from 'vue'
/**
 * BaseLoadingShimmerElement stores animation properties for loading "shimmer effect"
 */
export default {
  name: 'BaseLoadingShimmerElement',
  inheritAttrs: false,
  props: {
    /**
     * Width of the loading shimmer element. Tailwind class.
     * Don't apply classes onto component instance, pass them down as
     * discrete props for more clarity and consistency.
     */
    width: {
      type: String,
      required: false,
      default: 'w-1/2'
    },
    height: {
      type: Number,
      required: false,
      default: 16
    }
  },
  setup (props) {
    const computedHeight = computed(() => {
      const height = props.height
      return `height: ${height}px;`
    })

    return {
      computedHeight
    }
  }
}
</script>

<template>
  <div
    class="rounded-sm shimmer"
    :style="computedHeight"
    :class="width"
  />
</template>

<style scoped>
.shimmer {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
</style>
