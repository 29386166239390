import AwsAuth from '@aws-amplify/auth'
import { StoreManager } from '@/store'
import ErrorTracking from '@/services/errorTracking'
import { setMixpanelUserIdentity } from '@/analytics'
import { ApiNames, Methods, callLambdaApi } from '@/api'

/**
 * Class for authing and storing auth-related user info.
 *
 * Auth is also responsible for caching and restoring the cognito
 * state for a user, including refresh and caching the
 * org session data (i.e. last active orgId) to be used when restoring
 * user's last browser activity within the app.
 *
 * Ideally this would have a stricter separation of concerns,
 * since the org cache is not strictly related to the authorization state,
 * but is fine for now.
 *
 * TODO:
 * - [ ] Create api layer for auth
 */
export default class Auth {
  static SESSION_DATA = 'session_data'
  static ORG_DATA = 'org_data'

  signIn (email, password) {
    return AwsAuth.signIn(email, password)
  }

  signOut () {
    return AwsAuth.signOut()
  }

  signUp ({ firstName, lastName, email, password } = {}) {
    return AwsAuth.signUp({
      username: email,
      password,
      attributes: {
        email,
        given_name: firstName,
        family_name: lastName
      }
    })
  }

  sendPasswordResetCode (email) {
    return AwsAuth.forgotPassword(email)
  }

  resetPassword ({ email, code, newPassword }) {
    return AwsAuth.forgotPasswordSubmit(email, code, newPassword)
  }

  fetchUser () {
    return AwsAuth.currentUserInfo()
  }

  verifyAuthState () {
    return AwsAuth.currentAuthenticatedUser()
  }

  async changePassword (currentPassword, newPassword) {
    const authedUser = await this.verifyAuthState()
    return AwsAuth.changePassword(authedUser, currentPassword, newPassword)
  }

  getDefaultOrgFromSessionCreate () {
    return Auth.createSession()
  }

  static createSession () {
    return callLambdaApi({
      method: Methods.GET,
      apiName: ApiNames.Projects,
      path: '/session/create'
    })
  }

  /**
   * Start services for an authorizes user, [Real Time Updates, Analytics, Error Reporting]
   * @return {Promise} PusherManager session creation
   */
  async startAuthedUserServices () {
    const store = StoreManager.storeInstance
    const currentUser = store.currentUser

    setMixpanelUserIdentity(currentUser)
    ErrorTracking.setUser(currentUser)

    return this.initializeSession()
  }

  stopAuthedUserServices () {
    // TODO:
    // - [ ] End mixpanel
    const store = StoreManager.storeInstance
    store.pusherManager.stop()
  }

  count = 0

  async initializeSession () {
    const store = StoreManager.storeInstance
    const [authSession, orgSession] = await Promise.all([
      this.getCognitoSession(),
      this.retrieveAndCacheOrgSession()
    ])

    const pusherManager = store.pusherManager
    // TODO:
    // - [ ] Perhaps store these on the auth instance
    //       so they don't have to be passsed in every call.
    return pusherManager.start(authSession, orgSession)
  }

  // ==============
  // Org Session
  // ==============

  async retrieveAndCacheOrgSession () {
    const store = StoreManager.storeInstance
    const defaultOrg = await this.getRestoredOrDefaultOrgSession()
    await store.setOrgFromCache(defaultOrg)
    this.cacheOrgData(defaultOrg)
    return defaultOrg
  }

  async getRestoredOrDefaultOrgSession () {
    let cachedOrgData = this.getCachedOrgData()
    let defaultOrg = cachedOrgData?.defaultOrg
    if (!defaultOrg) {
      cachedOrgData = await this.getDefaultOrgFromSessionCreate()
      defaultOrg = cachedOrgData?.defaultOrg
      cachedOrgData.currentOrgIrg = defaultOrg?.orgId
    }
    return cachedOrgData
  }

  getCachedOrgData () {
    return JSON.parse(localStorage.getItem(Auth.ORG_DATA))
  }

  cacheOrgData (orgData) {
    localStorage.setItem(Auth.ORG_DATA, JSON.stringify(orgData))
  }

  clearOrgData () {
    localStorage.removeItem(Auth.SESSION_DATA)
  }

  changeOrganization (orgId) {
    const cachedOrg = this.getCachedOrgData()
    cachedOrg.currentOrgId = orgId
    this.cacheOrgData(cachedOrg)
  }

  setCachedDefaultOrg (org) {
    const chachedOrgDat = this.getCachedOrgData()
    chachedOrgDat.defaultOrg = org.getAsCacheData()
    this.cacheOrgData(chachedOrgDat)
  }

  // ===============
  // Cognito Session
  // ===============

  getCognitoSession () {
    return AwsAuth.currentSession()
  }

  getCachedSessionData () {
    return JSON.parse(localStorage.getItem(Auth.SESSION_DATA))
  }

  cacheSessionData (sessionData) {
    localStorage.setItem(Auth.SESSION_DATA, JSON.stringify(sessionData))
  }

  clearSessionData () {
    localStorage.removeItem(Auth.SESSION_DATA)
  }

  getJwtToken (session) {
    return session.idToken.jwtToken
  }

  clear () {}
}
