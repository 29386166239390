<script>
import StatefulTeleport from './StatefulTeleport.vue'
import StatefulPositioner from './StatefulPositioner.vue'

/**
 * TODO:
 * - [] Change to stateless
 */
export default {
  name: 'StatefulPopover',
  components: {
    StatefulPositioner,
    StatefulTeleport
  },
  inheritAttrs: false,
  props: {
    ...StatefulPositioner.props
  }
}
</script>

<template>
  <stateful-teleport>
    <template #default="{ animationState }">
      <stateful-positioner
        :is-visible="isVisible"
        :target-ref="targetRef"
        :animation-state="animationState"
        :offset="offset"
        :placement="placement"
        manual-destroy
      >
        <template
          #default="{ showSlot, destroyPositioner, updatePositioner, transformString }"
        >
          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
            @before-enter="updatePositioner"
            @after-leave="destroyPositioner"
          >
            <slot v-if="showSlot" :transform-string="transformString" />
          </transition>
        </template>
      </stateful-positioner>
    </template>
  </stateful-teleport>
</template>

<style scoped></style>
