<script>
import { ref, computed, watch, onBeforeMount } from 'vue'
import { useToggle, set, get } from '@vueuse/core'
import { useCheckClickOutsideComponent } from '@/composables'

import * as CommonProps from '../common/props'

import NewBaseListItem from '../list/BaseListItem.vue'
import BaseListItemSection from '../list/BaseListItemSection.vue'

import StatefulPositioner from '../utils/StatefulPositioner.vue'
import Pane from '../layers/Pane.vue'

/**
 * Select allows user to select an item from a list of options.
 * Different than SelectMenu in that this is simply to override
 * the native '< select >' element and not providing custom menu/container
 * formatting.
 *
 * Common in forms.
 *
 * It is a controlled, stateful component managing the selection internally of an option,
 * which is emitted with the 'change' event.
 */
export default {
  name: 'BaseSelect',
  components: {
    Pane,
    NewBaseListItem,
    BaseListItemSection,
    StatefulPositioner
  },
  emits: {
    /**
     * When an option is selected
     */
    change: null,
    /**
     *
     */
    open: null,
    /**
     *
     */
    close: null
  },
  props: {
    /**
     *
     */
    label: CommonProps.STRING,
    /**
     *
     */
    emptyMessage: CommonProps.STRING,
    /**
     *
     */
    width: CommonProps.NUMBER,
    /**
     *
     */
    maxHeight: CommonProps.NUMBER,
    /**
     *
     */
    options: CommonProps.ARRAY,
    /**
     *
     */
    defaultSelectedOptionIndex: CommonProps.NUMBER,
    /**
     *
     */
    zIndex: {
      ...CommonProps.NUMBER,
      default: 20
    },
    /**
     * If no option has been selected,
     * show "None" as the button display text
     * but NOT as an option in the dropdown
     */
    unselectedShowsNone: CommonProps.BOOLEAN
  },
  setup (props, { emit }) {
    const selectedOptionIndex = ref(0)
    onBeforeMount(() => {
      // set default
      const defaultIndex = props.defaultSelectedOptionIndex
      selectedOptionIndex.value = defaultIndex
    })

    const selectOption = (option, index) => {
      selectedOptionIndex.value = index
      emit('change', option, index)
      togglePaneIsVisible()
    }

    const containerRef = ref(null)
    const containerWidth = ref(0)
    const getContainerRect = () => {
      return get(containerRef)?.getBoundingClientRect() || {}
    }
    const widthPx = computed(() => `${props.width}px`)

    const [paneIsVisible, togglePaneIsVisible] = useToggle()

    useCheckClickOutsideComponent({
      els: [containerRef],
      watchProperty: paneIsVisible,
      onClickOutside: () => set(paneIsVisible, false)
    })

    watch(paneIsVisible, is => {
      if (is) {
        const rect = getContainerRect()
        containerWidth.value = rect.width
        emit('open')
      } else {
        emit('close')
      }
    })

    watch(
      () => props.defaultSelectedOptionIndex,
      nowIndex => {
        selectedOptionIndex.value = nowIndex
      }
    )

    const selectedOption = computed(() => {
      const index = selectedOptionIndex.value
      return props.options[index]
    })

    const buttonLabel = computed(() => {
      const options = props.options
      if (options.length > 0 && selectedOptionIndex.value >= 0) {
        const selectedOptionLabel = get(selectedOption).label
        return selectedOptionLabel
      } else if (options.length > 0 && props.unselectedShowsNone) {
        // -1
        return 'None'
      }
      return props.emptyMessage
    })

    return {
      selectedOption,
      selectOption,
      buttonLabel,
      containerRef,
      togglePaneIsVisible,
      paneIsVisible,
      widthPx,
      containerWidth
    }
  }
}
</script>
<template>
  <div
    ref="containerRef"
    :class="{
      'set-width': width > 0,
      'w-auto': width === 0 || width === undefined
    }"
    :style="{
      zIndex: zIndex
    }"
    class="relative"
  >
    <div class="space-y-2">
      <base-span v-if="label" type="caption" rank="secondary">
        {{ label }}
      </base-span>
      <BaseButton
        @click="togglePaneIsVisible"
        :label="buttonLabel"
        rank="secondary"
        trailing-icon="chevron-down"
        size="medium"
        block
      />
    </div>
    <stateful-positioner
      v-if="options.length > 0"
      :is-visible="paneIsVisible"
      :target-ref="containerRef"
      placement="bottom"
    >
      <pane :width="containerWidth" :max-height="maxHeight" y-scroll>
        <ul>
          <new-base-list-item
            v-for="(option, index) in options"
            @click="selectOption(option, index)"
            :key="index"
            v-bind="option"
            selectable
            dense
          >
            <base-list-item-section
              class="text-left grow shrink-0"
            >
              <base-span :lines="1" type="caption">
                {{ option.label }}
              </base-span>
            </base-list-item-section>
            <base-list-item-section v-if="option.secondaryLabel">
              <base-span
                :lines="1"
                :italic="option.secondaryLabelItalic"
                type="caption"
                class="shrink grow-0 text-right"
              >
                {{ option.secondaryLabel }}
              </base-span>
            </base-list-item-section>
          </new-base-list-item>
        </ul>
      </pane>
    </stateful-positioner>
  </div>
</template>

<style scoped>
.set-width {
  width: v-bind(widthPx);
}
</style>
