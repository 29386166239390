<script>
import * as CommonProps from '../common/props'
export default {
  name: 'BaseListItemSection',
  props: {
    /**
     * Takes any remaining content space in flex row.
     */
    main: CommonProps.BOOLEAN
  }
}
</script>

<template>
  <div
    :class="{
      'flex-1': main
    }"
    class="flex flex-col justify-center"
  >
    <slot />
  </div>
</template>
