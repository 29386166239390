export const MIXPANEL_EVENTS_PROJECT = {
  PROJECT_OPEN: 'project_open',
  PROJECT_CREATE: 'project_create',
  PROJECT_DELETE: 'project_delete',
  PROJECT_DELETE_ERROR: 'project_delete_error',
  PROJECT_DUPLICATE: 'project_duplicate',
  PROJECT_RENAME: 'project_rename',

  // unsure if used
  PROJECT_INSPECT: 'project_inspect',
  PROJECT_TOGGLE_DATA_VIEW: 'project_toggle_data_view'
}
