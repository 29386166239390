import { OrganizationApiCommunicator } from '@/api'

export class OrgSettings {
  static REGISTRATION_METHODS = {
    SSD: 'ssd',
    POISSON: 'poisson'
  }

  static getRegistrationMethodsList () {
    const methods = OrgSettings.REGISTRATION_METHODS
    return Object.values(methods)
  }

  constructor (json = {}) {
    this.general = json.general || this.getGeneralDefaults()
    this.registration = json.registration || this.getRegistrationDefaults()
    this.orgId = json.orgId
    this.apiCommunicator = new OrganizationApiCommunicator(this.orgId)
  }

  getGeneral () {
    return this.general
  }

  setGeneral ({ displayUnits }) {
    this.general = { ...this.general, displayUnits }
    return this.getGeneral()
  }

  getRegistration () {
    return this.registration
  }

  setRegistration ({
    method,
    poissonLevels,
    poissonSamplesPerNode,
    ssdLevels
  }) {
    this.registration = {
      ...this.registration,
      method,
      poissonLevels,
      poissonSamplesPerNode,
      ssdLevels
    }
    return this.getRegistration()
  }

  getGeneralDefaults () {
    return {
      displayUnits: 'mm'
    }
  }

  getRegistrationDefaults () {
    return {
      /**
       * ssd || poisson
       */
      method: OrgSettings.REGISTRATION_METHODS.SSD,
      poissonSamplesPerNode: 1,
      poissonLevels: 9,
      ssdLevels: 11
    }
  }

  async fetchAndStoreDetails () {
    const apiCommunicator = this.apiCommunicator
    const settings = await apiCommunicator.fetchSettings()
    console.log('fetch and store details settings()', settings)
    return this
  }

  async save () {
    const apiCommunicator = this.apiCommunicator
    const requestBody = this.getAsRequestBody()
    const { general, registration } = await apiCommunicator.saveSettings(
      requestBody
    )
    this.setGeneral(general)
    this.setRegistration(registration)
    return this
  }

  getAsRequestBody () {
    return {
      general: this.getGeneral(),
      registration: this.getRegistration()
    }
  }
}
