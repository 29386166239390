export const partInspector = {
  name: 'part-inspector',
  path: 'project/:projectId/part/:partId',
  components: {
    default: () =>
      import(
        /* webpackChunkName: 'part-inspector' */ '../views/PartInspector.vue'
      ),
    leftSidebar: () =>
      import('@/components/part-inspector/left-sidebar/ThePartInspectorLeftSidebar.vue')
  },
  meta: { requiresAuth: true }
}
