/**
 * Base object received from internal API calls.
 * Represents core-data for a Project.
 */
export class ProjectDTO {
  createdAt
  createdBy
  indexedAt
  orgId
  projectId
  projectName
  transformSettings
  updatedAt
  updatedBy
  /** @type {import('../models').Part[]} Array of parts */
  parts = []
  /** @type {import('../models').ObjectPermissions} */
  permissions
}
