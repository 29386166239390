import mixpanel from 'mixpanel-browser'

import config from '@/config'
import AnalyticsManager from './AnalyticsManager'

export const mixpanelProviderKey = Symbol('$mixpanel')

export const initMixpanel = () => {
  const token = mixpanelToken()
  const mixpanelConfig = {}

  mixpanel.init(token, mixpanelConfig)

  AnalyticsManager.mixpanelInstance = mixpanel

  return mixpanel
}

const mixpanelToken = () => config.mixpanel.token

export const provideMixPanel = app => {
  const mixpanel = initMixpanel()
  app.provide(mixpanelProviderKey, mixpanel)
}

/** @param {User} currentUser */
export const setMixpanelUserIdentity = currentUser => {
  const userIdentity = { userId: currentUser.username }
  const userAttributes = currentUser.attributes || {}
  const userProperties = {
    ...userIdentity,
    $name: userAttributes.email,
    $email: userAttributes.email
  }

  mixpanel.identify(userIdentity)
  mixpanel.people?.set(userProperties)
}
