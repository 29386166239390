/**
 * Sortable options used for sorting lists.
 * @enum
 */
export const SORT_OPTIONS = {
  CREATED_AT: 0, // createdAt
  UPDATED_AT: 1, // updatedAt
  NAME: 2 // name
}

/**
 * Matches the sort field in UI
 * to the key on DTO for dynamic sort on the GET.
 */
export const SORT_OPTION_TO_PROJECT_FIELD = {
  [SORT_OPTIONS.CREATED_AT]: 'createdAt',
  [SORT_OPTIONS.UPDATED_AT]: 'updatedAt',
  [SORT_OPTIONS.NAME]: 'projectName'
}

/**
 * Used for dynamically generated lists to ensure
 * render order consistency.
 */
export const SORT_OPTIONS_LIST = Object.values(SORT_OPTIONS).sort()
