/**
 * Simple sort to be fed as a callback to
 * JavaSript's .sort() on arrays.
 * @param {string|number} a first element to sort
 * @param {string|number} b second element to sort
 * @param {boolean=true} asc Should the list sort in ascending order?
 *
 * @returns {-1|0|1} Number to determine how to move the given elements in list.
 *
 * @example
 *
 */
const simpleSort = (a = null, b = null, asc = true) => {
  if (!a || !b) return 0
  if (a < b) return asc ? -1 : 1
  if (a > b) return asc ? 1 : -1
  return 0
}

export default simpleSort
