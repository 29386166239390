import { useMutation } from '@vue/apollo-composable'
import { ApolloManager } from '../'
import { incrementReplyCount } from './useReplyCountForComment'
import { createCommentReply as createCommentReplyMutation } from '@/graphql/mutations'
import gql from 'graphql-tag'

/**
 * Add a comment reply to the specified comment (replyTo)
 *
 * @return {(replyTo: String, body: String) => void} The function returned by composable to be called
 * from component that wishes to "add" a comment reply.
 *
 * @example
 * ```js
 * const { addCommentReply } = useAddCommentReply()
 *
 * const onEnterKeyPress  = () => {
 *   addCommentReply({ replyTo, body })
 * }
 * ```
 */
export const useAddCommentReply = () => {
  const { mutate: addCommentReply } = useMutation(
    gql`
      ${createCommentReplyMutation}
    `,
    () => ({ ...ApolloManager.DEFAULT_QUERY_OPTIONS, fetchPolicy: 'no-cache' })
  )

  return {
    addCommentReply: ({ replyTo = null, body = null }) => {
      if (!replyTo || !body) {
        console.log('useAddCommentReply() parameter is missing or invalid', {
          replyTo,
          body
        })
        return
      }
      addCommentReply({ replyTo, body })
      // replyTo == commentId we need to increment
      // move to on success mutation hook?
      incrementReplyCount(replyTo)
    }
  }
}
