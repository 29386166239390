/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listComments = /* GraphQL */ `
  query ListComments(
    $objectId: ID!
    $type: commentType!
    $count: Int
    $nextToken: String
    $sortOrder: String
  ) {
    listComments(
      objectId: $objectId
      type: $type
      count: $count
      nextToken: $nextToken
      sortOrder: $sortOrder
    ) {
      items {
        objectId
        commentId
        type
        body
        createdAt
        updatedAt
        author
        handle
        metadata
        annotationAnchor
        replyCount
        lastReplyHandle
      }
      nextToken
    }
  }
`;
export const listCommentReplies = /* GraphQL */ `
  query ListCommentReplies(
    $commentId: ID!
    $count: Int
    $nextToken: String
    $sortOrder: String
  ) {
    listCommentReplies(
      commentId: $commentId
      count: $count
      nextToken: $nextToken
      sortOrder: $sortOrder
    ) {
      items {
        replyTo
        objectId
        commentId
        body
        createdAt
        updatedAt
        author
        handle
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($commentId: ID!) {
    getComment(commentId: $commentId) {
      objectId
      commentId
      type
      body
      createdAt
      updatedAt
      author
      handle
      metadata
      annotationAnchor
      replyCount
      lastReplyHandle
    }
  }
`;
export const listModelSessions = /* GraphQL */ `
  query ListModelSessions($modelId: ID!, $count: Int, $nextToken: String) {
    listModelSessions(modelId: $modelId, count: $count, nextToken: $nextToken) {
      items {
        sessionId
        modelId
        creator
        creatorHandle
        transformation
        participants
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getModelSession = /* GraphQL */ `
  query GetModelSession($sessionId: ID!) {
    getModelSession(sessionId: $sessionId) {
      sessionId
      modelId
      creator
      creatorHandle
      transformation
      participants
      createdAt
      updatedAt
    }
  }
`;
