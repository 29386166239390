import Pusher from 'pusher-js'
import config from '@/config'

import { Logger } from '@aws-amplify/core'
import { StoreManager } from '@/store'
import { ApiEndpoints } from '@/api'

const logger = new Logger('PusherManager')

/**
 * Manages pusher storeInstance and subscriptions for authed user
 */
export default class PusherManager {
  static UPDATE_OPERATIONS = {
    MODIFY: 'modify',
    INSERT: 'insert'
  }

  static UPDATE_EVENTS = {
    ORGS: 'orgs',
    PROJECTS: 'projects',
    PARTS: 'parts',
    MODELS: 'models',
    DEVICES: 'devices',
    SCANS: 'scans'
  }

  static CHANNEL_ERROR_EVENT = 'pusher:subscription_error'
  static CHANNEL_SUCCESS_EVENT = 'pusher:subscription_succeeded'

  static MAX_CONNECTION_RETRY_COUNT = 5

  /**
   * @type {Pusher}
   */
  static pusherInstance

  static getPusherInstance () {
    return PusherManager.pusherInstance
  }

  static pusherInstanceIsValid () {
    return !!PusherManager.getPusherInstance()
  }

  static getEventFromUpdateMessage (message) {
    return message._event
  }

  /**
   * When a record is "deleted", backend appends a deleteTTL
   * onto the data object as a temporary safety net. This means
   * the pusher message regards the operation as a "modify" event.
   *
   * However, we should still process these updates as deletes onto the store
   * when they arrive.
   */
  static getEventMessageImpliesDelete (message) {
    const record = PusherManager.getRecordDataFromUpdateMessage(message)
    const recordHasDeleteTTL = Object.prototype.hasOwnProperty.call(
      record,
      'deleteTTL'
    )
    const operation = PusherManager.getOperationFromUpdateMessage(message)
    const eventIsModify = operation === PusherManager.UPDATE_OPERATIONS.MODIFY
    return recordHasDeleteTTL && eventIsModify
  }

  static getOperationFromUpdateMessage (message) {
    return message._operation
  }

  static getRecordDataFromUpdateMessage (message) {
    return message.record
  }

  /**
   * Active subscription string endpoint
   * @private
   * @type {string}
   */
  sessionChannel = null

  /**
   * @type {Date}
   */
  startTS

  /**
   * @type {Date}
   */
  endTS

  /**
   * @type {number}
   */
  connectionRetryCount = 0

  async start (authSession, orgSession) {
    this.log('start()', {
      authSession,
      orgSession
    })
    this.startTS = Date.now()
    if (this.connectionRetryCount >= PusherManager.MAX_CONNECTION_RETRY_COUNT) {
      this.log('[PUSHER]: Did exceed max retry count for connection.')
      return
    }
    try {
      await this.startQueueConsumer(authSession, orgSession)
      this.connectionRetryCount = 0
    } catch (err) {
      const store = StoreManager.storeInstance
      const auth = store.auth
      const newSessionData = await auth.createAndCacheSession()
      this.connectionRetryCount++
      return this.startQueueConsumer(newSessionData, orgSession)
    }
  }

  async startQueueConsumer (authSession, orgSession) {
    this.log('startQueueConsumer()', authSession, orgSession)
    const lastSessionOrgId = orgSession.currentOrgId
    const defaultOrg = orgSession.defaultOrg
    const defaultOrgId = defaultOrg?.orgId
    const orgId = lastSessionOrgId || defaultOrgId
    if (!orgId) {
      return
    }

    try {
      const store = StoreManager.storeInstance
      const auth = store.auth
      const jwtToken = auth.getJwtToken(authSession)
      await this.createAndSetPusherInstance(jwtToken)
      // possible this JWT is expired
      // may need to change method to async
      // fetch from AWS or recursively call this on error after
      // resetting session state...
    } catch (err) {
      logger.error('[PUSHER MANAGER] -- startQueueConsumer ERROR', err)
      this.endTS = Date.now()
      this.logLifeCycle()
      return
    }

    this.subscribeToOrgUpdates(orgId)
    this.bindGeneralUpdates()
  }

  async createAndSetPusherInstance (jwtToken) {
    this.log('createAndSetPusherInstance()', jwtToken)
    const pusherConfig = config.pusher
    const appKey = pusherConfig.appKey
    const cluster = pusherConfig.appCluster
    const auth = {
      headers: {
        Authorization: jwtToken
      }
    }
    let client
    Pusher.logToConsole = true
    if (PusherManager.pusherInstanceIsValid()) {
      client = PusherManager.getPusherInstance()
      // Dyanomicallly set headers on exiting instance.
      // https://github.com/pusher/pusher-angular/issues/18
      client.config.auth = auth
    } else {
      client = new Pusher(appKey, {
        cluster,
        encrypted: true,
        authEndpoint: this.getAuthEndpoint(),
        auth
      })
      PusherManager.pusherInstance = client
    }
  }

  getAuthEndpoint () {
    const orgApiEndpoint = ApiEndpoints.Org
    const authEndpoint = 'pusherauth'
    return `${orgApiEndpoint}/${authEndpoint}`
  }

  /** @private */
  subscribeToOrgUpdates (orgId) {
    this.log('subscribeToOrgUpdates()', orgId)
    this.sessionChannel = this.buildOrgChannelEndpoint(orgId)
    const channel = PusherManager.pusherInstance.subscribe(this.sessionChannel)
    this.log('Instance', PusherManager.pusherInstance)
    this.log('Channel', channel)
    this.bindChannelUpdates(channel)
  }

  /** @private */
  bindGeneralUpdates () {
    const self = this
    self.log('bindGeneralUpdates()')
    PusherManager.pusherInstance.bind(
      PusherManager.CHANNEL_ERROR_EVENT,
      self.processChannelError
    )
    // this is connection error
    // perhaps we do not need to fully reset the session here...
    PusherManager.pusherInstance.connection.bind('err', this.processChannelError)
    PusherManager.pusherInstance.connection.bind('state_change', states => {
      self.log('[PUSHER]: State Change ->', states.current)
    })
    PusherManager.pusherInstance.connection.bind('error', error => {
      self.log('[PUHSER]: Connection Error:', error)
    })
    PusherManager.pusherInstance.bind(
      'update',
      StoreManager.storeInstance.pusherDataHandler
    )
  }

  /** @private */
  bindChannelUpdates (channel) {
    const self = this
    channel.bind(PusherManager.CHANNEL_ERROR_EVENT, (eventName, data) => {
      self.processChannelError(eventName, data)
    })
    channel.bind(PusherManager.CHANNEL_SUCCESS_EVENT, (eventName, data) => {
      self.processSuccess(eventName, data)
    })
  }

  /** @private */
  buildOrgChannelEndpoint (orgId) {
    return `private-org-${orgId}`
  }

  changeOrganization (orgId) {
    PusherManager.pusherInstance.unsubscribe(this.sessionChannel)
    this.subscribeToOrgUpdates(orgId)
  }

  stop () {
    if (PusherManager.pusherInstanceIsValid() && this.sessionChannel) {
      PusherManager.pusherInstance.unsubscribe(this.sessionChannel)
      this.clear()
    }
  }

  processSuccess (e, data) {
    this.log('Success', e, data)
  }

  processChannelError (error = {}) {
    this.endTS = Date.now()
    logger.error('[PUSHER]: processChannelError: ', error)
    if (error.status === 500 && error.type === 'AuthError') {
      logger.error('Reset Session')
      this.stop()
      const store = StoreManager.storeInstance
      const auth = store.auth
      // calls pushermanger.start()
      auth.initializeSession()
    }
    this.logLifeCycle && this.logLifeCycle()
  }

  clear () {
    this.sessionChannel = ''
  }

  logLifeCycle () {
    const start = this.startTS
    const end = this.endTS
    this.log('start() @', start, 'and ended at', end)
  }

  log (...args) {
    logger.debug(...args)
  }
}
