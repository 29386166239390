import { StoreManager } from '@/store'

const ORGS_KEY = 'orgs'

/**
 * Some store DTOs have a `paths` object that contain unsigned
 * urls for fetching data from S3 (meshes, pngs, etc).
 *
 * These must be scoped according to the org, but often times
 * pusher updates record info excludes the full url.
 *
 * Here we just ensure that the org/${orgId} is present on the url
 * string.
 */
export const ensurePrependedPathObject = (pathObj = {}) => {
  const retPaths = { ...pathObj }
  // TODO:
  // - [ ] Support traversing nested objects
  const stringedPathKeys = Object.keys(pathObj).filter(pathKey => {
    const value = pathObj[pathKey]
    return value && typeof value === 'string'
  })

  stringedPathKeys.forEach(pathKey => {
    const baseUrl = pathObj[pathKey]
    if (baseUrlRequiresPrepending(baseUrl)) {
      retPaths[pathKey] = getFullUrl(baseUrl)
    }
  })
  return retPaths
}

const baseUrlRequiresPrepending = baseUrl => {
  return !baseUrl.startsWith(ORGS_KEY)
}

const getFullUrl = baseUrl => {
  const store = StoreManager.storeInstance
  const org = store.getActiveOrganization()
  const orgId = org.orgId
  return `${ORGS_KEY}/${orgId}/${baseUrl}`
}
