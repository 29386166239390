import { useRouter } from 'vue-router'
import { routeIndex } from '@/router'
/**
 * Composable container for utilities that redirect to
 * different routes in the app
 *
 * @returns {{
 *  goToLogin: () => void,
 *  goToConfirmEmail: () => void,
 *  goToChangePassword: () => void,
 *  goToDashboard: () => void,
 *  goToPartInspector: (projectId: string, partId: string) => void,
 *  goBack: () => void
 * }} Object of functions for navigating the app's router.
 */
export const useAppRouterNavigation = () => {
  const router = useRouter()

  const goToLogin = () => {
    router.push({
      name: routeIndex.login.name
    })
  }

  const goToConfirmEmail = () => {
    router.push({
      name: routeIndex.confirmEmail.name
    })
  }

  const goToChangePassword = () => {
    router.push({
      name: routeIndex.changePassword.name
    })
  }

  const goToDashboard = projectId => {
    const cleanedId = typeof projectId === 'string' ? projectId : null
    const params = cleanedId ? { projectId } : {}
    router.push({
      replace: true,
      name: routeIndex.dashboard.name,
      params
    })
  }

  const goToPartInspector = (projectId, partId) => {
    router.push({
      name: routeIndex.partInspector.name,
      params: {
        partId,
        projectId
      }
    })
  }

  const goToOrgManagementSettings = orgId => {
    router.push({
      name: routeIndex.orgManagementSettings.name,
      params: { orgId }
    })
  }

  const goToOrgManagementMembers = orgId => {
    router.push({
      name: routeIndex.orgManagementMembers.name,
      params: { orgId }
    })
  }

  const goToUserAccountProfile = () => {
    router.push({
      name: routeIndex.userAccountProfile.name
    })
  }

  const goToUserAccountOrganizations = () => {
    router.push({ name: routeIndex.userAccountOrganizations.name })
  }

  const goToPermissionsDenied = (projectId, partId) => {
    const params = {}
    if (projectId) {
      params.projectId = projectId
    }
    if (partId) {
      params.partId = partId
    }
    router.push({ name: routeIndex.permissionsDenied.name, params })
  }

  const goBack = () => {
    router.back()
  }

  return {
    // authed
    goToDashboard,
    goToPartInspector,
    // org management
    goToOrgManagementSettings,
    goToOrgManagementMembers,
    // user account
    goToUserAccountProfile,
    goToUserAccountOrganizations,
    // misc
    goToPermissionsDenied,
    // auth
    goToLogin,
    goToConfirmEmail,
    goToChangePassword,
    // utils
    goBack
  }
}
