<script>
import { computed, h } from 'vue'
import InlineSVG from 'vue-inline-svg'
import * as CommonProps from '@/components/_base/common/props'

/**
 * We inline SVGs alongside dynamic URL
 * imports.
 * @link {https://github.com/shrpne/vue-inline-svg}
 * @link {https://cli.vuejs.org/guide/html-and-static-assets.html#static-assets-handling}
 */
export default {
  name: 'BaseIcon',
  components: {
    InlineSVG
  },
  props: {
    /**
     *
     */
    icon: CommonProps.ICON,
    /**
     *
     */
    color: CommonProps.STRING,
    /**
     *
     */
    size: {
      type: Number,
      required: false,
      default: 16,
      validator: function (value) {
        const sizes = [8, 10, 12, 14, 16, 18, 24, 32, 48, 72]
        return sizes.includes(value)
      }
    }
  },
  setup (props) {
    const computedColor = computed(() => props.color || 'currentColor')

    const computedSizes = computed(
      () => `width: ${props.size}px; height: ${props.size}px;`
    )

    const getSource = _icon => {
      return require(`../../../assets/icons/${_icon}.svg`)
    }

    const iconPath = computed(() => {
      let icon = require(`@/assets/icons/${props.icon}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }
      return icon.url
    })

    const alias = computed(() => `@/assets/icons/${props.icon}.svg`)

    return {
      computedColor,
      computedSizes,
      getSource,
      iconPath,
      alias
    }
  },
  render () {
    return h('div', [
      h(InlineSVG, {
        src: require(`@/assets/icons/${this.icon}.svg`),
        width: this.size,
        height: this.size,
        color: this.computedColor
      })
    ])
  }
}
</script>

<style scoped></style>
