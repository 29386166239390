<script>
import { ref } from 'vue'
/**
 * UI representation of a boolean for user. Often used in forms or settings.
 */
export default {
  name: 'BaseToggle',
  inheritAttrs: false, // manually propagate click event so local state is modifiable
  props: {
    /**
     * Starting value for toggle state when component instance is rendered.
     */
    autoEnabled: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * Optional label to append to toggle.
     */
    label: {
      type: String,
      required: false
    },
    /**
     * If there is a label, define its position relative to the toggle.
     * @values top, left, right
     */
    labelPosition: {
      type: String,
      required: false,
      default: 'right',
      validator: function (value) {
        const trimmed = value.trim()
        return ['top', 'left', 'right'].indexOf(trimmed) !== -1
      }
    }
  },
  emits: {
    /**
     * Emit click handler if defined with component instance.
     * Passes back the enabled state of the toggle.
     */
    click: null
  },
  setup (props, { emit }) {
    const enabled = ref(props.autoEnabled)
    /**
     * Toggle the local state for UI upate
     * and propagate event call up to parent with new state
     */
    const toggleEnabled = () => {
      enabled.value = !enabled.value
      emit('click', enabled.value) // pass the localized state up to parent if added event directive
    }

    return {
      enabled,
      toggleEnabled
    }
  }
}
</script>

<template>
  <div class="flex items-center" :class="labelPosition === 'top' ? 'max-w-max justify-center flex-col' : ''">
    <!-- Left/Top Label -->
    <span :class="labelPosition === 'left' ? 'mr-3' : 'mb-2'" v-if="labelPosition === 'left' || labelPosition === 'top'">
      <span class="text-sm font-medium text-brand-gray-darker">{{ label }}</span>
    </span>
    <!-- Toggle Button -->
    <button
      v-bind="$attrs"
      type="button"
      aria-pressed="false"
      aria-labelledby="toggleLabel"
      @click.prevent="toggleEnabled"
      class="relative inline-flex shrink-0 h-6 border-2 border-transparent rounded-full cursor-pointer w-11 transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-blue"
      :class="[enabled ? 'bg-brand-blue' : 'bg-brand-gray-light']"
    >
      <span
        aria-hidden="true"
        :class="enabled ? 'translate-x-5' : 'translate-x-0'"
        class="inline-block w-5 h-5 bg-white rounded-full shadow transform ring-0 transition ease-in-out duration-200"
      />
    </button>
    <!-- Right Label -->
    <span class="ml-3" v-if="labelPosition === 'right'">
      <span class="text-sm font-medium text-brand-gray-darker">{{ label }}</span>
    </span>
  </div>
</template>

<style scoped></style>
