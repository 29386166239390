import { CameraState } from './'

/**
 * @typedef {Vertex}
 * @property {float[]} vertexPosition
 * @property {int} vertexIndex
 */

/**
 * Container for creating new comment interaction/data storage.
 */
export default class Annotation {
  constructor (data = {}) {
    this.objectId = data.objectId
    this.commentType = data.commentType
    this.cameraState = new CameraState(data.cameraState)
    this.positionX = data.positionX
    this.positionY = data.positionY
    /**
     * @type {Vertex[]}
     */
    this.selectedVertices = data.selectedVertices
  }
}
