import { MeshViewer, Dashboard, PartInspector } from './'
import VueStore from 'vue-class-store'

class NewPartSnackbar {
  projectId
  partIdToWatchForChanges

  // we need a reference to the projectId
  // so we can correctly watch the store for changes on the part
  setProjectId (projectId) {
    this.projectId = projectId
  }

  setPartIdToWatch (partId) {
    this.partIdToWatchForChanges = partId
  }

  clear () {
    this.projectId = null
    this.partId = null
  }
}

/**
 * Class containing UI States across app.
 */
export default class UIStore {
  constructor () {
    /**
     * Wrap the MeshViewer state container in another
     * VueStore call (converts getters to computed). Required for
     * dynamic values such as 'blobs'.
     * @link {https://github.com/davestewart/vue-class-store/issues/22}
     *
     * @type {MeshViewer}
     */
    this.meshViewer = VueStore.create(new MeshViewer())

    this.dashboard = new Dashboard()

    this.partInspector = new PartInspector()

    this.newPartSnackbar = new NewPartSnackbar()
  }

  clear () {
    this.newPartSnackbar && this.newPartSnackbar.clear()
  }
}
