<script>
/**
 * Opaque overlay used for isolating the visibility of components upon
 * UI. (Used by dialogues/onboarding)
 */
export default {
  name: 'BaseOverlay'
}
</script>

<template>
  <div class="fixed inset-0 transition-opacity" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75" />
  </div>
</template>

<style scoped>
.hole {
  /* rgba === gray-500 */
  box-shadow: 0 0 0 9999px rgba(107, 114, 128, 0.75);
}
</style>
