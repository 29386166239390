<script>
import { computed } from 'vue'
import { useToggle, get } from '@vueuse/core'
import { useAvatarColorCache } from '@/composables'
/**
 * Avatar component. Renders placeholder svg or provided src image.
 */
export default {
  name: 'BaseAvatar',
  props: {
    /**
     * Image link to render.
     * If there's no source, the component will default to a placeholder svg.
     * Component will also fall-back to placeholder in the event that the
     * src errors using the @error directive.
     */
    src: {
      type: String,
      required: false
    },
    /**
     * Size of the avatar.
     */
    size: {
      type: Number,
      required: false,
      default: 32,
      validator: function (value) {
        const sizes = [8, 10, 12, 14, 16, 18, 24, 32, 48, 72, 100]
        return sizes.includes(value)
      }
    },
    /**
     * Make icon circular? Defaults to rounded square.
     */
    rounded: {
      type: Boolean,
      required: false,
      default: false
    },
    /**
     * In order for the base avatar to show "initials",
     * pass in the user's name here. If there's no source
     * the initials will be placed on a randomly colored background.
     */
    handle: {
      type: String,
      required: false,
      default: ''
    },
    /**
     * Unique identifier to cache the randomized color set
     * for either the user or organzation this avatar is being used
     * for.
     */
    id: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup (props, { attrs }) {
    const [imageFailedToLoad, toggleImageFailedToLoad] = useToggle()
    const { randomColor } = useAvatarColorCache(props.id)

    const classObject = computed(() => {
      const color = get(randomColor) || 'bg-brand-blue-light'
      return {
        // Source or placeholder parameters
        'cursor-pointer': attrs.onClick && typeof attrs.onClick === 'function',
        'bg-brand-gray-lighter': (!props.src || imageFailedToLoad) && !props.handle,
        // Border radius
        'rounded-full': props.rounded,
        'rounded-md': !props.rounded,
        [color]: props.handle
      }
    })

    const computedSizes = computed(() => `width: ${props.size}px; height: ${props.size}px;`)

    const parseInitials = handle => {
      if (!handle) return ''
      return handle.split(' ').slice(0, 2).map(n => n[0].toUpperCase()).join('') || ''
    }

    const initials = computed(() => {
      return parseInitials(props.handle) || ''
    })

    const initialsClass = computed(() => ({
      'text-sm': props.size === 'small',
      'text-base': props.size === 'medium',
      'text-lg': props.size === 'large'
    }))

    const triggerImageError = e => {
      toggleImageFailedToLoad()
    }

    return {
      classObject,
      triggerImageError,
      imageFailedToLoad,
      initials,
      initialsClass,
      computedSizes
    }
  }
}
</script>

<template>
  <span
    :class="classObject"
    :style="computedSizes"
    class="inline-flex items-center justify-center overflow-hidden"
  >
    <img
      @error="triggerImageError"
      :src="src"
      v-if="src && !imageFailedToLoad"
    />
    <span
      v-else-if="initials"
      :class="initialsClass"
      class="font-medium leading-none text-white"
    >
      {{ initials }}
    </span>
    <svg
      v-else
      class="w-full h-full text-brand-gray-dark"
      fill="currentColor"
      viewBox="0 0 24 24"
    >
      <path
        d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
      />
    </svg>
  </span>
</template>

<style scoped>
.s-16 {
  width: 16px;
  height: 16px;
}

</style>
